import "./style.css";

export const RoCheckbox = (props) => {
  const { onChange, style = {}, checked = false, ...rest } = props;
  return (
    <label className="checkbox-container" style={style}>
      <input type="checkbox" checked={checked} {...rest} onChange={onChange} />
      <span className="checkmark" />
    </label>
  );
};
