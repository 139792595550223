import { Pipes } from "global/pipes";
import { LinkedPayersTableHead } from "../constants";

export const FooterFilterMenuItems = LinkedPayersTableHead.filter(
  (h) => h.name !== ""
).map((head) => {
  return {
    label: head.name,
    value: head.name,
  };
});

export const MapHeadStringToFilter = LinkedPayersTableHead.reduce(
  (acc, head) => {
    acc[head.name] = Pipes.normal2Snake(head.name);
    return acc;
  },
  {}
);

export const MapFilterToApiKeys = {
  ["Insurance Name"]: "linkedPayer",
  ["Payer ID"]: "linkedPayerId",
  ["Trizetto Insurance Company"]: "payerName",
  ["Trizetto EDI"]: "payerId",
  ["State"]: "state",
  ["Status"]: "status",
};
