export const dateRangeData = [
    {name:'month_to_date', key:'Month to date'},
    {name:'quater_to_date', key:'Quarter to date'},
    {name:'Year_to_date', key:'Year to date'},
    {name:'last_month', key:'Last Month'},
    {name:'last_quarter', key:'Last Quarter'},
    {name:'last_year', key:'Last Year'}
]

export const header = [
    {name: 'Sr. No.', key: 'sr. no.'},
    {name: 'Rule Name', key: 'rule name'},
    {name: 'Rule Violation', key: 'rule violation'},
    {name: 'Created Date', key: 'created date'},
]

export const transactionHeader = [
    {name: 'Month', key: 'month'},
    {name: 'Encounters Ran', key: 'encounters ran'},
    {name: 'Eligibility Checked', key: 'eligibility checked'},
    {name: 'Clean Claims', key: 'clean claims'},
    {name: 'Claims Violations', key: 'claims violations'},
    {name: 'Rule Violations', key: 'rule violations'}
]

export const autoRunData = [
    {name: 'Encounters Downloaded', key: 'encounters downloaded'},
    {name: 'Downloaded Date & time', key: 'downloaded date & time'},
    {name: 'Start Time of Auto-Run', key: 'start time of auto-run'},
    {name: 'End time of Auto-Run', key: 'end time of auto-run'},
    {name: 'Encounters Successfully Ran', key: 'encounters successfully ran'},
    {name: 'Encounters Failed', key: 'encounters failed'},
]

export const varibleBreakdown = [
    {name:'Clean Claims', key: 'clean claims'},
    {name:'Providers', key: 'providers'},
    {name:'Service Locations', key: 'service location'},
]