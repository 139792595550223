import API from "Api";
import { buildUrlWithQuery, handleErrors } from "global/helpers";
import { LinkedPayerStatus, attachState } from "./constants";

/**
 * Fetches the list of all linked payers from the server
 * @returns
 */
export const fetchAllLinkedPayers = async () => {
  return handleErrors(async () => {
    const response = await API.get(`getAllLinkedPayers`);
    const { data } = response?.data;
    const linkedPayers = (data || []).map((linkedPayer) => {
      let [payerName, state = ""] =
        linkedPayer.payerName.split("Servicing States:");
      if (payerName.indexOf("Servicing States") !== -1) {
        payerName = payerName.split("Servicing States").join("");
      }
      // return {
      //   ...linkedPayer,
      //   state,
      //   payerName: payerName.trim(),
      //   name: linkedPayer.payerName,
      //   status:
      //     linkedPayer.status === true
      //       ? LinkedPayerStatus.APPROVED
      //       : LinkedPayerStatus.DISAPPROVED,
      // };
      return {
        ...linkedPayer,
        state,
        payerName: payerName.trim(),
        name: linkedPayer.payerName,
        status:
          linkedPayer.statusType === 'active'
            ? LinkedPayerStatus.APPROVED
            : linkedPayer.statusType === 'rejected' 
            ? LinkedPayerStatus.DISAPPROVED 
            : LinkedPayerStatus.PENDING,
      };
    });
    return linkedPayers;
  });
};

/**
 * Fetches the list of insurance payers from the server
 * @param {*} query
 * @returns
 */
export const fetchInsurancePayersList = async (query = {}) => {
  return handleErrors(async () => {
    let url = buildUrlWithQuery("getInsurancePayerList", query);
    const response = await API.get(url);
    const { data } = response?.data;
    const insurancePayers = (data || []).map((insurancePayer) => {
      let [payerName, state = ""] =
        insurancePayer.payerName.split("Servicing States:");
      if (payerName.indexOf("Servicing States") !== -1) {
        payerName = payerName.split("Servicing States").join("");
      }
      return {
        ...insurancePayer,
        state: insurancePayer.states,
        payerName: payerName,
        name: insurancePayer.payerName,
      };
    });

    return insurancePayers.sort((prev, next) =>
      prev.payerName > next.payerName ? 1 : -1
    );
  });
};

/**
 * Updates the linked payer record in the database
 * @param {*} linkedPayer
 * @returns
 */
export const updateLinkedPayer = async (linkedPayer) => {
  return handleErrors(async () => {
    let url = `updateLinkedPayer/${linkedPayer.id}`;
    linkedPayer.payerName =
      linkedPayer.payerName + " " + attachState(linkedPayer.state);
    const response = await API.put(url, linkedPayer);
    return response;
  });
};

/**
 * Deletes the linked payer records from the database
 * @param {*} linkedPayerId
 */
export const deleteLinkedPayer = async (linkedPayerId) => {
  return handleErrors(async () => {
    let url = `deleteLinkedEDI/${linkedPayerId}`;
    const response = await API.delete(url);
    return response;
  });
};

/**
 * Updates the insurance linked to the payer
 * @param {*} payerId
 * @param {*} insurance
 * @returns
 */
export const relinkPayerWithInsurance = async (payerId, insurance) => {
  return handleErrors(async () => {
    let url = `updateLinkPayer/${payerId}`;
    const response = await API.put(url, insurance);
    return response;
  });
};

/**
 * Link insurance to the payer
 * @param {*} payerId
 * @param {*} insurance
 * @returns
 */
export const linkPayerWithInsurance = async (payerId, insurance) => {
  return handleErrors(async () => {
    let url = `linkEDI`;
    const response = await API.post(url, insurance);
    return response;
  });
};
