
const footerStyle = {
    background: 'linear-gradient(0deg, #24476A 31.25%, #8EAFC2 299.25%)',
    height: 70,
    width: '100%',
    position: 'fixed',
    bottom: 0
}

export const ROFooter = (props) => {
    const { children, style = {}, ...rest } = props;

    return <div style={{ ...footerStyle, ...style }} {...rest}>{children}</div>

}