import React, { Component } from 'react';
import moment from 'moment'
import API from '../../Api'
import { Typography, TablePagination, DialogTitle, Card, CardContent } from '@material-ui/core';
import { Dialog, DialogContent } from '@material-ui/core';
import bug from '../../assets/icons/bug.svg';
import { NotificationManager } from 'react-notifications';

class LogModal extends Component {
    constructor(props) {
        super(props);
        const eachPageContaiRow = 50;
        this.state = {
            encounterID: this.props.encounterID,
            logModalOpen: this.props.logModalOpen,
            bugModal: false,
            isOther: false,
            selectValue: '',
            rules: [],
            bugRuleId: '',
            errorDescription: '',
            activityData: null,
            totalRows: 0,
            startActivityLogLimit: 0,
            endActivityLogLimit: eachPageContaiRow,
            page: 0,
            pages: [eachPageContaiRow, 100, 200],
            rowsPerPage: eachPageContaiRow
        }
    }

    fetchLogData = async ({ start, end }) => {
        return await API.post('getActivity', { encounterID: this.state.encounterID, start, end });
    }

    handleLogModel = async ({ status, start = this.state.startActivityLogLimit, end = this.state.endActivityLogLimit }) => {
        const res = await this.fetchLogData({ start, end })
        if (res.data.code === 200 && res.data.success === true) {
            this.setState({
                logModalOpen: status,
                activityData: res.data.data.activity_log,
                totalRows: res.data.data.rowCount
            })
        }
    }

    onRowsPerPage = (event) => {
        this.handleLogModel({ status: true, start: 0, end: event.target.value })
        this.setState({
            rowsPerPage: parseInt(event.target.value),
            page: 0,
            startActivityLogLimit: 0
        })
    }

    handleLogModelClose = () => {
        this.setState({
            logModalOpen: false
        })
    }

    handleLogData = (e, newPage) => {
        this.setState({ page: newPage });
        if (this.state.page < newPage) {
            this.setState({
                startActivityLogLimit: this.state.startActivityLogLimit + this.state.rowsPerPage
            }, async () => {
                const res = await this.fetchLogData({ start: this.state.startActivityLogLimit, end: this.state.rowsPerPage });
                if (res.data.code === 200 && res.data.success === true) {
                    this.setState({
                        logModalOpen: true,
                        activityData: res.data.data.activity_log,
                        totalRows: res.data.data.rowCount,
                    })
                }
            })
        } else {
            this.setState({
                startActivityLogLimit: this.state.startActivityLogLimit - this.state.rowsPerPage
            }, async () => {
                const res = await this.fetchLogData({ start: this.state.startActivityLogLimit, end: this.state.rowsPerPage })
                if (res.data.code === 200 && res.data.success === true) {
                    this.setState({
                        logModalOpen: true,
                        activityData: res.data.data.activity_log,
                        totalRows: res.data.data.rowCount,
                    })
                }
            })
        }
    }

    handleBugModal = () => {
        this.setState({
            bugModal: !this.state.bugModal,
            logModalOpen: false
        })
        // }, () => {
        //     this.getRules();
        // });
    }

    getRules = (practiceType = false) => {
        API.get(
            "getAllRules" + (practiceType == 0 ? "?practiceId=" + practiceType : "")
            )
            .then((response) => {
                let rules = response.data.data;
                this.setState({
                    rules: rules ? rules : [],
                });
            })
            .catch((err) => {
                console.error(err);
            });
        };

    handleSelectOption = (e) => {
        const {value} = e.target;
        if(value == 'other'){
            this.setState({isOther: true});
        }else if (value == '0'){
            this.setState({isOther: false});
            this.getRules(value);
        }else{
            this.setState({isOther: false});
            this.getRules();
        }
        this.setState({selectValue: value});
    }

    changeDropdownRule = (e) => {
        this.setState({
            bugRuleId: e.target.value
        })
    }

    falseBugReport = () => {
        try {
            let body_data = {
                encounterId: this.state.encounterID,
                ruleId: this.state.bugRuleId,
                errorDescription: this.state.errorDescription
            }
            API.post('bugReportByFalseViolation', body_data).then((res) => {
                console.log(res.data);
                NotificationManager.success("Reported Bug Successfully", 'Success', 3000);
                this.setState({
                    bugModal: false,
                    logModalOpen: false,
                    bugRuleId: '',
                    bugRuleName: '',
                    errorDescription: ''
                });
            })
        }catch(err){
            console.error(err);
        }
    }

    render() {
        return (
            <>
                <button className='subheader-controls-save'
                    onClick={() => { this.handleLogModel({ status: true, start: 0, end: this.state.endActivityLogLimit }) }}
                >
                    <span className='subheader_controls_text'>
                        LOG
                    </span>
                </button>
                <Dialog
                    className='log-popup-main'
                    fullWidth={'lg'}
                    maxWidth={'lg'}
                    open={this.state.logModalOpen}
                    onClose={() => this.handleLogModelClose(false)}
                >
                    <DialogTitle className='title'>
                        <div className='log_bug font-face-futura'>
                            <div className='log_txt'>LOG</div>
                            <div className='bug_img' onClick={this.handleBugModal}>
                                <img src={bug} alt='bug' />
                            </div>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <table className='log-table'>
                            <thead>
                                <tr>
                                    <td>Date/Time</td>
                                    <td>User</td>
                                    <td>Action</td>
                                </tr>
                            </thead>
                            <tbody>
                                {(this.state.activityData && this.state.activityData.length > 0) ? (
                                    this.state.activityData.map((logDetail) => {
                                        return (
                                            <tr key={logDetail.id}>
                                                <td>{moment(logDetail.current_time).format('MM/DD/YYYY h:mm:ss A')}</td>
                                                <td>{logDetail.first_name || logDetail.last_name ? logDetail.first_name + " " + logDetail.last_name : "System"}</td>
                                                <td>{logDetail.event}</td>
                                            </tr>)
                                    })) :
                                    <tr>
                                        <td>No Data Found !</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                        <TablePagination
                            rowsPerPageOptions={this.state.pages}
                            component="div"
                            count={this.state.totalRows}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                            nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                            onPageChange={this.handleLogData}
                            onRowsPerPageChange={(e) => this.onRowsPerPage(e)}
                        />
                    </DialogContent>
                    <footer>
                        <button className='activity_close_log' style={{width: '96.75%'}}
                            onClick={this.handleLogModelClose}><span className='font-face-futura'>Close</span></button>
                    </footer>
                </Dialog>

                <Dialog
                    className='issue-popup-main'
                    fullWidth={'lg'}
                    maxWidth={'lg'}
                    open={this.state.bugModal}
                    onClose={() => this.handleBugModal(false)}
                >
                    <DialogTitle>
                        <span className='reportIssue'>Report Issue</span>
                        <div style={{ borderBottom: '1px solid #5CB02C' }}></div>
                    </DialogTitle>
                    <DialogContent>
                        <Card className='issueCard'>
                            <CardContent className='issueContent'>
                                <div className='mx-2 row'>
                                    <div className='col-12 col-lg-12 col-sm-12'>
                                        <label htmlFor='issue'><strong style={{fontSize: '18px'}}>Please select the issue:</strong></label>
                                        <select className='issue_select' name='issue' value={this.state.selectValue} onChange={this.handleSelectOption} >
                                            <option value="">Select</option>
                                            <option value="0">Claim didn't violate (Multi-Practice Rule)</option>
                                            <option value="1">Claim didn't violate (Practice Specific Rule)</option>
                                            <option value="other">Other</option>
                                        </select>
                                    </div>
                                    {this.state.isOther === false ? <div className='mt-3 col-12 col-lg-12 col-sm-12'>
                                        <label htmlFor='rules'><strong style={{fontSize: '18px'}}>Which rule(s) should have violated?</strong></label>
                                        <select className='issue_select' onChange={this.changeDropdownRule}>
                                            <option value="">Select</option>
                                            {this.state.rules?.map((rule) => (
                                                <option key={rule.ruleId} value={rule.ruleId}>{rule.ruleName}</option>
                                            ))}
                                        </select>
                                    </div> : <></>}
                                    <div className='mt-3 col-12 col-lg-12 col-sm-12'>
                                        <label htmlFor='info'><strong style={{fontSize: '18px'}}>Please give us more information:</strong></label>
                                        <textarea onChange={e => this.setState({errorDescription: e.target.value})} className='px-2 py-2 free_text' placeholder='Type here...'></textarea>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </DialogContent>
                    <footer>
                        <div className='buttons'>
                            <button className='activity_close_log'
                                onClick={this.handleBugModal}>
                                    <span className='font-face-futura'>Close</span>
                            </button>
                            <button onClick={e => this.falseBugReport()} className='activity_submit_log'>
                                <span className='font-face-futura' >Submit</span>
                            </button>
                        </div>
                    </footer>
                </Dialog>
            </>
        )
    }
}

export default LogModal;