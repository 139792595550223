import React, { Component } from 'react';
import SideBar from '../Sidebar';
import { Card, CardContent,Button } from '@material-ui/core';
import { workerData } from './data';
import NotRanDatatable from './datatable';
import API from '../../Api';
import PropTypes from 'prop-types';
import LogModal from '../Inspection-NoViolation/log_modal';
import { userActivity } from '../../activity/userActivity';
import './inspection.css';

class InspectionNotRan extends Component{
    constructor(props){
        super(props);
        this.state = {
            chargeData: this.props.location.state,
            viewedDetail: sessionStorage.getItem('viewedDetail'),
            token : JSON.parse(sessionStorage.getItem("token")).token
        }
    }

    // function to redirect to insurance page
    handleInsurance = (id, linkedStatus, type) => {
        // console.log(this.state.chargeData);
        let chargeData = this.state.chargeData.allChargeData;

        const linkedData = chargeData.filter(f => f.EncounterID === id).map(item => {
            const obj = {}
            obj["pName"] = item.payerName;
            obj["pId"] = item.payerId;
            obj["linkedPayer"] = item.PrimaryInsurancePlanName;
            obj["linkedPayerId"] = item.PrimaryInsurancePolicyPlanID;

            return obj;
        })
        if(!linkedData.length) return;
        const {PrimaryInsurancePlanName, PrimaryInsurancePolicyPlanID} = chargeData[0]
         console.log("linkedData", chargeData)
        this.props.history.push({ pathname: `/insurances/${id}/${PrimaryInsurancePlanName}/${PrimaryInsurancePolicyPlanID}/${type}`, state: { } });
    }

    componentDidMount(){
        (async ()=>{
            if(this.state.viewedDetail === '0'){
                var activity = new userActivity('Viewed Detail ', this.state.token)
                await activity.setActivityAPI({encounterID : this.props.location.state.allChargeData[0].EncounterID});
                sessionStorage.setItem('viewedDetail', '1')
            }
        })()
    }

    render(){
        const {state} = this.props.location;
        const data = state.allChargeData.length > 1 ? state.allChargeData.slice(0, 1) : state.allChargeData;
        // const status = state.trizettoData[0];
        // console.log(status);

        const styles = {
            background: 'linear-gradient(0deg, #24476A 31.25%, #8EAFC2 299.25%)',
            height: 66,
            width: '100%',
            left:0,
            right:0,top:100,
            position: 'fixed'
        }
        const cardStyle = {
            border: '1px solid #D8D8D8',
            borderRadius: '9px',
            width: '95%',
            textAlign: 'left'
        }
        const heading = {
            fontSize: '20px'
        }

        return(
            <section style={{width: '99%',position: 'relative', marginTop: '75px', height:"calc(100vh - 100px)", background: 'white'}} className="font-face-futura">
                <SideBar handleChangePractice={(practice) => {
                    if (practice) {
                    this.props.history.push({pathname: "/rule-processor"})
                    }
                }} />
                <div className='subheader row'>
                    <div className='subheader-controls col-md-4'>
                        <button className='subheader-controls-close'
                            onClick = { () => { this.props.history.goBack()
                            }
                        }
                        >
                            <span className='subheader_controls_text'>
                                CLOSE
                            </span>
                        </button>
                    </div>
                    <div className='subheader-heading col-md-4'>
                        <h5 className="mt-3 text-white font-face-futura">
                            RULES HAVE NOT BEEN RAN FOR THIS CLAIM
                        </h5>
                    </div>
                    <div className='notran-main-h subheader-review-btn col-md-4'>
                        <LogModal encounterID={this.props.location.state.allChargeData[0].EncounterID}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6 col-sm-12 font-face-futura">
                        <div  className='left-head-section'>
                            <h4 style={{marginLeft:'11rem'}}>You have not ran this claim through the rule engine yet...</h4>
                        </div>
                  
                        {/* <h4 style={{marginTop: 100, marginLeft: 120}}>You have not ran this claim through the rule engine yet...</h4> */}
                    </div> 

                    <div className="col-lg-6 col-sm-12 mt-4 font-face-futura">
                        <div className="mrl-35 scroll-patient">
                            {data.map((patient, idx) => (
                                <><h5 className="pt-2 px-2 cardHead">Patient</h5>
                                    <Card key={idx} className='cardStyle' style={cardStyle}>
                                        {/* <CardHeader 
                                        style={headerStyle} 
                                        title={<h5 className="pt-2" style={{fontWeight: 'bold'}}>Patient</h5>} /> */}
                                        <CardContent>
                                            <div className="pt-2">
                                                <p><strong style={heading}>Patient Name:</strong><br /><span className='cardText'>{patient.PatientName}</span></p>
                                                <p><strong style={heading}>Patient ID:</strong><br /><span className='cardText'>{patient.PatientID}</span></p>
                                                <p><strong style={heading}>Gender:</strong><br /><span className='cardText'>{patient.Gender}</span></p>
                                                <p><strong style={heading}>Date of Birth:</strong><br /><span className='cardText'>{patient.PatientDateOfBirth}</span></p>
                                                <p><strong style={heading}>Patient Address:</strong><br /><span className='cardText'>{patient.AddressLine1 + ' ' + patient.AddressLine2 + ' ' + patient.City + ' ' + patient.Country + ' ' + patient.ZipCode}</span></p>
                                                <p><strong style={heading}>Patient Alert:</strong><br /><span className='cardText'>{patient.AlertMessage}</span></p>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </>
                            ))}
                            
                            {data.map((insurance, idx) => (
                                <><h5 className="pt-4 px-2 cardHead">Insurance</h5>
                                    <Card key={idx} className='cardStyle' style={cardStyle}>
                                        <CardContent>
                                            <div className="pt-2">
                                                <p><strong style={heading}>Case Name:</strong><br /><span className='cardText'>{insurance.CaseName}</span></p>
                                                <p><strong style={heading}>Primary Insurance:</strong><br />
                                                    <p className='cardText'>{insurance.PrimaryInsurancePlanName}</p>
                                                    <p className='mx-2 cardText2'>Policy:&nbsp;<span>{insurance.PrimaryInsurancePolicyNumber}</span></p>
                                                    <p className='mx-2 cardText'>Eligibility Status:&nbsp;
                                                        <span style={{cursor: 'pointer', color: insurance.PrimaryInsuranceLinkedStatus === true ? '#5CB02C' : '#954437'}}
                                                        onClick={(id) => this.handleInsurance(insurance.EncounterID, insurance.PrimaryInsuranceLinkedStatus, 'Primary')}>
                                                            {insurance.PrimaryInsuranceLinkedStatus === true ? 'EDI Linked' : 'EDI Not Linked'}
                                                        </span>
                                                    </p>
                                                </p>
                                                {insurance.SecondaryInsurancePlanName ? <>
                                                <p><strong style={heading}>Secondary Insurance:</strong>
                                                    <p className='cardText'>{insurance.SecondaryInsurancePlanName}</p>
                                                </p>
                                                <p className='cardText'>Policy:&nbsp;<span>{insurance.SecondaryInsurancePolicyNumber}</span></p>
                                                <p>Eligibility Status:&nbsp;
                                                    <span style={{cursor: 'pointer', color: insurance.SecondaryInsuranceLinkedStatus === true ? '#5CB02C' : '#954437'}} 
                                                    onClick={(id) => this.handleInsurance(insurance.EncounterID, insurance.SecondaryInsuranceLinkedStatus, 'Secondary')}>
                                                        {insurance.SecondaryInsuranceLinkedStatus === true ? 'EDI Linked' : 'EDI Not Linked'}
                                                    </span>
                                                </p></> : ''}
                                            </div>
                                        </CardContent>
                                    </Card>
                            </>
                            ))}
                                    
                            {data.map((encounter, idx) => (
                                <><h5 className="pt-4 px-2 cardHead">Encounter</h5>
                                    <Card key={idx} className='cardStyle' style={cardStyle}>
                                        {/* <CardHeader 
                                        style={headerStyle} 
                                        title={<h5 className="pt-2" style={{fontWeight: 'bold'}}>Encounter</h5>} /> */}
                                        <CardContent>
                                            <div className="pt-2">
                                                <p><strong style={heading}>Encounter ID:</strong><br /><span className='cardText'>{encounter.EncounterID}</span></p>
                                                <p><strong style={heading}>Created Date:</strong><br /><span className='cardText'>{new Date(encounter.CreatedDate).toLocaleDateString('en-US')}</span></p>
                                                <p><strong style={heading}>Post Date:</strong><br /><span className='cardText'>{encounter.PostingDate}</span></p>
                                                <p><strong style={heading}>Scheduling Provider:</strong><br /><span className='cardText'>{encounter.SchedulingProviderName}</span></p>
                                                <p><strong style={heading}>Rendering Provider:</strong><br /><span className='cardText'>{encounter.RenderingProviderName}</span></p>
                                                <p><strong style={heading}>Place of Service:</strong><br /><span className='cardText'>{encounter.ServiceLocationName}</span></p>
                                                <p><strong style={heading}>Place of Service Code:</strong><br /><span className='cardText'>{encounter.ServiceLocationPlaceOfServiceCode}</span></p>
                                                <p><strong style={heading}>Hospitalization Start Date:</strong><br /><span className='cardText'>{encounter.HospitalizationStartDate}</span></p>
                                                <p><strong style={heading}>Referring Provider:</strong><br /><span className='cardText'>{encounter.ReferringProviderName}</span></p>
                                            </div>
                                        </CardContent>
                                    </Card>
                            </>
                            ))}
                            
                            {/* {data.map((auth) => (
                            <Card className="mt-3" variant="outlined" style={cardStyle}>
                                <CardHeader 
                                style={headerStyle} 
                                title={<h5 className="pt-2" style={{fontWeight: 'bold'}}>Authorization</h5>} />
                                <CardContent>
                                    <div className="pt-2">
                                        <p><strong>Authorization Number:</strong>&nbsp;{auth.Authorization1Number}</p>
                                        <p><strong>Auth Start:</strong>&nbsp;{auth.Authorization1StartDate}</p>
                                        <p><strong>Number of Visits:</strong>&nbsp;{auth.Authorization1NumberOfVisits}</p>
                                        <p><strong>Auth End:</strong>&nbsp;{auth.Authorization1EndDate}</p>
                                        <p><strong>Number of Visits(Used):</strong>&nbsp;{auth.Authorization1NumberOfVisitsUsed}</p>
                                    </div>
                                </CardContent>
                            </Card>
                            ))} */}
                            
                            {data.map((encounter, idx) => (
                                <><h5 className="pt-4 px-2 cardHead">Workers Compensation</h5>
                                    <Card key={idx} className="mb-4 cardStyle" style={cardStyle}>
                                        <CardContent>
                                            <div className="pt-2">
                                                <p><strong style={heading}>DOI Start:</strong><br /><span className='cardText'>{encounter.DefaultCaseDatesInjuryStartDate}</span></p>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </>
                            ))}
                        </div>
                    </div>
                </div>
                
                <NotRanDatatable data={state.allChargeData} />
            </section>
        )
    }
}
InspectionNotRan.propTypes = {
    history: PropTypes.array.isRequired,
    location: PropTypes.string.isRequired
}

export default InspectionNotRan;