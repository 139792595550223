import { Box } from "layouts/Box";
import { Content } from "layouts/Content";
import { TextInput } from "layouts/Form/TextInput";
import { Main } from "layouts/Main";
import { Panel } from "layouts/Panel";
import Section from "layouts/Section";
import Sidebar from "../Sidebar";
import { MdHelp } from "react-icons/md";
import { ROButton } from "layouts/RoButton";
import { useEffect, useState } from "react";
import { fetchAllLinkedPayers } from "./helper";
import { ROModal } from "layouts/Modal";
import { TrizettoPayerList } from "./TrizettoPayersList";
import { LinkedPayerTable } from "./LinkedPayerTable";
import { LinkedInsuracePayerFooter } from "./Footer";
import { useParams } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { LoadingSpinner } from "lib/loader";
import { RoAlert } from "layouts/RoAlert";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Insurances = ({ }) => {
  const [linkedPayers, setLinkedPayers] = useState([]);
  const [linkedPayersCache, setLinkedPayersCache] = useState([]);
  const [selectedInsurancePayer, setSelectedInsurancePayer] = useState();
  const [selectedInsurancePayerId, setSelectedInsurancePayerId] = useState();
  const [showTrizettoModal, setShowTrizettoModal] = useState(false);
  const [showLinkingModal, setShowLinkingModal] = useState(false);
  const [showTicketModal, setShowTicketModal] = useState(false);
  const handleOpenLinkingModal = () => setShowLinkingModal(true);
  const handleOpenTicketModal = () => setShowTicketModal(!showTicketModal);
  const handleOpenTrizettoModal = () => setShowTrizettoModal(true);
  const handleCloseTrizettoModal = () => setShowTrizettoModal(false);

  const params = useParams();
  const location = useLocation();
  params.payerName = location?.state?.PrimaryInsurancePlanName;
  const { encounterId, payerName, payerId, encounterType } = useParams();
  const [isNewLinking, setIsNewLinking] = useState(false);
  const [linkPayerVal, setLinkPayerVal] = useState({
    payerName: payerName || "",
    payerId: payerId || "",
  });

  const handeSelectInsurancePayer = (e, linkpayer) => {
    const isChecked = e.target.checked;
    // setSelectedInsurancePayerId(isChecked ? linkpayer.id : undefined);
    setSelectedInsurancePayerId(isChecked ? linkpayer.linkedPayerId : undefined);
    setSelectedInsurancePayer(isChecked ? linkpayer : undefined);
  };

  const handleAfterUpdateLinkedPayer = (linkedPayer) => {
    const indexOfLinkedPayer = linkedPayers.findIndex(
      (payer) => payer.id === linkedPayer.id
    );
    if (indexOfLinkedPayer !== -1) {
      linkedPayers[indexOfLinkedPayer] = linkedPayer;
      setLinkedPayers(linkedPayers);
      setLinkedPayersCache(linkedPayers);
    }
  };

  const handleAfterDeleteLinkedPayer = (linkedPayer) => {
    // console.log(linkedPayer)
    // const listOfLinkedPayer = linkedPayers.filter(
    //   (payer) => payer.linkedPayerId !== linkedPayer.id
    // );
    // setLinkedPayers(listOfLinkedPayer);
    // setLinkedPayersCache(listOfLinkedPayer);
    fetchLinkedPayers();
  };

  const handleAfterRelinking = (payer, trizettoInsuranceCompany) => {
    if (!payer || !trizettoInsuranceCompany) {
      console.error("Debug on line number 54 - insurances/index.jsx");
      return;
    }

    const { id } = payer;
    const indexOfSelectedPayer = linkedPayers.findIndex(
      (payer) => payer.id === id
    );

    if (indexOfSelectedPayer !== -1) {
      linkedPayers[indexOfSelectedPayer].payerId =
        trizettoInsuranceCompany.payerId;
      linkedPayers[indexOfSelectedPayer].payerName =
        trizettoInsuranceCompany.payerName;
      linkedPayers[indexOfSelectedPayer].state = trizettoInsuranceCompany.state;

      setLinkedPayers([...linkedPayers]);
      setLinkedPayersCache([...linkedPayers]);
      setSelectedInsurancePayer({ ...linkedPayers[indexOfSelectedPayer] });
    }
  };

  const handleLinkClick = async () => {
    const isValidated = !Object.values(linkPayerVal).includes("");
    if (!isValidated) {
      NotificationManager.error("Payer name and payer id is required.");
      return;
    }

    const { payerId, payerName } = linkPayerVal;

    const isAlreadyLinked = (linkedPayers || []).find((payer) => {
      if (payer.linkedPayerId === payerId) {
        return payer;
      }
    });

    if (isAlreadyLinked) {
      setSelectedInsurancePayerId(isAlreadyLinked.id);
      setSelectedInsurancePayer({ ...isAlreadyLinked });
      NotificationManager.error(
        `${payerName} is already linked. You can relink it by selecting it from the table below.`
      );
      return;
    }

    await setSelectedInsurancePayer({
      ...selectedInsurancePayer,
      linkedPayer: payerName,
      linkedPayerId: payerId,
    });
    if (!params) await setSelectedInsurancePayerId();
    setIsNewLinking(true);
    await handleOpenLinkingModal();
    // await handleOpenTrizettoModal();
  };

  const fetchLinkedPayers = async () => {
    LoadingSpinner.show();
    const linkedPayersList = await fetchAllLinkedPayers();
    await setSelectedInsurancePayer(undefined)
    await setSelectedInsurancePayerId(undefined)
    await setLinkedPayers(linkedPayersList);
    await setLinkedPayersCache(linkedPayersList);
    LoadingSpinner.hide();
  };

  useEffect(() => {
    fetchLinkedPayers();
  }, []);

  useEffect(() => {
    if (params && params.payerId && params.payerName) {
      const id = (linkedPayers || []).find(
        (payer) => payer.linkedPayerId === params.payerId
      )?.linkedPayerId;
      // )?.id;
      // console.log(id, linkedPayers)
      if (id) {
        const linkedPayerByQuery = linkedPayers.find(
          (payer) => payer.linkedPayerId === params.payerId
        );
        setSelectedInsurancePayerId(id);
        setSelectedInsurancePayer({
          id,
          linkedPayerId: id,
          linkedPayer: params.payerName,
          payerName: linkedPayerByQuery.payerName,
          payerId: linkedPayerByQuery.payerId,
        });
        setIsNewLinking(false);
        setLinkPayerVal({ payerName: "", payerId: "" });
      } else {
        setIsNewLinking(true);
      }
    }
  }, [params, linkedPayers]);

  const renderFooter = () => (
    <LinkedInsuracePayerFooter
      setLinkedPayers={setLinkedPayers}
      linkedPayers={linkedPayersCache}
    />
  );

  const renderPanelHeader = () => (
    <Box>
      <div className="row text-left">
        <div className="col-md-7">
          <TextInput
            label="Payer Name:"
            onChange={(e) => {
              setLinkPayerVal({
                ...linkPayerVal,
                payerName: e.target.value || "",
              });
            }}
            value={linkPayerVal.payerName}
            className="w-100"
          />
        </div>
        <div className="col-md-4">
          <TextInput
            type="text"
            label="Payer ID:"
            value={linkPayerVal.payerId}
            className="w-100"
            onChange={(e) => {
              setLinkPayerVal({
                ...linkPayerVal,
                payerId: e.target.value || "",
              });
            }}
          />
        </div>
        <div
          className="col mt-3 pr-0 pl-0 text-left "
          style={{ paddingLeft: "0px", paddingRight: "0px" }}
        >
          <MdHelp
            style={{
              fontSize: "35px",
              color: "#D8D8D8",
              marginRight: "1rem",
              marginLeft: "-5px",
            }}
          />
          <ROButton onClick={handleLinkClick} className="mt-2 mt-xxl-0 me-2 me-xxl-0">link</ROButton>
        </div>
      </div>
    </Box>
  );

  const renderTrizettoPayerListModal = () => (
    <ROModal
      show={showTrizettoModal}
      handleClose={handleCloseTrizettoModal}
      title="Trizetto Payer List"
      style={{ top: "42%" }}
    >
      <TrizettoPayerList
        handleClose={handleCloseTrizettoModal}
        // handleTicket={renderTicketModal}
        // handleTicketOpen={handleOpenTicketModal}
        selectedInsurancePayer={selectedInsurancePayer}
        handleAfterRelinking={handleAfterRelinking}
        isNewLinking={isNewLinking}
        encounter={{ encounterId, encounterType }}
        refreshLinkedPayers={() => {
          setLinkPayerVal({
            payerName: "",
            payerId: "",
          });
          fetchLinkedPayers();
        }}
      />
    </ROModal>
  );

  let practiceData = JSON.parse(sessionStorage.getItem("practice"));

  const renderLinkingModal = () => (
    <RoAlert
      show={showLinkingModal}
      handleClose={() => setShowLinkingModal(false)}
      handleSubmit={() => {
        setShowTrizettoModal(true)
        setShowLinkingModal(false)
      }}
      title="you are linking this payer"
      type="info"
      content={
        <div className="alert-footer-text text-center mt-5 mb-4">
          You are about to link this insurance to the practice:<br />
          <span style={{ color: '#0E0E2C' }}>{practiceData?.practiceName}</span>
        </div>
      }
    />
  );

  const renderTicketModal = () => (
    <RoAlert
      show={showTicketModal}
      showTicket={showTicketModal}
      handleClose={() => setShowTicketModal(false)}
      handleSubmit={() => {
        setShowTicketModal(false)
      }}
      title="Submit Ticket?"
      type="info"
      content={
        <div className="alert-footer-text text-center mt-3 mb-4">
          Enrollment is required for this payer.<br />
          <span style={{ color: '#0E0E2C' }}>We can take care of that for you!</span>
        </div>
      }
    />
  );

  const handleChangePractice = (practice) => { fetchLinkedPayers(); }

  return (
    <Section>
      <Sidebar handleChangePractice={handleChangePractice} />
      <Main>
        <Content className={"py-2 pr-5"} style={{ paddingRight: "18px" }}>
          <Panel
            heading={"linked payers".toUpperCase()}
            bodyStyle={{ padding: "0px", paddingBottom: "0px" }}
            headStyle={{ height: "50px" }}
          >
            <div className="p-2">{renderPanelHeader()}</div>
            <br />
            <LinkedPayerTable
              linkedPayersList={linkedPayers}
              selectedInsurancePayerId={selectedInsurancePayerId}
              handeSelectInsurancePayer={handeSelectInsurancePayer}
              handleRelink={async () => {
                await setIsNewLinking(false);
                handleOpenTrizettoModal();
              }}
              selectedInsurancePayer={selectedInsurancePayer}
              handleAfterUpdateLinkedPayer={handleAfterUpdateLinkedPayer}
              handleAfterDeleteLinkedPayer={handleAfterDeleteLinkedPayer}
            />
          </Panel>
        </Content>
      </Main>
      {renderLinkingModal()}
      {renderFooter()}
      {renderTrizettoPayerListModal()}
      {/* {showTicketModal && renderTicketModal()} */}
    </Section>
  );
};

export default Insurances;
