import React, { Component } from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import "./inspection.css";
import PropTypes from 'prop-types';

class NotRanDatatable extends Component{
    constructor(props){
        super(props);
        this.state = {
            headers: ['S.No', 'Service Start', 'Service End', 'Procedure', 'Mod 1', 'Mod 2', 'Units', 'Unit Charge', 'Total Charges', 'Diag 1', 'Diag 2', 'Diag 3', 'Diag 4'],
            notranData: props.data,
            viewMore: false
        }
    }

    // function to toggle data
    viewData = () => {
        this.setState(prevState => ({
            viewMore: !prevState.viewMore
        }));
    }

    render() {
        const {headers, viewMore, notranData} = this.state;
        const showData = notranData.length > 1;
        // console.log(notranData.length);
        const data = viewMore ? notranData : notranData.slice(0, 1);
        // console.log(data);
        const styles = {
            width: '100%', 
            height: '50px',
            border: '1px solid darkgrey',
            transform: viewMore ? 'translate(0,0)' : 'translate(0, 0)'
        }
        return(
 
            <>
                <div className='abs'>
                    <div className='relat'>
                        {showData && (
                                <Button                    
                                className="view_more" 
                                onClick={this.viewData}>View {viewMore ? 'less' : 'more'}</Button>
                            )}
                        <TableContainer className="table-wrapper table-respon" style={{marginBottom: -25, overflowX: "none", borderRadius: '20px 20px 0px 0px'}}>
                            <Table style={styles}>
                                <TableHead style={{backgroundColor: '#24476A', border: '1px solid #24476A'}}>
                                    <TableRow>
                                        {headers.map((header) => (
                                        <TableCell key={header} className="text-center text-white" style={{fontWeight: 'bold'}}>{header}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody style={{backgroundColor:"white"}}>
                                    {data.map((notran, index) => (
                                        <TableRow key={index}>
                                            <TableCell className="text-center font-face-futura">{index+1}</TableCell>
                                            <TableCell className="text-center font-face-futura">{notran.ServiceStartDate}</TableCell>
                                            <TableCell className="text-center font-face-futura">{notran.ServiceEndDate}</TableCell>
                                            <TableCell className="text-center font-face-futura">{notran.ProcedureCode}</TableCell>
                                            <TableCell className="text-center font-face-futura">{notran.ProcedureModifier1}</TableCell>
                                            <TableCell className="text-center font-face-futura">{notran.ProcedureModifier2}</TableCell>
                                            <TableCell className="text-center font-face-futura">{parseFloat(notran.Units).toFixed(2)}</TableCell>
                                            <TableCell className="text-center font-face-futura">{parseFloat(notran.UnitCharge).toFixed(2)}</TableCell>
                                            <TableCell className="text-center font-face-futura">{notran.TotalCharges !== "" ? parseFloat(notran.TotalCharges).toFixed(2) : notran.TotalCharges}</TableCell>
                                            <TableCell className="text-center font-face-futura">{notran.EncounterDiagnosisID1}</TableCell>
                                            <TableCell className="text-center font-face-futura">{notran.EncounterDiagnosisID2}</TableCell>
                                            <TableCell className="text-center font-face-futura">{notran.EncounterDiagnosisID3}</TableCell>
                                            <TableCell className="text-center font-face-futura">{notran.EncounterDiagnosisID4}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </>
        );
    }
}

NotRanDatatable.propTypes = {
  data: PropTypes.array.isRequired
}

export default NotRanDatatable;