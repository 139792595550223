export const filterSettingsTeams = [
  { value: "first_name", label: " First Name" },
  { value: "last_name", label: "Last Name" },
  { value: "email", label: "Email" },
  // { value: "status", label: "Status" },
];

export const filterSettingsPractice = [
  { value: "practiceName", label: "Practice Name" },
  { value: "created_at", label: "Creation Date" },
  { value: "email", label: "Email" },
  // { value: "status", label: "Status" },
];

export const filterSettingsGroup = [
  { value: "groupName", label: "Group Name" },
  { value: "created_at", label: "Creation Date" },
  { value: "typeOfGroup", label: "Type" },
];

export const mapIndexToFilterArr = {
  0: filterSettingsTeams,
  1: filterSettingsPractice,
  2: filterSettingsGroup,
};
