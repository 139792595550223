import React from "react";
import { IconButton, TablePagination, Tooltip } from "@material-ui/core";
import "../settings.css";
import settingEdit from "assets/icons/settingEdit.svg";
import settingDelete from "assets/icons/settingDelete.svg";
import resendInvite from "assets/icons/resendInvite.svg";
import EditUserModal from "../AddUsers/EditUserModal";
import { useState } from "react";
import SendIcon from "@material-ui/icons/Send";
import API from "Api";
import { RoAlert } from "layouts/RoAlert";

const UserTable = ({
  users,
  userData,
  page,
  rowsPerPage,
  handlePageChange,
  handleRowsPerPage,
  deleteUsers,
  editUser,
  openEditUser,
  openEditUserModel,
  permissionValues,
  allPractices,
  updateUserDetails,
  getUsers
}) => {

  const [selectedUser, setSelectedUser] = useState();
  const [selectedPractice, setSelectedPractice] = useState();
  const [roleDetail, setRoleDetail] = useState(JSON.parse(sessionStorage.getItem('userDetail')));
  const [showPopupWithoutText, setShowPopupWithoutText] = useState(false);
  const [openResendPopup, setOpenResendPopup] = useState(false);

  const handleResendPopup = () => {
    setOpenResendPopup(true);
    setShowPopupWithoutText(true);
  };
  
  /**
   * function handle to open edit pop-up and send selected practice data through props
   * in the edit user pop-up
   * @param {*} user 
   */
  const handleEdit = (user) => {
    let arr = user.practiceId
    let practiceObj = arr.map((item)=> ({id: item}))
    let selectedPractices = practiceObj.map((item)=>{
      let obj = allPractices.find((o)=> o.id === item.id)
      return {...obj, ...item}
    })
    setSelectedPractice(selectedPractices)
    editUser(user.id)
    setSelectedUser(user)
  }

  const handleResendInvite = async (id) => {
    const result = await API.post('resendInvite', {id})
    console.log('result ', result)
    getUsers();
  }

  return (
    <div className="mb-3 table-responsive setting-table font-face-futura">
      <table className="tables">
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.length > 0 ? (
            users
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((user, idx) => (
                <tr key={idx} style={{ fontSize: 15, lineHeight: 4 }}>
                  <td>{user.first_name}</td>
                  <td>{user.last_name}</td>
                  <td>{user.email}</td>
                  <td>{(user.status === true && user.active === true) ? "Active" : 
                  (user.status === true && user.active === false && user.activeExpires > Date.now()) ? 'Invite Sent' : 
                  (user.status === true && user.active === false && user.activeExpires < Date.now()) ? 'Invite Expired' : "Inactive"}</td>
                  {/* <td>{user.status === true ? "Active" : user.active === false ? user.activeExpires > Date.now() ? 'Invite Sent' : 'Invite Expired' : "Inactive"}</td> */}
                  <td>
                  {((user.status === true && user.active === true) || user.status === false) ? <Tooltip title={roleDetail.roleId === 1 ? "" : "You don't have permission" } followCursor arrow>
                    <IconButton>
                      <input
                        type="image"
                        disabled={roleDetail.roleId === 1 ? false : true }
                        className="mx-1"
                        alt=""
                        src={settingEdit}
                        onClick={() => handleEdit(user)}
                      />
                    </IconButton>
                  </Tooltip> : user.active === false && user.activeExpires > Date.now() ? <Tooltip title={roleDetail.roleId === 1 ? "" : "You don't have permission" } followCursor arrow>
                    <IconButton>
                      <input
                        type="image"
                        disabled={roleDetail.roleId === 1 ? false : true }
                        className="mx-1"
                        alt=""
                        src={settingEdit}
                        onClick={() => handleEdit(user)}
                      />
                    </IconButton>
                  </Tooltip> : user.active === false && user.activeExpires < Date.now() ? <>
                  <Tooltip title={roleDetail.roleId === 1 ? "" : "You don't have permission" } followCursor arrow>
                    <input
                      type="image"
                      disabled={roleDetail.roleId === 1 ? false : true }
                      className="mx-1"
                      alt=""
                      src={resendInvite}
                      onClick={handleResendPopup}
                      // onClick={e => handleResendInvite(user.id)}
                    />
                  </Tooltip>
                  <RoAlert
                    showResendPopup={showPopupWithoutText}
                    show={openResendPopup}
                    handleClose={() => setOpenResendPopup(false)}
                    handleSubmit={() => {
                      handleResendInvite(user.id)
                      setOpenResendPopup(false);
                    }}
                    title="Resend Invite?"
                    type="info"
                    content={
                      <div className="alert-footer-text text-center mt-5 mb-4" style={{letterSpacing: "-0.06em"}}>
                        This user did not respond to the invite within 7 calendar days...<br />
                        We can resend the invite for you!
                      </div>
                    }
                  />
                  </>
                  : <Tooltip title={roleDetail.roleId === 1 ? "" : "You don't have permission" } followCursor arrow>
                    <IconButton>
                      <input
                        type="image"
                        disabled={roleDetail.roleId === 1 ? false : true }
                        className="mx-1"
                        alt=""
                        src={settingEdit}
                        onClick={() => handleEdit(user)}
                      />
                    </IconButton>
                  </Tooltip>}
                  
                  <Tooltip title={roleDetail.roleId === 1 ? "" : "You don't have permission" } followCursor arrow>
                    <IconButton>
                      <input
                        type="image"
                        disabled={roleDetail.roleId === 1 ? false : true }
                        className="mx-1"
                        alt=""
                        src={settingDelete}
                        onClick={(userId) => deleteUsers(user.id)}
                      />
                    </IconButton>
                  </Tooltip>
                  </td>
                </tr>
              ))
          ) : (
            <tr>
              <td colSpan={6} className="noRecord text-center">
                No records found!
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <EditUserModal 
        isEditMode={true} 
        open={openEditUserModel} 
        closeUserModal={openEditUser}
        selectedUser={selectedUser} 
        permissionValues={permissionValues}
        allPractices={allPractices}
        updateUserDetails={updateUserDetails}
        deleteUsers={deleteUsers}
        selectedPractice={selectedPractice}
        prevSelectedPractice={userData}
        getUsers={getUsers}
      />

      <TablePagination
        rowsPerPageOptions={[5, 10, 20]}
        component="div"
        count={users.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{ "aria-label": "Previous Page" }}
        nextIconButtonProps={{ "aria-label": "Next Page" }}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPage}
      />
    </div>
  );
};

export default UserTable;
