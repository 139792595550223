import { ROFooter } from "layouts/RoFooter";
import { SearchInput } from "layouts/Form/SearchInput";
import { IconMenu } from "layouts/IconMenu";
import { FooterFilterMenuItems, MapFilterToApiKeys } from "./constants";
import { useState } from "react";

export const LinkedInsuracePayerFooter = (props) => {
  const { setLinkedPayers, linkedPayers } = props;
  const [activeFilter, setActiveFilter] = useState("Insurance Name");
  const [searchInput, setSearchInput] = useState("");

  const handleSelectFilter = (selectedCol) => {
    setActiveFilter(selectedCol);
    setSearchInput("");
    setLinkedPayers(linkedPayers);
  };

  const handleSearch = (e) => {
    const val = e.target.value;
    setSearchInput(val);

    if (val === "" || !val) {
      setLinkedPayers(linkedPayers);
      return;
    }

    // Get the name of key from the API response which will help to filter the records
    const searchFor = MapFilterToApiKeys[activeFilter];

    const searchedRecords = linkedPayers.filter((payer) => {
      if (typeof payer[searchFor] === "string") {
        const uppercaseStr = payer[searchFor].toUpperCase();
        const uppercaseVal = val.toUpperCase();
        if (uppercaseStr.indexOf(uppercaseVal) !== -1) {
          return payer;
        }
      } else {
        if (payer[searchFor] === val) {
          return payer;
        }
      }
    });

    setLinkedPayers(searchedRecords);
  };

  return (
    <ROFooter>
      <div className="footer-left-area p-3">
        <IconMenu
          menuItems={FooterFilterMenuItems}
          handleSelect={handleSelectFilter}
          selectedItem={activeFilter}
        />
        <SearchInput
          value={searchInput}
          placeholder={` Search by ${activeFilter}`}
          onChange={handleSearch}
          className="mt-1 mx-2"
        />
      </div>
    </ROFooter>
  );
};
