import API from "Api";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const LoggerDetails = () => {
    const params = useParams();
    const [logContent, setContent] = useState('')
    const [errorLable, setErrLable] = useState('')

    useEffect(async () => {
        const fileContent = await API.get(`logFileContent/${params.loggerName}`)
        if(fileContent.status === 200){
            setContent(fileContent.data)
        } else if(fileContent.status === 204) {
            setErrLable('No Content Found')
        } else {
            setErrLable('Something Went Wrong')
        }
    }, [])

    return <>
        {errorLable}
        <div style={{"textAlign":"left"}}>{logContent}</div>
    </>
}

export default LoggerDetails;