import { Box as MaterialBox, Modal } from "@material-ui/core";
import { Panel } from "../Panel";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "transparent",
  border: "none",
  boxShadow: 24,
  height: "80%",
};

export const ROModal = (props) => {
  const { show, handleClose, title, description = "", children, style } = props;

  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby={title}
      aria-describedby={description}
    >
      <MaterialBox sx={{ ...modalStyle, ...style }}>
        <Panel
          heading={title || ""}
          cardStyle={{ border: "0px solid transparent" }}
          headStyle={{ textAlign: "center" }}
        >
          {children}
        </Panel>
      </MaterialBox>
    </Modal>
  );
};
