import React, { Component } from "react";
import Sidebar from "../Sidebar";
import {
  Tabs,
  Tab,
  Button,
  Card,
  CardContent,
  CardHeader,
  Tooltip,
} from "@material-ui/core";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import API from "../../Api";
import { withRouter } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import PropTypes from "prop-types";
import _ from "lodash";
import "./settings.css";
import { CSVLink } from "react-csv";
import {
  SampleDiagnosisCodes,
  SampleModifierCodes,
  SampleProcedureCodes,
} from "./data";
import Papa from "papaparse";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Swal from "sweetalert2";
import AddPracticeModal from "./AddPractice/AddPracticeModal";
import SettingFilterOnApi from "./SearchSettings";
import UserTable from "./Tables/UserTable";
import PracticeTable from "./Tables/PracticeTable";
import GroupTable from "./Tables/GroupTable";
import { debounce } from "global/helpers";
import AddUserModal from "./AddUsers/AddUserModal";

let setIntervalUser;
class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      /**
       * Records for tables
       */
      users: [],
      practiceList: [],
      groups: [],
      /** clones of table records */
      allUsers: [],
      allPractices: [],
      allGroups: [],

      /**
       * Searching and Tab required states
       */
      tabVal: 0, // active tab

      /**
       * Other essential states
       */
      open: false,
      anchorEl: null,
      openAddUserModel: false,
      openEditUserModel: false,
      openAddPracticeModal: false,
      openEditGroupModal: false,
      openAddGroupModal: false,
      openBulkModal: false,
      userDetails: [
        {
          name: "",
          email: "",
          temporaryPassword: "",
          role: "",
          practices: "",
        },
      ],
      userData: [],
      practiceData: [],

      groupName: "",
      selectType: "",
      diagnosisCodes: [],
      modifiers: [],
      procedureCodes: [],
      searchKey: "",
      showCard: false,
      file: "",
      optionsList: [],
      selectedValues: [],
      csvValues: [],
      groupValues: [],
      displayData: false,
      showDropdown: false,
      page: 0,
      offset: 0,
      totalRows: 0,
      rowsPerPage: 5,
      editGroupData: {},
      practiceName: "",
      practiceEmail: "",
      practiceKey: "",
      practicePassword: "",
      groupNpi: "",
      errors: {},
      showResult: false,
      showMessage: false,
      patientId: "",
      patientData: {},
      type: "password",
      showButton: false,
      loading: false,
      dropdownClose: false,
      permissionValues: [],
      filterVal: '',
      recentExpireInvite: 0,
      recentInviteExpire: false
    };
  }

  // function to handle page change
  handlePageChange = (event, currentPage) => {
    this.setState({
      page: currentPage,
    });
  };

  // function to handle rows per page
  handleRowsPerPage = (event) => {
    let rowspage = parseInt(event.target.value, 10);
    this.setState({
      rowsPerPage: rowspage,
      page: 0,
    });
  };

  // function to handle tabs
  handleTabs = (event, tabVal) => {
    this.setState({
      tabVal,
      page: 0,
      rowsPerPage: 5,
      dropdownClose: false
    });
  };

  // function to handle group name
  handleGroupName = (event) => {
    this.setState({groupName: event.target.value})
  }

  // function to handle type of group
  handleGroupType = (event) => {
    this.setState({
      selectType: event.target.value,
      showCard: true,
      displayData: false,
      groupValues: [],
      optionsList: [],
      showDropdown: false,
      searchKey: "",
      selectedValues: [],
      csvValues: [],
    });
  };

  // function to handle button toggle
  handleToggle = (event) => {
    this.setState({ anchorEl: event.target, open: !this.state.open });
  };

  // function to close menu
  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  // function to handle bulk user modal
  openBulk = () => {
    this.setState({ openBulkModal: true, anchorEl: null });
  };
  closeBulk = () => {
    this.setState({
      openBulkModal: false,
    });
  };


  // function to handle add group
  openAddGroup = () => {
    this.setState({
      openAddGroupModal: true,
      displayData: false,
      showCard: false,
      groupValues: [],
    });
  };

  closeAddGroup = () => {
    this.setState({
      openAddGroupModal: false,
      showCard: false,
      groupName: "",
      selectType: "",
      searchKey: "",
      showDropdown: false,
      displayData: false,
      groupValues: [],
      csvValues: [],
      selectedValues: [],
    });
  };

  // function to handle edit group
  openEditGroup = (group) => {
    this.setState({
      editGroupData: group,
      groupName: group.groupName,
      selectType: group.typeOfGroup,
      showCard: true,
      openEditGroupModal: true,
      groupValues: group.groupValues,
      selectedValues: group.groupValues,
      displayData: true,
    });
  };
  closeEditGroup = () => {
    this.setState({
      openEditGroupModal: false,
      groupName: "",
      selectType: "",
      searchKey: "",
      // showPracticeOptions: false,
      showCard: false,
      // showCodeOptions: false,
      displayData: false,
      csvValues: [],
      selectedValues: [],
      groupValues: [],
      showDropdown: false,
    });
    this.getGroups();
  };

  // function to go add practice Model page
  openAddPractice = () => {
    this.setState({ openAddPracticeModal: !this.state.openAddPracticeModal });
  };

  // function to add/remove user details
  // addUser = () => {
  //   this.setState((prevState) => ({
  //     userDetails: [
  //       ...prevState.userDetails,
  //       {
  //         name: "",
  //         email: "",
  //         temporaryPassword: "",
  //         role: "",
  //         practices: "",
  //       },
  //     ],
  //   }));
  // };

  /**
   * use to delete the users locally currently not in use
   * @param {*} index 
   */
  // deleteUser = (index) => {
  //   let userDetails = [...this.state.userDetails];
  //   userDetails.splice(index, 1);
  //   this.setState({ userDetails });
  // };

  /**
   * function to open and close Add users Models
   */
  openAdduser = () => {
    this.setState({ openAddUserModel: !this.state.openAddUserModel });
    this.getPermissionForUser()
  };

  /**
   * taking body_data from the AddUserModal component as an props and use to add
   * user by cliecking on send invite button
   * @param {} body_data 
   */
  addUserDetails = (body_data, token) => {
    API.post("createUsers", body_data).then((res) => {
      if (res.data.status === 200) {
        NotificationManager.error("User Email is Already Exist", " Email Already Exist", 3001)
      } else if (res.data.status === 201) {
        NotificationManager.success("Invite send Succesfully!", "Successfully!", 3000)
        this.getUsers();
      }
    }).catch((error) => {
      NotificationManager.error("You dont have permission to perform this action!")
      return error
    })
    this.setState({ openAddUserModel: !this.state.openAddUserModel });
  }

  /**
   * function is used to update the user, Take body_data from editUserModal to userTable then group component.
   * @param {*} userId 
   * @param {*} body_data 
   */
  updateUserDetails = (userId, body_data) => {
    API.put(`updateUser/${userId}`, body_data).then((response) => {
      if (response.data.status === 400) {
        NotificationManager.error(`${response.data.message}`, "error", 3001)
      } else {
        NotificationManager.success(`${response.data.message}`, "Successfully!", 3000)
        this.getUsers();
      }
    }).catch((error) => {
      NotificationManager.error("Something Went Wrong to update User!")
      return error;
    })
    this.setState({ openEditUserModel: !this.state.openEditUserModel })
  }

  /**
   * function is used to get permission or Role ID 
   * which is used to show on the add user and edit user modal pop-up
   */
  getPermissionForUser = () => {
    API.get("getRole").then((res) => {
      this.setState({ permissionValues: res.data.data })
    }).catch((error) => {
      console.log(error, "getRole")
    })
  }

  /**
   * function is used to open and close the editUserModal pop-up
   */
  openEditUser = () => {
    this.setState({ openEditUserModel: !this.state.openEditUserModel })
    this.getPermissionForUser()
  }

  /**
   * API to get list of all users
   */
  getUsers = () => {
    API.get("getAllUsers")
      .then((response) => {
        const users = response.data.data;
        if(users?.length > 0){
            const filterUser = users.filter(user => user.status === true && user.active === false && user.activeExpires > Date.now()).map(user => user.activeExpires).sort();
            if(filterUser.length > 0){
              this.setState({
                recentExpireInvite: filterUser
              })
              setIntervalUser= setInterval(() =>{
                if(this.state.recentExpireInvite.length>0 && this.state.recentExpireInvite[0] < Date.now()){
                  this.setState({
                    recentExpireInvite: this.state.recentExpireInvite.slice(1)
                  })
                } else if(this.state.recentExpireInvite.length == 0){
                  clearInterval(setIntervalUser)
                }
              }, 5000);
            }
          }
          // let activeUsers = users.filter(x => x.status === true);
          let activeUsers = users.filter((item) => (item.status === true && item.active === true) || (item.active === false && item.activeExpires > Date.now()));
        this.setState({
          users: activeUsers || [],
          allUsers: users || [],
          // users: users || [], 
          // allUsers: users || [], 
          filterVal: "Active"
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  /**
   * function is to get user data by ID while edit the user
   * @param {*} userId 
   */
  editUser = (userId) => {
    API.get(`getUser/${userId}`)
      .then((response) => {
        const userData = response.data;
        this.setState({ userData: userData });
      })
      .catch((err) => {
        console.error(err);
      });
    this.openEditUser()
  };

  /**
   * API to delete user from user table
   * @param {*} userId 
   */
  deleteUsers = (userId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are deleting the user.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#006F90",
      cancelButtonColor: "#C77B7B",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        API.put(`deleteUser/${userId}`)
          .then(() => {
            NotificationManager.success(
              "User deleted successfully!",
              "Successful!",
              3000
            );
            let userdata = this.state.users.filter((item) => {
              return item.id !== userId
            })
            this.setState({ users: userdata })
          })
          .catch((err) => {
            return err;
          });
      }
    });
  };

  // API to get practice list
  getPractices = () => {
    API.post("allPractices")
      .then((response) => {
        const practiceList = response.data.data;
        let activePractices = practiceList.filter(x => x.status === true);
        this.setState({
          practiceList: activePractices || [],
          allPractices: practiceList || [],
          filterVal: "Active"
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // function to go to edit practice
  editPractice = (id) => {
    const { users } = this.state
    this.props.history.push({
      pathname: "/practice-settings",
      state: { practiceData: null, users: users ? users : [], selectedUsers: [], practiceId: id },
    });
  };

  // API to get groups
  getGroups = () => {
    API.get("getAllGroup")
      .then((response) => {
        const groups = response.data.data;
        this.setState({
          groups: groups || [],
          allGroups: groups || [],
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // function to validate group
  isGroupValid = () => {
    const { groupName, selectType, groupValues } = this.state;
    let isValid = true;
    // let alpha = /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/;
    // let regexp = /^\S*$/;
    let alpha = /^[a-z\d\s]+$/i;
    if (!groupName || groupName === "") {
      isValid = false;
      NotificationManager.error("Group name is mandatory!", "Failure!", 3000);
    } else if (/^\s/.test(groupName) || !groupName.match(alpha)) {
      isValid = false;
      NotificationManager.error(
        "Group name cannot start with a space!",
        "Failure!",
        3000
      );
    } else if (!selectType) {
      isValid = false;
      NotificationManager.error("Please select the type!", "Failure!", 3000);
    } else if (groupValues.length === 0) {
      isValid = false;
      NotificationManager.error(
        "Please select or upload atleast one value to create a group!",
        "Failure!",
        3000
      );
    }
    return isValid;
  };

  // function to add a group
  addGroup = () => {
    if (this.isGroupValid()) {
      const data = {
        groupName: this.state.groupName,
        typeOfGroup: this.state.selectType,
        groupValues: [...new Set(this.state.groupValues)],
      };
      API.post("createGroup", data)
        .then(() => {
          NotificationManager.success(
            "Group added successfully!",
            "Successful!",
            3000
          );
          this.setState(
            {
              groupName: "",
              selectType: "",
              openAddGroupModal: false,
              showCard: false,
              optionsList: [],
              displayData: false,
              groupValues: [],
              csvValues: [],
              selectedValues: [],
            },
            () => {
              this.getGroups();
            }
          );
        })
        .catch((err) => {
          NotificationManager.error("Group cannot be added", "Failed!", 3000);
          console.error(err);
        });
    }
  };

  // API to delete group
  deleteGroup = (groupId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are deleting the group.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#006F90",
      cancelButtonColor: "#C77B7B",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        API.put(`deleteGroup/${groupId}`)
          .then(() => {
            NotificationManager.success(
              "Group deleted successfully!",
              "Successful!",
              3000
            );
            this.getGroups();
          })
          .catch((err) => {
            console.error(err);
          });
      }
    });
  };

  // API to edit group
  editGroup = (groupId) => {
    if (this.isGroupValid()) {
      const data = {
        groupName: this.state.groupName,
        typeOfGroup: this.state.selectType,
        groupValues: [...new Set(this.state.groupValues)],
      };
      API.put(`updateGroup/${groupId}`, data)
        .then((response) => {
          if (response.data.code === 200) {
            NotificationManager.success(
              "Group Updated successfully!",
              "Successful!",
              3000
            );
          } else {
            NotificationManager.error(response.data.message, "Failure!", 3000);
          }
          this.setState(
            {
              openEditGroupModal: false,
              groupName: "",
              selectType: "",
              csvValues: [],
              groupValues: [],
              optionsList: [],
              selectedValues: [],
              openAddGroupModal: false,
            },
            () => {
              this.getGroups();
            }
          );
        })
        .catch((err) => {
          NotificationManager.error(
            "An error occured while updating the group!",
            "Failed!",
            3000
          );
          console.error(err);
        });
    }
  };

  // function to get list of diagnosis codes
  getDiagnosisCodes = () => {
    API.get("getdiagnosisCodesList")
      .then((response) => {
        // let diagnosisList = response.data.data.splice(0,10);
        let diagnosisList = response.data.data;
        diagnosisList = diagnosisList.map(
          (f) => f.diagnosisCodes + " - " + f.diseaseName
        );
        this.setState({ optionList: diagnosisList });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // function to get list of procedure codes
  getProcedureCodes = () => {
    API.get("getProcedureCodesList")
      .then((response) => {
        let procedureList = response.data.data;
        procedureList = procedureList.map(
          (f) => f.procedureCodes + " - " + f.diseaseName
        );
        this.setState({ optionList: procedureList });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // function to get list of modifiers
  getModifiers = () => {
    API.get("getModifiersList")
      .then((response) => {
        let modifiersList = response.data.data;
        modifiersList = modifiersList.map((f) => f);
        this.setState({ optionList: modifiersList });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // function to search values in dropdown
  searchCodesInDropDown = _.debounce((type, searchKey) => {
    API.get(`searchCodesInDropDown/${type}`, {
      params: { searchData: searchKey },
    })
      .then((response) => {
        let searchOptions = response.data.result;
        switch (type) {
          case "Diagnosis Codes":
            searchOptions = searchOptions.map(
              (f) => f.diagnosisCodes + " - " + f.diseaseName
            );
            break;
          case "Procedure Codes":
            searchOptions = searchOptions.map(
              (f) => f.procedureCodes + " - " + f.diseaseName
            );
            break;
          case "Modifier Codes":
            searchOptions = searchOptions.map((f) => f.modifierCode);
            break;
          default:
            break;
        }
        this.setState({
          optionsList: searchOptions,
          showDropdown: true,
          displayData: false,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }, 1000);
  searchValues = (type, value) => {
    this.setState({ optionsList: [], showDropdown: false, displayData: true });
    this.setState({ searchKey: value });
    if (value.length > 0) {
      this.searchCodesInDropDown(type, value);
    } else {
      this.setState({ optionsList: [], displayData: false });
    }
  };

  // function to verify codes
  verifyCodes = () => {
    let { groupValues, selectedValues } = this.state;
    let message;
    let data = {
      typeOfGroup: this.state.selectType,
      groupValues: this.state.csvValues,
    };
    API.post("verifyCodes", data)
      .then((response) => {
        let csvValues = response.data.data;
        csvValues.map((f) => groupValues.push(f));
        message = response.data.message;
        NotificationManager.success(`${message}!`, "Successful!", 5000);
        this.setState({
          csvValues: csvValues,
          groupValues,
          selectedValues: [...selectedValues, ...new Set(csvValues)],
        });
        // this.setState({csvValues: response.data.data, groupValues});
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // function to upload csv file
  csvRead = (e) => {
    let { file } = this.state;
    file = e.target.files[0];
    // const fileReader = new FileReader();
    e.preventDefault();
    if (file) {
      Papa.parse(file, {
        complete: (result) => {
          if (this.state.selectType === result.data[0][0]) {
            result.data.shift();
            result.data.pop();
            let csvData = [];
            result.data.map((item) => csvData.push(item.toString()));
            this.setState(
              { csvValues: [...new Set(csvData)], displayData: true },
              () => {
                this.verifyCodes();
              }
            );
          } else {
            NotificationManager.error(
              `Please export valid group type!`,
              "Error!",
              5000
            );
          }
        },
      });
    }
    e.target.value = "";
  };

  // function to handle single select checkbox
  handleCheckbox = (e) => {
    let { selectedValues } = this.state;
    let selectedIndex = e.target.value;
    if (!selectedValues.includes(selectedIndex)) {
      this.setState({ selectedValues: [...selectedValues, selectedIndex] });
    } else {
      selectedValues = selectedValues.filter(
        (index) => index !== selectedIndex
      );
      this.setState({ selectedValues, groupValues: [...selectedValues] });
    }
  };

  // function to handle ok
  handleOk = () => {
    let { groupValues, selectedValues } = this.state;
    selectedValues.map((item) => groupValues.push(item));
    this.setState({
      showDropdown: false,
      displayData: true,
      searchKey: "",
    });
  };

  // function to handle search
  handleSearch = (activeFilter, userInput) => {
    const activeTab = this.state.tabVal;

    switch (activeTab) {
      /**
       * Handle user search
       */
      case 0:
        if (userInput.trim() === "") {
          this.setState({ users: this.state.allUsers });
          break;
        }
        const searchedResult = this.state.allUsers.map((u) => {
          return {
            ...u,
            // status: u.status ? "Active" : "Deactivated",
            status: u.status === true ? "Active" : u.active === false ? u.activeExpires > Date.now() ? 'Invite Sent' : 'Invite Expired' : "Inactive",
          };
        })
          .filter((user) => {
            if (user[activeFilter] && typeof user[activeFilter] === "string") {
              if (user[activeFilter].toUpperCase().indexOf(userInput.toUpperCase()) !== -1) {
                return user;
              }
            }
          });
        this.setState({
          users: searchedResult.map((u) => {
            return {
              ...u,
              status: u.status === "Active" ? true : false,
              // status: u.status === true ? "Active" : u.active === false ? u.activeExpires > Date.now() ? 'Invite Sent' : 'Invite Expired' : "Inactive",
            };
          })
        });
        break;

      /**
       * handle Prcatices Search
       */
      case 1:
        if (userInput.trim() === "") {
          this.setState({ practiceList: this.state.allPractices });
          break;
        }
        const searchPractice = this.state.allPractices
          .map((p) => {
            return {
              ...p,
              status: p.status ? "Active" : "Deactivated",
            };
          })
          .filter((practices) => {
            if (practices[activeFilter] && typeof practices[activeFilter] === "string") {
              if (practices[activeFilter].toUpperCase().indexOf(userInput.toUpperCase()) !== -1) {
                return practices;
              }
            }
          });
        this.setState({
          practiceList: searchPractice.map((p) => {
            return {
              ...p,
              status: p.status === "Active" ? true : false,
            };
          })
        });
        break;

      /**
       * Handle Gruops search
       */
      case 2:
        if (userInput.trim() === "") {
          this.setState({ groups: this.state.allGroups });
          break;
        }
        const searchGroup = this.state.allGroups.filter((groupFilter) => {
          if (
            groupFilter[activeFilter] &&
            typeof groupFilter[activeFilter] === "string"
          ) {
            if (
              groupFilter[activeFilter]
                .toUpperCase()
                .indexOf(userInput.toUpperCase()) !== -1
            ) {
              return groupFilter;
            }
          }
        });
        this.setState({ groups: searchGroup });
        break;
      default:
        break;
    }
  };

  // function to handle filter value change
  handleFilterChange = (e) => {
    const { tabVal, allUsers, allPractices } = this.state;
    const { name, value } = e.target;
    this.setState({ filterVal: value });
    let filterResult;
    if (tabVal === 0) {
      switch (value) {
        case 'Active':
          filterResult = allUsers.filter((item) => (item.status === true && item.active === true) || (item.status === true && item.active === false && item.activeExpires > Date.now()));
          break;
        case 'Invite Sent':
          filterResult = allUsers.filter((item) => item.status === true && item.active === false && item.activeExpires > Date.now());
          break;
        case 'Invite Expired':
          filterResult = allUsers.filter((item) => item.status === true && item.active === false && item.activeExpires < Date.now());
          break;
        case 'Inactive':
          filterResult = allUsers.filter((item) => (item.status === false) || (item.status === true && item.active === false && item.activeExpires < Date.now()));
          break;
        default:
          filterResult = allUsers.filter((item) => (item.status === true && item.active === true) || (item.status === true && item.active === false && item.activeExpires > Date.now()));
          break;
      }
      this.setState({ users: filterResult });
    } else if (tabVal === 1) {
      switch (value) {
        case 'Active':
          filterResult = allPractices.filter((item) => item.status === true);
          break;
        case 'Inactive':
          filterResult = allPractices.filter((item) => item.status === false);
          break;
        default:
          filterResult = allPractices.filter((item) => item.status === true);
          break;
      }
      this.setState({ practiceList: filterResult });
    }
  }

  componentDidMount() {
    this.getUsers();
    this.getPractices();
    this.getGroups();
  }

  componentWillUnmount(){
    clearInterval(setIntervalUser)
  }


  render() {
    const {
      tabVal,
      openEditGroupModal,
      openAddGroupModal,
      rowsPerPage,
      page,
      practiceList,
      users,
      userData,
      groups,
      selectType,
      optionsList,
      showCard,
      selectedValues,
      groupName,
      displayData,
      showDropdown,
      groupValues,
      openAddPracticeModal,
      openAddUserModel,
      permissionValues,
      allPractices,
      openEditUserModel
    } = this.state;
    let roleDetails = JSON.parse(sessionStorage.getItem('userDetail'));
    let finalGroupValues = [...new Set(groupValues)];
    const groupValuesList =
      finalGroupValues &&
      finalGroupValues.map((option, idx) => {
        if (selectType === "Diagnosis Codes") {
          return (
            <div key={idx} className="optionDrop">
              <CheckBoxIcon
                className="check"
                onClick={() =>
                  this.setState({
                    groupValues: groupValues.filter((a) => a !== option),
                    selectedValues: groupValues.filter((a) => a !== option),
                  })
                }
              />
              &nbsp;
              <span>{option}</span>
            </div>
          );
        } else if (selectType === "Modifier Codes") {
          return (
            <div key={idx} className="optionDrop">
              <CheckBoxIcon
                className="check"
                onClick={() =>
                  this.setState({
                    groupValues: groupValues.filter((a) => a !== option),
                    selectedValues: groupValues.filter((a) => a !== option),
                  })
                }
              />
              &nbsp;
              <span>{option}</span>
            </div>
          );
        } else if (selectType === "Procedure Codes") {
          return (
            <div key={idx} className="optionDrop">
              <CheckBoxIcon
                className="check"
                onClick={() =>
                  this.setState({
                    groupValues: groupValues.filter((a) => a !== option),
                    selectedValues: groupValues.filter((a) => a !== option),
                  })
                }
              />
              &nbsp;
              <span>{option}</span>
            </div>
          );
        }
        return option;
      });

    return (
      <section>
        <Sidebar />
        <div
          className="row"
          style={{
            marginTop: 130,
            marginLeft: 70,
            padding: "0px 40px 0px 40px",
          }}
        >
          <div className="col-lg-12 col-sm-12">
            <Tabs
              value={tabVal}
              variant="fullWidth"
              indicatorColor="#3A7CA5"
              onChange={this.handleTabs}
            >
              <Tab label="Team" />
              <Tab label="Practices" />
              <Tab label="Group Names" />
            </Tabs>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-lg-12 col-sm-12 filter-box">
            {tabVal === 0 && <div className="filter_col font-face-futura">
              <span className='mx-3'>Filter By:</span>
              <select className='filter_drop text-center' name="filter" value={this.state.filterVal ? this.state.filterVal : 'Active'} onChange={this.handleFilterChange}>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
                <option value="Invite Sent">Invite Sent</option>
                <option value="Invite Expired">Invite Expired</option>
              </select>
            </div>}
            {tabVal === 1 && <div className="filter_col font-face-futura">
              <span className='mx-3'>Filter By:</span>
              <Tooltip title={roleDetails.roleId === 1 ? "" : "You don't have permission"} followCursor arrow>
                <select className='filter_drop text-center' disabled={roleDetails.roleId === 1 ? false : true} name="filter" value={roleDetails.roleId !== 1 ? "Active" : this.state.filterVal ? this.state.filterVal : 'Active'} onChange={this.handleFilterChange}>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
              </Tooltip>
            </div>}
            <SettingFilterOnApi
              dropdownChange={this.state.dropdownClose}
              activeTab={tabVal}
              handleSearch={(activeFilter, userInput) => {
                debounce(() => {
                  this.handleSearch(activeFilter, userInput);
                }, 400);
              }}
            />

            {tabVal === 0 ? (
              <>
                <Tooltip title={roleDetails.roleId === 1 ? "" : "You don't have permission"} followCursor arrow>
                  <span className="font-face-futura">
                    <Button
                      className="mx-3 inviteBtn"
                      variant="contained"
                      disabled={roleDetails.roleId === 1 ? false : true}
                      onClick={this.openAdduser}
                    >
                      INVITE
                    </Button>
                  </span>
                </Tooltip>
                <AddUserModal
                  open={openAddUserModel}
                  closeUserModal={this.openAdduser}
                  permissionValues={permissionValues}
                  addUserDetails={this.addUserDetails}
                />
              </>
            ) : (
              <>
                <Tooltip
                  arrow
                  title={((roleDetails.roleId === 1) || (roleDetails.roleId === 2 && tabVal === 2) || (roleDetails.roleId === 3 && tabVal === 2)) ? "" : "You don't have permission"}
                >
                  <span className="font-face-futura">
                    <Button
                      variant="contained"
                      className="mx-3 inviteBtn"
                      disabled={((roleDetails.roleId === 1) || (roleDetails.roleId === 2 && tabVal === 2) || (roleDetails.roleId === 3 && tabVal === 2)) ? false : true}
                      onClick={
                        tabVal === 2 ? this.openAddGroup : this.openAddPractice
                      }
                    >
                      Add
                    </Button>
                  </span>
                </Tooltip>
                {openAddGroupModal && (
                  <Dialog
                    fullWidth
                    open={openAddGroupModal}
                    onClose={this.closeAddGroup}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className="margin-0px"
                  >
                    <div align="center" className="addGroupTitle ">
                      <DialogTitle id="alert-dialog-title">
                        <span className="font-face-futura">{"Add Group"} </span>
                      </DialogTitle>
                    </div>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        <span className="font-face-futura addGroupDesc">
                          This associates multiple diagnosis, procedure and
                          modifier codes in one group.
                        </span>
                      </DialogContentText>
                      <div className="mt-2">
                        <Card className="addGroupCard">
                          <CardHeader
                            className="addGroupCardHead"
                            title={
                              <h5 className="text-white font-face-futura">
                                Profile
                              </h5>
                            }
                          />
                          <CardContent className="addGroupContent">
                            <div>
                              <label htmlFor="group" className="cardLabel">
                                Group Name:
                              </label>
                              <br />
                              <input
                                type="text"
                                className="p-2 text"
                                value={groupName}
                                onChange={this.handleGroupName}
                              />
                            </div>
                            <div className="mt-2">
                              <label htmlFor="group" className="cardLabel">
                                Type of Group:
                              </label>
                              <select
                                fullWidth
                                className="px-2 text"
                                value={selectType}
                                onChange={this.handleGroupType}
                              >
                                <option value="" disabled>
                                  Select
                                </option>
                                <option value="Diagnosis Codes">
                                  Diagnosis Codes
                                </option>
                                <option value="Modifier Codes">
                                  Modifier Codes
                                </option>
                                <option value="Procedure Codes">
                                  Procedure Codes
                                </option>
                              </select>
                            </div>
                          </CardContent>
                        </Card>
                        {showCard && (
                          <>
                            <div className="mt-2 csvDiv font-face-futura">
                              <CSVLink
                                style={{
                                  textDecoration: "none",
                                  color: "#5CB02C",
                                }}
                                filename={
                                  selectType === "Diagnosis Codes"
                                    ? "DiagnosisCodes.csv"
                                    : selectType === "Modifier Codes"
                                      ? "ModifierCodes.csv"
                                      : selectType === "Procedure Codes"
                                        ? "ProcedureCodes.csv"
                                        : ""
                                }
                                data={
                                  selectType === "Diagnosis Codes"
                                    ? SampleDiagnosisCodes
                                    : selectType === "Modifier Codes"
                                      ? SampleModifierCodes
                                      : selectType === "Procedure Codes"
                                        ? SampleProcedureCodes
                                        : []
                                }
                              >
                                <span className="mx-3">
                                  Download Sample File
                                </span>
                              </CSVLink>
                              <label style={{ cursor: "pointer" }}>
                                <input
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={this.csvRead}
                                  accept=".csv"
                                />
                                <span>Add Via CSV File</span>
                              </label>
                            </div>
                            <Card className="mt-2 addGroupCard">
                              <CardHeader
                                className="addGroupCardHead"
                                title={
                                  <h5 className="text-white font-face-futura">
                                    {selectType}
                                  </h5>
                                }
                              />
                              <CardContent className="addGroupContent">
                                <input
                                  className="p-2 mb-3 text modifier-search"
                                  type="text"
                                  value={this.state.searchKey}
                                  placeholder="Type something to search..."
                                  onChange={(e) => {
                                    this.searchValues(
                                      selectType,
                                      e.target.value
                                    );
                                  }}
                                />
                                <br />
                                {showDropdown && (
                                  <div className="drop">
                                    {optionsList &&
                                      optionsList.map((option, idx) => (
                                        <div key={idx} className="optionDrop">
                                          {/* <input className={idx === 0 ? 'mt-2' : 'm-0'} */}
                                          <input
                                            type="checkbox"
                                            style={{ width: 18, height: 18 }}
                                            checked={selectedValues.includes(
                                              option
                                            )}
                                            value={option}
                                            onChange={this.handleCheckbox}
                                          />
                                          <span className="spantext">
                                            {option}
                                          </span>
                                        </div>
                                      ))}
                                    <div className="buttonDiv">
                                      <Button
                                        className="button"
                                        variant="contained"
                                        onClick={this.handleOk}
                                      >
                                        <span className="font-face-futura">
                                          Ok
                                        </span>
                                      </Button>
                                    </div>
                                  </div>
                                )}
                                {displayData && groupValuesList && (
                                  <div className="groupDrop">
                                    {groupValuesList}
                                  </div>
                                )}
                              </CardContent>
                            </Card>
                          </>
                        )}
                        <footer>
                          <div className="actionButton">
                            <Button
                              className="mt-4 mx-2 nevermind"
                              variant="outlined"
                              onClick={this.closeAddGroup}
                            >
                              <span
                                className="text-white font-face-futura"
                                style={{ fontSize: 18 }}
                              >
                                NeverMind
                              </span>
                            </Button>
                            <Button
                              className="mt-4 mx-2 looksgood"
                              variant="outlined"
                              onClick={this.addGroup}
                            >
                              <span
                                className="text-white font-face-futura"
                                style={{ fontSize: 18 }}
                              >
                                Add
                              </span>
                            </Button>
                          </div>
                        </footer>
                      </div>
                    </DialogContent>
                  </Dialog>
                )}

                <AddPracticeModal
                  open={openAddPracticeModal}
                  closeModal={this.openAddPractice}
                  allUsersList={users}
                  getAllPractice={this.getPractices}
                />
              </>
            )}
          </div>
        </div>

        <div
          className="row mt-3"
          style={{ marginLeft: 70, padding: "0 40px 0 40px" }}
        >
          {tabVal === 0 && (
            <UserTable
              users={users}
              userData={userData}
              page={page}
              rowsPerPage={rowsPerPage}
              handlePageChange={this.handlePageChange}
              handleRowsPerPage={this.handleRowsPerPage}
              editUser={this.editUser}
              deleteUsers={this.deleteUsers}
              openEditUser={this.openEditUser}
              openEditUserModel={openEditUserModel}
              permissionValues={permissionValues}
              allPractices={allPractices.filter(x => x.status === true)}
              updateUserDetails={this.updateUserDetails}
              getUsers={this.getUsers}
            />
          )}

          {tabVal === 1 && (
            <PracticeTable
              practiceList={practiceList}
              page={page}
              rowsPerPage={rowsPerPage}
              handlePageChange={this.handlePageChange}
              handleRowsPerPage={this.handleRowsPerPage}
              editPractice={this.editPractice}
            />
          )}

          {tabVal === 2 && (
            <GroupTable
              page={page}
              groups={groups}
              openEditGroupModal={openEditGroupModal}
              groupName={groupName}
              setState={this.setState}
              selectType={selectType}
              showCard={showCard}
              rowsPerPage={rowsPerPage}
              showDropdown={showDropdown}
              optionsList={optionsList}
              selectedValues={selectedValues}
              displayData={displayData}
              groupValuesList={groupValuesList}
              state={this.state}
              searchValues={this.searchValues}
              openEditGroup={this.openEditGroup}
              closeEditGroup={this.closeEditGroup}
              deleteGroup={this.deleteGroup}
              handleGroupType={this.handleGroupType}
              csvRead={this.csvRead}
              handlePageChange={this.handlePageChange}
              handleRowsPerPage={this.handleRowsPerPage}
              handleCheckbox={this.handleCheckbox}
              handleOk={this.handleOk}
              handleGroupName={this.handleGroupName}
              editGroup={this.editGroup}
              editGroupData={this.state.editGroupData}
            />
          )}
        </div>
      </section>
    );
  }
}
Settings.propTypes = {
  history: PropTypes.array.isRequired,
};
export default withRouter(Settings);
