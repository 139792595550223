import React, { Component } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

class LineChart extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data } = this.props;
    return (
      <div className="mb-3">
        <Line
          data={data}
          options={{
            responsive: true,
            maintainAspectRatio: data.datasets[0].label == "Claims" ? false : true,
            scales: {
              y: {
                ticks: {
                  color: '#535353',
                },
                beginAtZero: true,
              },
              x: {
                ticks: {
                  color: '#535353',
                }
              }
            },
            plugins: {
              title: {
                display: true,
              },
              legend: {
                position: "top",
                display: false,
              },
            },
          }}
        />
      </div>
    );
  }
}

export default LineChart;
