export const sortInAscending = (records, index) => {
  const sorted = records.sort((a, b) => {
    return a[index].val < b[index].val ? -1 : 1;
  });
  return sorted;
};

export const sortInDescending = (records, index) => {
  const sorted = records.sort((prev, next) => {
    return prev[index].val > next[index].val ? -1 : 1;
  });
  return sorted;
};

export const cloneRecords = (records) => {
  return JSON.parse(JSON.stringify(records));
};
