import React, { Component } from "react";
import { Radar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);
class RadarChart extends Component {
  constructor(props) {
    super(props);
    this.state = { };
  }
  render() {
    const {data} = this.props
    return (
      <>
        <Radar
          data={data}
          options={{
            maintainAspectRatio: false,
            responsive: true,
            scales: {
              r: {
                angleLines: {
                  color: '#D8D8D8'
                },
                grid: {
                  color: '#D8D8D8'
                },
                pointLabels: {
                  color: 'black'
                }
              }
            },
            elements: {
              line: {
                 borderWidth: 13
              }
           },
            plugins: {
              title: {
                display: true,
                font: 15
              },
              legend: {
                position: "top",
                display: false
              },
            },
          }}
        />
      </>
    );
  }
}

export default RadarChart;
