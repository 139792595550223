import React, { Component } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "./dashboard.css";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

class DashBoardChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        labels: [ '02/1', '02/2', '02/1', '02/1', '02/1','02/1', '02/1', '02/1', '02/1', '02/1', '02/1', '02/1', '02/1', '02/1', '02/1'],
        datasets: [
          {
            label: "Processed claims",
            data: [10, 20 ,80, 91 ,140, 35, 500, 410, 390, 450, 510, 2, 156, 74, 220],
            borderColor: "#5CB02C",
            backgroundColor: "#5CB02C",
            borderWidth: 3,
            pointRadius: 5,
          },
        ],
      },
    };
  }
  render() {
    return (
      <>
        <Line
          data={this.state.data}
          options={{
            maintainAspectRatio:false,
            responsive: true,
            scales: {
              y: {
                beginAtZero: false,
              },
            },
            plugins: {
              title: {
                display: true,
              },
              legend: {
                position: "top",
                display: false,
              },
            },
          }}
        />
      </>
    );
  }
}

export default DashBoardChart;
