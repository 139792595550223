/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import API from "../../../Api";
import Loader from "react-loader-spinner";
import showPassword from "../../../assets/icons/showPassword.svg";
import hidePassword from "../../../assets/icons/hidePassword.svg";
import helpIcon from "../../../assets/icons/help.svg";
import cancel from "../../../assets/icons/cancel.svg";
// React Notification
import { NotificationManager } from "react-notifications";
import "../settings.css";
import _ from "lodash";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { RoCheckbox } from "layouts/Form/Checkbox";
import { selectClasses } from "@mui/material";

export default class AddPracticeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      practiceName: "",
      practiceAcronym: "",
      practiceEmail: "",
      practiceKey: "",
      practicePassword: "",
      groupNpi: "",
      errors: {},
      showResult: false,
      showMessage: false,
      patientId: "",
      patientData: {},
      type: "password",
      showButton: false,
      loading: false,
      userSearchKey: "",
      userList: this.props?.allUsersList,
      userSearchList: this.props?.allUsersList,
      selectedUser: [],
    };
  }

  // function to toggle password visibility
  showHide = (event) => {
    this.setState({
      type: this.state.type === "password" ? "input" : "password",
    });
  };

  // function to handle change
  handleInputChange = (event) => {
    const { errors } = this.state;
    errors[event.target.name] = "";
    this.setState({ [event.target.name]: event.target.value, errors });
  };

  // function to handle practice modal
  openPracticeModal = () => {
    if (this.isFormValid()) {
      this.setState({ openModal: true });
    }
  };

  closePracticeModal = () => {
    this.setState({
      openAddPracticeModal: false,
      showResult: false,
      patientId: "",
      loading: false,
      showMessage: false,
      showButton: false,
    });
  };


  // function to handle form validation
  isFormValid = () => {
    const {
      errors,
      practiceName,
      practiceAcronym,
      practiceEmail,
      practiceKey,
      practicePassword,
      groupNpi,
    } = this.state;
    let isValid = true;
    var passwordReg =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/;
    if (!practiceName) {
      isValid = false;
      errors.practiceName = "*Please enter practice name";
    }
    if (!practiceAcronym || practiceAcronym.length > 5) {
      isValid = false;
      errors.practiceAcronym =
        "*Practice acronym cannot be more than 5 characters";
    }
    if (!groupNpi) {
      isValid = false;
      errors.groupNpi = "*Please enter group NPI";
    }
    // if (!practiceEmail || !(/^[+\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(practiceEmail))) {
    if (
      !practiceEmail ||
      !/^[+\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(practiceEmail)
    ) {
      isValid = false;
      errors.practiceEmail = "*Please enter a valid email";
    }
    if (!practiceKey) {
      isValid = false;
      errors.practiceKey = "*Please enter practice key";
    }
    if (!practicePassword) {
      isValid = false;
      errors.practicePassword = "*Please enter a password";
    } else if (practicePassword.length < 8 || practicePassword.length > 20) {
      isValid = false;
      errors.practicePassword = "*Password must be between 8 to 20 characters";
    } else if (!practicePassword.match(passwordReg)) {
      isValid = false;
      errors.practicePassword =
        "*Password must contain atleast one uppercase, one lowercase, one number and a special character";
    }
    this.setState({ errors });
    return isValid;
  };

  // function to clear form
  clearFormData = () => {
    this.setState({
      practiceName: "",
      practiceAcronym: "",
      practiceEmail: "",
      practiceKey: "",
      practicePassword: "",
      groupNpi: "",
      patientId: "",
      selectedUser: []
    });
  };

  /**
   * close practice pop-up
   * @returns 
   */
  closeAddPracticeModal = () => {
    this.props.closeModal();
    this.clearFormData()
  }

  // function to validate patient id
  isPatient = () => {
    this.setState({ loading: true });
    const { errors, patientId } = this.state;
    let isPatient = true;
    if (!patientId) {
      isPatient = false;
      errors.patientId = "*Please enter patient Id";
    }
    this.setState({ errors });
    return isPatient;
  };

  // API to get patient data based on patient id
  getPatient = (id) => {
    let practiceData = JSON.parse(sessionStorage.getItem("practice"));
    if (this.isPatient()) {
      // this.setState({showResult: true});
      API.post(`kareo/getPatient/${id}`, {
        customerKey: this.state.practiceKey,
        user: this.state.practiceEmail,
        password: this.state.practicePassword,
        practiceName: this.state.practiceName,
      })
        .then((response) => {
          // console.log(response.data.result);
          const patientData = response.data.result;
          this.setState({
            patientData: patientData,
            // showResult: true,
            loading: false,
          });
          if (response.data.success === true) {
            this.setState({
              showResult: true,
              showMessage: false,
              showButton: true,
            });
          } else {
            NotificationManager.error(
              "Patient not found for the given practice",
              "Failed!",
              3000
            );
            this.setState({
              showResult: false,
              showMessage: true,
              showButton: false,
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  /**
   * function to handle user search
   * @param {*} value
   */
  searchValues = (value) => {
    this.setState({ userSearchKey: value});
    let searchResult = this.state.userSearchList.filter((items) => {
      if (
        (items.first_name + " " + items.last_name)
          .toUpperCase()
          .indexOf(value.toUpperCase()) !== -1
      ) {
        return items;
      }
    });
    this.setState({ userList: searchResult });
  };

  /**
   * function to handle user checkbox
   * @param {*} e 
   */
  userCheckbox = (e) => {
    let value = e.target.value;
    let selectUser = [...this.state.selectedUser, value];
    if (this.state.selectedUser.includes(value)) {
      selectUser = selectUser.filter((id) => id !== value);
    }
    this.setState({ selectedUser: selectUser });
  };

  // API to add a practice
  addPractice = () => {
    const {
      practiceName,
      practiceAcronym,
      practiceEmail,
      practiceKey,
      practicePassword,
      groupNpi,
      selectedUser
    } = this.state;
    const data = {
      practiceName: practiceName,
      practiceAcronym: practiceAcronym,
      email: practiceEmail,
      key: practiceKey,
      password: practicePassword,
      groupNpi: groupNpi,
      userId: selectedUser
    };
    API.post("/addPractice", data)
      .then((response) => {
        console.log(response);
        if (response) {
          this.props.closeModal();
          NotificationManager.success(
            "Practice added successfully!",
            "Successful!",
            3000
          );
          this.props.getAllPractice()
          this.setState({
              patientId: "",
              showResult: false,
              showMessage: false,
            });
          this.clearFormData();
        } else {
          NotificationManager.error(
            "Practice cannot be added",
            "Failed!",
            3000
          );
        }
      })
      .catch((err) => {
        return err;
      });
  };

  render() {
    const {
      practiceName,
      practiceAcronym,
      practiceEmail,
      practiceKey,
      practicePassword,
      groupNpi,
      errors,
      showResult,
      showMessage,
      patientData,
      patientId,
      type,
      showButton,
      selectedUser,
      userSearchKey,
      userList,
    } = this.state;
    const style = {
      textAlign: "left",
      height: 0,
      lineHeight: "16px",
    };

    return (
      <>
        {this.props.open && (
          <Dialog
            fullWidth
            open={this.props.open}
            onClose={this.closeAddPracticeModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <div align="center" className="planTitle">
              <DialogTitle id="alert-dialog-title">
                <span className="font-face-futura">{"Add Practice"}</span>
              </DialogTitle>
            </div>
            <DialogContent className="pos-relative">
              <div className="mt-2">
                <Card className="planCard mb-4" style={{ height: "460px" }}>
                  <CardHeader
                    className="planCardHead"
                    title={
                      <h5 className="text-white font-face-futura">Profile</h5>
                    }
                  />
                  <CardContent className="planContent">
                    <div className="row">
                      <div className="col-lg-12 col-sm-12">
                        <label htmlFor="practiceName">
                          <strong>Practice Name:</strong>
                        </label>
                        <input
                          className="practice-input"
                          name="practiceName"
                          value={practiceName}
                          onChange={this.handleInputChange}
                        />
                        <p className="text-danger" style={style}>
                          {errors.practiceName}
                        </p>
                      </div>
                      <div className="col-lg-12 col-sm-12">
                        <label htmlFor="practiceAcro">
                          <strong>Practice Acronym:</strong>
                        </label>
                        <input
                          className="practice-input"
                          name="practiceAcronym"
                          value={practiceAcronym.toUpperCase()}
                          onChange={this.handleInputChange}
                        />
                        <p className="text-danger" style={style}>
                          {errors.practiceAcronym}
                        </p>
                      </div>
                      <div className="col-lg-12 col-sm-12">
                        <label htmlFor="practiceEmail">
                          <strong>Email:</strong>
                        </label>
                        <input
                          className="practice-input"
                          name="practiceEmail"
                          value={practiceEmail}
                          onChange={this.handleInputChange}
                        />
                        <p className="text-danger" style={style}>
                          {errors.practiceEmail}
                        </p>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 col-sm-12 position-relative">
                          <label htmlFor="password">
                            <strong>Current Password:</strong>
                          </label>
                          <input
                            className="practice-input"
                            type={type}
                            name="practicePassword"
                            value={practicePassword}
                            onChange={this.handleInputChange}
                          />
                          <img
                            style={{ cursor: "pointer" }}
                            src={
                              type === "password" ? hidePassword : showPassword
                            }
                            alt="password"
                            onClick={this.showHide}
                            className="form-btn"
                          />
                          <p className="text-danger" style={style}>
                            {errors.practicePassword}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 col-sm-12 position-relative">
                          <label htmlFor="practiceKey">
                            <strong>Practice Key:</strong>
                          </label>
                          <input
                            className="practice-input"
                            name="practiceKey"
                            value={practiceKey}
                            onChange={this.handleInputChange}
                          />
                          <img src={helpIcon} alt="help" className="form-btn" />
                          <p className="text-danger" style={style}>
                            {errors.practiceKey}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-12 col-sm-12">
                        <label htmlFor="practiceEmail">
                          <strong>Group NPI:</strong>
                        </label>
                        <input
                          className="practice-input"
                          name="groupNpi"
                          value={groupNpi}
                          onChange={this.handleInputChange}
                        />
                        <p className="text-danger" style={style}>
                          {errors.groupNpi}
                        </p>
                      </div>
                    </div>
                  </CardContent>
                </Card>
                <Card className="mt-2 planCard">
                  <CardHeader
                    className="planCardHead"
                    title={
                      <h5 className="text-white font-face-futura">
                        Verify & Add
                      </h5>
                    }
                  />
                  <CardContent className="planContent pb-1">
                    {showResult ? (
                      <div className="mb-4">
                        <div className="row mt-2">
                          <div className="col-lg-12 col-sm-12">
                            <span>
                              <strong>Patient ID:</strong>&nbsp;
                              {patientData?.ID}
                            </span>
                          </div>
                          <div className="col-lg-12 col-sm-12">
                            <span>
                              <strong>Patient Name:</strong>&nbsp;
                              {patientData?.PatientFullName}
                            </span>
                          </div>
                          <div className="col-lg-12 col-sm-12">
                            <span>
                              <strong>Patient DOB:</strong>&nbsp;
                              {patientData?.DOB}
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : showMessage ? (
                      <div align="center" className="mb-4">
                        <img src={cancel} alt="cancel" />
                        <p
                          className="mt-3 text-center"
                          style={{ fontSize: "50px" }}
                        >
                          There were no results
                        </p>
                      </div>
                    ) : (
                      <div className="row">
                        <div className="col-lg-12 col-sm-12">
                          <input
                            className="practice-input"
                            placeholder="Search with a patient's ID from Kareo..."
                            name="patientId"
                            value={patientId}
                            onChange={this.handleInputChange}
                          />
                          <p
                            className="text-danger"
                            style={{
                              height: 0,
                              lineHeight: "16px",
                              marginRight: 170,
                            }}
                          >
                            {errors.patientId}
                          </p>
                        </div>
                      </div>
                    )}
                    {this.state.loading && patientId ? (
                      <Loader
                        className="mb-3 mt-3 text-center"
                        type="TailSpin"
                        color="#10496a"
                        height={50}
                        width={50}
                      />
                    ) : showResult ? (
                      showResult
                    ) : (
                      showMessage
                    )}
                    <footer>
                      {showButton || showMessage ? (
                        <div align="center" style={{ marginTop: "35px" }}>
                          <p className="showButtonText">
                            {showResult
                              ? "Not what you were looking for?"
                              : "Try searching again..."}
                          </p>
                          <Button
                            fullWidth
                            className="searchAgain"
                            variant="outlined"
                            onClick={this.closePracticeModal}
                          >
                            <span className="text-white font-face-futura">
                              Search Again
                            </span>
                          </Button>
                        </div>
                      ) : (
                        <div className="mt-2" align="center">
                          <Button
                            variant="contained"
                            className="verifyLink"
                            onClick={(id) => this.getPatient(patientId)}
                          >
                            <span className="text-white font-face-futura">
                              Verify Link
                            </span>
                          </Button>
                        </div>
                      )}
                    </footer>
                  </CardContent>
                </Card>
                <Card className="mt-2 planCard pos-relative">
                  <CardHeader
                    className="planCardHead"
                    title={
                      <h5 className="text-white font-face-futura">
                        User Access
                      </h5>
                    }
                  />
                  <CardContent className="addGroupContent px-2 pbb-0">
                    <input
                      className="p-2 text"
                      type="text"
                      value={userSearchKey}
                      placeholder="Type something to search..."
                      onChange={(e) => {
                        this.searchValues(e.target.value);
                      }}
                    />
                    <br/>
                    <div className="drops mt-2">
                      {userList.length > 0 ? (
                        userList.map((option, idx) => (
                          <div key={idx} className="optionDrop">
                            <RoCheckbox
                              style={{ width: 18, height: 18 }}
                              value={option.id}
                              checked={selectedUser.includes(option.id)}
                              onChange={this.userCheckbox}
                            />
                            <span className="spantext">
                              {option.first_name + " " + option.last_name}
                            </span>
                          </div>
                        ))
                      ) : (
                        <span className="noRecordPrac">No Result found!</span>
                      )}
                    </div>
                  </CardContent>
                </Card>
              </div>
            </DialogContent>
            <DialogActions className="set-btn">
                  <div className="actionButton">
                    <Button
                      fullWidth
                      className="mt-4 mb-3 mx-2 nevermindBtn"
                      variant="outlined"
                      onClick={this.closeAddPracticeModal}
                    >
                      <span
                        className="text-white font-face-futura"
                        style={{ fontSize: 18 }}
                      >
                        Nevermind
                      </span>
                    </Button>
                    <Button
                      fullWidth
                      className={
                        showResult
                          ? "mt-4 mb-3 mx-2 linkBtnAfter"
                          : "mt-4 mb-3 mx-2 linkBtnBefore"
                      }
                      variant="outlined"
                      disabled={showResult ? false : true}
                      onClick={this.addPractice}
                    >
                      <span
                        className="text-white font-face-futura"
                        style={{ fontSize: 18 }}
                      >
                        Add Practice
                      </span>
                    </Button>
                  </div>
                </DialogActions>
          </Dialog>
        )}
      </>
    );
  }
}
