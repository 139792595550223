export function DateConverter(date) {
    let formattedDate = new Date(date);
    let hours = formattedDate.getHours();
    let minutes = formattedDate.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    let ranTime = hours + ':' + minutes + ' ' + ampm;
    console.log(ranTime);
}

export const getDataFromSession = (key) => {
    try {
        return JSON.parse(sessionStorage.getItem(key)) || []
    } catch (error) {
        return false;
    }
}