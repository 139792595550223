import React, { Component } from "react";
import { SearchInput } from "layouts/Form/SearchInput";
import { IconMenu } from "layouts/IconMenu";
import {
  filterSettingsTeams,
  filterSettingsPractice,
  filterSettingsGroup,
  mapIndexToFilterArr,
} from "./constants";
import "./style.css";

class SettingFilterOnApi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeFilter: "first_name",
      searchInput: "",
    };
  }

  handleSelectFilter = (selectedCol) => {
    this.setState({ activeFilter: selectedCol, searchInput: "" });
  };
  
  handleSearch = (e) => {
    const val = e.target.value || ''
    this.setState({searchInput: val})
    this.props.handleSearch(this.state.activeFilter, val)
   };

  getActiveMenuItems = () => {
    const activeTab = this.props.activeTab;
    if (activeTab === 0) {
      return filterSettingsTeams;
    } else if (activeTab === 1) {
      return filterSettingsPractice;
    } else {
      return filterSettingsGroup;
    }
  };

  getDropdownSize = () => {
    // const activeTab = this.props.activeTab;
    // if (activeTab === 0) {
    //   return 120;
    // } else if (activeTab === 1) {
    //   return 120;
    // } else {
    //   return 98;
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.activeTab !== this.props.activeTab) {
      this.setState({
        searchInput: "",
        activeFilter: this.getActiveMenuItems()[0].value,
      });
    }
  }

  getPlaceholderVal = () => {
    const activeTab = this.props.activeTab;
    const selected = mapIndexToFilterArr[activeTab].find(x => {
      if(x.value === this.state.activeFilter) {
        return x
      }
    });
    return selected?.label
  }

  render() {
    return (
      <div>
        <IconMenu
          menuItems={this.getActiveMenuItems()}
          handleSelect={this.handleSelectFilter}
          selectedItem={this.state.activeFilter}
          customClass="setting-filter"
          dropdownStyle={{height: this.getDropdownSize()}}
          tabValueChange ={this.props.activeTab}
        />
        <SearchInput
          value={this.state.searchInput}
          placeholder={` Search by ${this.getPlaceholderVal()}`}
          onChange={this.handleSearch}
          className="mt-1 mx-2"
        />
      </div>
    );
  }
}

export default SettingFilterOnApi;
