import React from "react";
import "./style.css";

export const TextInput = (props) => {
  const {
    style,
    className = "",
    value,
    onChange,
    type = "text",
    defaultValue = "",
    label = "",
    placeholder,
    name,
    ...rest
  } = props;

  if (value !== undefined) {
    return (
      <React.Fragment>
        <label className="textInputLabel font-face-futura">{label}</label>
        <input
          type={type}
          className={"p-2 textInput font-face-futura-placeholder " + className}
          style={style}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          name={name}
          {...rest}
        />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <label className="textInputLabel">{label}</label>
      <input
        type={type}
        className={"p-2 textInput font-face-futura-placeholder " + className}
        style={style}
        defaultValue={defaultValue}
        placeholder={placeholder}
        onChange={onChange}
        {...rest}
      />
    </React.Fragment>
  );
};
