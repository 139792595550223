import { IconButton } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import { useEffect, useState } from "react";
import "./style.css";

export const IconMenu = (props) => {
  const { menuItems = [], handleSelect, selectedItem, customClass, dropdownStyle, tabValueChange } = props;
  const [showMenuItems, setShowMenuItems] = useState(false);

  const handleChange = (e) => {
    const val = e.target.value;
    handleSelect(val);
    setShowMenuItems(false);
  };

  const getClassName = () => {
    if (customClass) {
      return showMenuItems ? customClass + ' show-selector' : customClass + ' none-selector'
    }

    return showMenuItems
      ? "sticky-selection2 show-selector"
      : "sticky-selection2 none-selector"
  }


  useEffect(()=>{
    if(showMenuItems === true){
        setShowMenuItems(false)
      }
  },[tabValueChange])

  return (
    <>
      <select style={{ height: '162px', ...dropdownStyle }}
        className={getClassName()}
        onChange={handleChange}
        multiple
      >
        {menuItems.map((item, key) => (
          <>
            <option
              selected={selectedItem === item.value}
              className={`p-1 option option-list text-center font-face-futura-footer
                     ${selectedItem === item.value ? "bg-active" : ""}`}
              key={key}
              value={item.value}
            >
              {item.label}
            </option>
          </>
        ))}
      </select>
      <IconButton 
        onClick={() => setShowMenuItems((prev) => !prev)}>
        <FilterListIcon
          style={{
            backgroundColor: "white",
            width: 32,
            height: 30,
            borderRadius: "50%",
            color: "#535353",
            marginTop: 0,
          }}
        />
      </IconButton>
    </>
  );
};
