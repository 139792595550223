import {
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
} from "@material-ui/core";
import {
  systemAdminDo,
  systemAdminDont,
  ruleCretorDo,
  ruleCretorDont,
  ruleLimitedDo,
  ruleLimitedDont,
  ruleEditDo,
  ruleEditDont,
  claimScrubberDo,
  claimScrubberDont,
} from "./UserData";
import React, { Component } from "react";
import "./indexUser.css";
import practiceEmailIcon from "../../../assets/icons/practiceEmail.svg";
import practiceCalendar from "../../../assets/icons/practiceCalendar.svg";
import showPassword from "../../../assets/icons/showPassword.svg";
import hidePassword from "../../../assets/icons/hidePassword.svg";
import { RoCheckbox } from "layouts/Form/Checkbox";
import { NotificationManager } from "react-notifications";
import API from "Api";
import Swal from "sweetalert2";

class EditUserModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      userEmail: "",
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      userId: undefined,
      activeStatus:true,
      selectRole: "",
      practiceId: [],
      prevPracticeId: [],
      allPractices: [],
      selectedPractice: [],
      searchKey: "",
      showResult: false,
      showPass: false,
      showConfirmPass: false,
      showNewPass: false,
      showPracticeList: false,
    };
  }

  /**
   * lifecycle method to get all props value from the userTable and group.js component
   * @param {*} nextProps
   */
  componentWillReceiveProps(nextProps) {
    this.handlePermissionChange(nextProps?.selectedUser?.roleid);
    this.setState({
      firstName: nextProps?.selectedUser?.first_name,
      lastName: nextProps?.selectedUser?.last_name,
      userEmail: nextProps?.selectedUser?.email,
      currentPassword: nextProps?.selectedUser?.password,
      selectRole: nextProps?.selectedUser?.roleid,
      practiceId: nextProps?.selectedUser?.practiceId,
      userId: nextProps?.selectedUser?.id,
      activeStatus: nextProps?.selectedUser?.status,
      allPractices: nextProps?.allPractices,
      selectedPractice: nextProps?.selectedPractice,
      prevPracticeId: nextProps?.prevSelectedPractice,
    });
  }
  /**
   * function to handle input change
   * @param {*} event
   */
  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  showPassword = () => {
    this.setState({ showPass: !this.state.showPass });
  };
  showConfirmPass = () => {
    this.setState({ showConfirmPass: !this.state.showConfirmPass });
  };
  showNewPass = () => {
    this.setState({ showNewPass: !this.state.showNewPass });
  };

  /**
   * method is used for edit user form validations
   */
  isUserValid = () => {
    const { firstName, lastName, userEmail, selectRole, practiceId, newPassword, confirmPassword } =
      this.state;

    let isValid = true;

    if (!firstName) {
      NotificationManager.error("Please enter a First Name", "Failure!", 3000);
      isValid = false;
    } else if (!lastName) {
      NotificationManager.error("Please enter a Last Name", "Failure!", 3000);
      isValid = false;
    } else if (
      !userEmail ||
      !/^[+\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(userEmail)
    ) {
      NotificationManager.error(
        "Please enter a valid user email",
        "Failure!",
        3000
      );
      isValid = false;
    } else if (selectRole === "select") {
      NotificationManager.error(
        "Please select any of the permission level",
        "Failure!",
        3000
      );
      isValid = false;
    } else if (practiceId.length === 0) {
      NotificationManager.error(
        "Choose any of the practices",
        "Failure!",
        3000
      );
      isValid = false;
    } else if (confirmPassword !== newPassword) {
      NotificationManager.error(
        "Confirm password value doesn't match new password value",
        "Failure!",
        3000
      );
      isValid = false;
    }
    return isValid;
  };

  /**
   * function to handle Drop down change of permissions
   * @param {*} e
   */
  handlePermissionChange = (e) => {
    // let value = `${e}`;
    let value = e?.toString();
    switch (value) {
      case "select":
        this.setState({
          userCanDo: [],
          userCantDo: [],
        });
        break;
      case "1":
        this.setState({
          userCanDo: systemAdminDo,
          userCantDo: systemAdminDont,
        });
        break;
      case "2":
        this.setState({
          userCanDo: ruleCretorDo,
          userCantDo: ruleCretorDont,
        });
        break;
      case "3":
        this.setState({
          userCanDo: ruleLimitedDo,
          userCantDo: ruleLimitedDont,
        });
        break;
      case "4":
        this.setState({
          userCanDo: ruleEditDo,
          userCantDo: ruleEditDont,
        });
        break;
      case "5":
        this.setState({
          userCanDo: claimScrubberDo,
          userCantDo: claimScrubberDont,
        });
        break;
      default:
        break;
    }
    this.setState({ selectRole: e });
  };

  /**
   * function to handle Search input in practice access
   * @param {*} value
   */
  searchValues = (value) => {
    this.setState({ showPracticeList: true });
    const { allPractices } = this.props;
    let copyOfPractices = allPractices;
    let searchResult = copyOfPractices.filter((item) => {
      if (item.practiceName.toUpperCase().indexOf(value.toUpperCase()) !== -1) {
        return item;
      }
    });
    this.setState({ allPractices: searchResult, searchKey: value });
  };

  /**
   * function to handle checkbox
   * @param {*} e
   */
  practiceCheckbox = (e) => {
    let chekedValue = Number(e);
    let { practiceId } = this.state;
    if (practiceId.indexOf(chekedValue) !== -1) {
      let arr = practiceId;
      arr.splice(arr.indexOf(chekedValue), 1);
      this.setState({ practiceId: arr });
    } else {
      let arr = practiceId;
      arr.push(chekedValue);
      this.setState({ practiceId: arr });
    }
  };

  /**
   * function to handle ok button while edit the practice checkbox and search practices
   */
  // handleOk = () => {
  //   let { practiceId } = this.state;
  //   const { allPractices } = this.props;
  //   let practiceObj = practiceId.map((item) => ({ id: item }));
  //   let selectedPractices = practiceObj.map((item) => {
  //     let obj = allPractices.find((o) => o.id === item.id);
  //     return { ...obj, ...item };
  //   });
  //   this.setState({
  //     showPracticeList: false,
  //     selectedPractice: selectedPractices,
  //   });
  // };

  /**
   * function to reset the neccessary fields while close the pop-up
   */
  resetEditForm = () => {
    this.setState({
      showPracticeList: false,
      searchKey: "",
    });
  };

  /**
   * function to Add edit user details and send body data by using
   * updateUserDetails method in groups.js componenet
   */
  updateUser = () => {
    if (this.isUserValid()) {
      const { firstName, lastName, userEmail, selectRole, practiceId, userId, confirmPassword } =
        this.state;
      let clientKey = JSON.parse(sessionStorage.getItem("userDetail"));
      let edit_body_data = {
        first_name: firstName,
        last_name: lastName,
        email: userEmail,
        password: confirmPassword,
        practiceId: practiceId,
        PrevPracticeId: this.state.prevPracticeId['practiceId'],
        roleid: selectRole,
        client_key: clientKey.client_key
      };
      this.props.updateUserDetails(userId, edit_body_data);
      this.resetEditForm();
    }
  };

  /**
   * function is used to deactivate the user
   * @param {*} userId
   */
  deactivateUser = (userId) => {
    console.log(typeof userId)
    API.put(`deactivateUser/${userId}`)
      .then((res) => {
        if (res.data.status === false) {
          NotificationManager.success(
            "user has been deactivated successfully!",
            "Successful!",
            3000
          );
        } else if (res.data.status === true) {
          NotificationManager.success(
            "user has been activated successfully!",
            "Successful!",
            3000
          );
        }
        this.closeModal()
        this.props.getUsers()
      })
      .catch((err) => {
        return err;
      });
  };

  /**
   * function to handle close button in pop-up
   */
  closeModal = () => {
    this.props.closeUserModal();
    this.resetEditForm();
  };

  /**
   * function to handle delete button in user edit pop-up
   * @param {*} userId
   */
  deleteUserBtn = (userId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are deleting the user.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#006F90",
      cancelButtonColor: "#C77B7B",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        this.props.deleteUsers(userId)
        this.props.closeUserModal();
   }})
  };

  render() {
    const {
      firstName,
      lastName,
      userEmail,
      searchKey,
      selectRole,
      practiceId,
      currentPassword,
      newPassword,
      confirmPassword,
      showPass,
      showConfirmPass,
      showNewPass,
      userCanDo,
      userCantDo,
      allPractices,
      userId,
      showPracticeList,
      selectedPractice,
      activeStatus,
    } = this.state;
    const { permissionValues } = this.props;
    return (
      <>
        {this.props.open && (
          <Dialog
            fullWidth
            open={this.props.open}
            onClose={this.closeModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <div align="center" className="userTitle">
              <DialogTitle id="alert-dialog-title">
                <span className="font-face-futura">Edit User</span>
              </DialogTitle>
            </div>
            <DialogContent>
              <Card className="userCard" style={{ height: "130px" }}>
                <CardContent className="userContent">
                  <span className="headingUserName">
                    {firstName + " " + lastName}
                  </span>
                  <div className="row">
                    <div className="col-lg-3 col-sm-3">
                      <div className="Logo_name_user">
                        <div className="logoFieldUser">
                          <p>
                            {(firstName + " " + lastName || "").match(
                              /\b(\w)/g
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5 col-sm-5">
                      <div className="col">
                        <div className="row userCred">
                          <span className="">
                            <img
                              src={practiceEmailIcon}
                              alt="practiceEmailIcon"
                              className="emailIcon"
                            />
                            <strong>Email: </strong>
                            {userEmail}
                          </span>
                        </div>
                        <div className="row">
                          <span>
                            <img
                              src={practiceCalendar}
                              alt="practiceCalendar"
                              className="calenderIcon"
                            />
                            <strong>Invited: </strong>
                            {new Date().toLocaleDateString("en-US")}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-4">
                      <div className="btnDiv">
                        <button
                          variant="contained"
                          className="deactivateBtn mx-2"
                          onClick={() => this.deactivateUser(userId)}
                        >
                          <span>{activeStatus? "DEACTIVATE" : "ACTIVATE"}</span>
                        </button>
                        <button
                          variant="contained"
                          className="deleteBtn"
                          onClick={() => this.deleteUserBtn(userId)}
                        >
                          <span>DELETE USER</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <div className="mt-2">
                <Card className="userCard" style={{ height: "200px" }}>
                  <CardHeader
                    className="userCardHead"
                    title={
                      <h5 className="text-white font-face-futura">Profile</h5>
                    }
                  />
                  <CardContent className="userContent">
                    <div className="row">
                      <div className="col-lg-6 col-sm-6">
                        <label htmlFor="firstName">
                          <strong>First Name:</strong>
                        </label>
                        <input
                          className="user-Input"
                          name="firstName"
                          value={
                            firstName.charAt(0).toUpperCase() +
                            firstName.slice(1)
                          }
                          onChange={this.handleInputChange}
                        />
                      </div>
                      <div className="col-lg-6 col-sm-6">
                        <label htmlFor="lastName">
                          <strong>Last Name:</strong>
                        </label>
                        <input
                          className="user-Input"
                          name="lastName"
                          value={
                            lastName.charAt(0).toUpperCase() + lastName.slice(1)
                          }
                          onChange={this.handleInputChange}
                        />
                      </div>
                      <div className="col-lg-12 col-sm-12">
                        <label htmlFor="userEmail">
                          <strong>Email</strong>
                        </label>
                        <input
                          className="user-Input"
                          name="userEmail"
                          value={userEmail}
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
              <div className="mt-2">
                  <Card className="userCard" style={{ height: "250px" }}>
                    <CardHeader
                      className="userCardHead"
                      title={
                        <h5 className="text-white font-face-futura">
                          Password Management
                        </h5>
                      }
                    />
                    <CardContent className="userContent">
                      <div className="row">
                        <div className="col-lg-11 col-sm-11">
                          <label htmlFor="currentPassword">
                            <strong>Current Password:</strong>
                          </label>
                          <input
                            className="user-Input"
                            type={showPass ? "text" : "password"}
                            name="currentPassword"
                            value={currentPassword}
                            onChange={this.handleInputChange}
                          />
                           <img
                            src={showPass ? showPassword : hidePassword}
                            alt="password"
                            onClick={this.showPassword}
                            className="pass-btn"
                          />
                          </div>
                        <div className="col-lg-11 col-sm-11">
                          <label htmlFor="newPassword">
                            <strong>New Password</strong>
                          </label>
                          <input
                            type={showNewPass ? "text" : "password"}
                            className="user-Input"
                            name="newPassword"
                            value={newPassword}
                            onChange={this.handleInputChange}
                          />
                           <img
                            src={showNewPass ? showPassword : hidePassword}
                            alt="password"
                            onClick={this.showNewPass}
                            className="pass-btn"
                          />
                        </div>
                        <div className="col-lg-11 col-sm-11">
                          <label htmlFor="confirmPassword">
                            <strong>Confirm Password</strong>
                          </label>
                          <input
                            type={showConfirmPass ? "text" : "password"}
                            className="user-Input"
                            name="confirmPassword"
                            value={confirmPassword}
                            onChange={this.handleInputChange}
                          />
                           <img
                            src={showConfirmPass ? showPassword : hidePassword}
                            alt="password"
                            onClick={this.showConfirmPass}
                            className="pass-btn"
                          />
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              <div className="mt-1">
                <Card className="mt-2 userCard">
                  <CardHeader
                    className="userCardHead"
                    title={
                      <h5 className="text-white font-face-futura">
                        Permissions
                      </h5>
                    }
                  />
                  <CardContent>
                    <div align="center">
                      <span className="contentUser">
                        Choose the users site permissions
                      </span>
                    </div>
                    <div align="center">
                      <span className="mt-1 contentText ">
                        Let’s get them set up!
                      </span>
                    </div>
                    <div className="mt-3">
                      <label htmlFor="group" className="userLabel">
                        Permission Level:
                      </label>
                      <div className="boxSizingDrop">
                        <select
                          fullWidth="true"
                          className="px-2 text font-face-futura"
                          value={selectRole}
                          onChange={(e) => {
                            this.handlePermissionChange(e.target.value);
                          }}
                        >
                          {/* <option value="select">Select</option> */}
                          {permissionValues &&
                            permissionValues.map((options, idx) => (
                              <option key={idx} value={options.id}>
                                {options.role_Name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="mt-2">
                      <div className="row font-face-futura">
                        <div className="col-lg-6 col-sm-6">
                          <div className="headingsText">
                            <strong>What can they do?</strong>
                          </div>
                          <ul className="mt-2 headingsText">
                            {userCanDo &&
                              userCanDo.map((canDo, idx) => (
                                <li key={idx}>{canDo}</li>
                              ))}
                          </ul>
                        </div>

                        <div className="col-lg-6 col-sm-6">
                          <div className="headingsText">
                            <strong>What can't they do?</strong>
                          </div>
                          <div className="mt-2 headingsText">
                            {userCantDo &&
                              userCantDo.map((cantDo, idx) => (
                                <li key={idx}>{cantDo}</li>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
              <Card className="mt-2 userCard">
                <CardHeader
                  className="userCardHead"
                  title={
                    <h5 className="text-white font-face-futura">
                      Practice Access
                    </h5>
                  }
                />
                <CardContent className="userCardContent">
                  <input
                    className="p-2 mb-3 text practice-search"
                    type="text"
                    placeholder="Type something to search..."
                    value={searchKey}
                    onChange={(e) => {
                      this.searchValues(e.target.value);
                    }}
                  />
                  <br />
                  <div className="practiceDrop">
                    {allPractices.length > 0 ? (
                      allPractices.map(
                        (option, idx) => (
                          <div key={idx} className="optionPracDrop">
                            <RoCheckbox
                              style={{ width: 18, height: 18 }}
                              value={option.id}
                              checked={practiceId?.includes(option.id)}
                              onChange={(e) =>
                                this.practiceCheckbox(e.target.value)
                              }
                            />
                            {/* <input
                              type="checkbox"
                              style={{ width: 18, height: 18 }}
                              checked={practiceId.includes(option.id)}
                              value={option.id}
                              onChange={(e) => this.practiceCheckbox(e.target.value)}
                            /> */}
                            <span className="practiceText">
                              {option.practiceName}
                            </span>
                            {/* {showPracticeList && (
                              <div className="editButtonDiv">
                                <Button
                                  className="okBTN"
                                  variant="contained"
                                  onClick={this.handleOk}
                                >
                                  <span className="font-face-futura">Ok</span>
                                </Button>
                              </div>
                            )} */}
                          </div>
                        )
                      )
                    ) : (
                      <span className="noRecordPrac">No Result found!</span>
                    )}
                  </div>
                </CardContent>
              </Card>
            </DialogContent>
            <DialogActions>
              <div className="actionBtn">
                <Button
                  fullWidth
                  className="mt-4 mb-2 mx-2 nevermindBtn"
                  variant="outlined"
                  onClick={this.closeModal}
                >
                  <span
                    className="text-white font-face-futura"
                    style={{ fontSize: 18 }}
                  >
                    Nevermind
                  </span>
                </Button>
                <Button
                  fullWidth
                  variant="outlined"
                  className={"mt-4 mb-2 mx-2 linkBtnAfter"}
                  onClick={() => this.updateUser()}
                >
                  <span className="text-white font-face-futura">Save</span>
                </Button>
              </div>
            </DialogActions>
          </Dialog>
        )}
      </>
    );
  }
}
export default EditUserModal;
