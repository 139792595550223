import { InsuranceCompanyTableHead } from "../constants";

export const containerStyle = {
  padding: "0px",
};

export const searchTypeOptions = InsuranceCompanyTableHead.map((opt) => {
  return {
    label: opt.name,
    value: opt.name,
  };
});
