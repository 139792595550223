export const SortedState = {
  ASC: "asc",
  DESC: "desc",
  NO_SORT: "noSort",
};

export const mapCurrentSortedStateToNextState = {
  [SortedState.ASC]: SortedState.DESC,
  [SortedState.DESC]: SortedState.NO_SORT,
  [SortedState.NO_SORT]: SortedState.ASC,
};
