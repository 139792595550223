import { FormControl } from "@material-ui/core";
import API from "Api";
import { handleErrors } from "global/helpers";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

const SESSION_PRACTICE_KEY = "practice";

export const savePracticeLocally = (practice) => {
  sessionStorage.setItem(SESSION_PRACTICE_KEY, JSON.stringify(practice));
};

const getSelectedPracticeLocally = () => {
  const practice = sessionStorage.getItem(SESSION_PRACTICE_KEY);
  if (practice && practice !== 'undefined') {
    return JSON.parse(practice);
  }
};

const PracticeSelect = ({
  handleChangePractice = () => { },
  hasAllPracticeOption,
  defaultDisabledValue,
  setSidebarDisable,
  refreshPractice,
  customSetPractice = () => { },
  handlePracticeChangeByNotReviewPage,
}) => {
  const history = useHistory();
  const location = useLocation();
  const currentRoute = location.pathname;
  const [practices, setPractices] = useState([]);
  const [selectedPracticeId, setSelectedPracticeId] = useState(
    getSelectedPracticeLocally()?.id || "all"
  );
  const [showDisableField, setShowDisableField] = useState(false);

  const handlePracticeChange = (e) => {
    const selectedPracticeId = e.target.value;
    let selectedPractice;
    if (e.practiceData) {
      selectedPractice = (e.practiceData || []).find(
        (practice) => practice.id === selectedPracticeId
      );
    } else {
      selectedPractice = (practices || []).find(
        (practice) => practice.id === Number(selectedPracticeId)
      );
    }
    if (selectedPractice && selectedPractice.id) {
      setSelectedPracticeId(selectedPractice.id);
      savePracticeLocally(selectedPractice);
      handleChangePractice(selectedPractice);
    }

    if (selectedPracticeId === "all") {
      setSelectedPracticeId("all");
      savePracticeLocally("all");
      handleChangePractice("all");
    }
  };

  const fetchPractices = async () => {
    handleErrors(async () => {
      const res = await API.get("allPractices");
      const fetchedPractices = res.data?.data || [];
      const activePractices = fetchedPractices.filter(practice => practice.status)
      setPractices(activePractices);
      selectInitialPractice(activePractices);
    });
  };

  const hasSelectedPractices = (practices) => {
    let selectedPractice = practices.filter((practice) => practice.id === getSelectedPracticeLocally().id)
    savePracticeLocally(selectedPractice[0]);
  }

  const selectInitialPractice = (practices) => {
    const hasSelectedPractice = getSelectedPracticeLocally() !== undefined;
    if (hasSelectedPractice && getSelectedPracticeLocally() !== "all") {
      hasSelectedPractices(practices)
      return
    };
    if (!practices.length) return;
    setSelectedPracticeId(practices[0].id);
    savePracticeLocally(practices[0]);
  };

  useEffect(() => {
    if (!practices.length) fetchPractices();
    history.listen((route) => {
      if (route.pathname.indexOf('edit-rule') !== -1 || route.pathname.indexOf('rule-dashboard') !== -1) setShowDisableField(true);
      else if (route.pathname.indexOf("settings") !== -1 || (setSidebarDisable && route.pathname.indexOf('dashboard')) !== -1) setShowDisableField(true);
      else setShowDisableField(false);
    });
    if (refreshPractice) refreshPractice(fetchPractices)
    if (handlePracticeChangeByNotReviewPage) handlePracticeChangeByNotReviewPage(handlePracticeChange)
  }, [history]);

  useEffect(() => {
    customSetPractice(setSelectedPracticeId)
  }, [])

  const renderPracticeOptions = () => {
    return practices.map((practice, index) => (
      <option key={practice.id} value={practice.id}>
        {practice.practiceName}
      </option>
    ));
  };



  if (showDisableField || currentRoute.includes("settings") || (setSidebarDisable && currentRoute.includes("dashboard")) || currentRoute.includes("edit-rule") || currentRoute.includes("rule-dashboard")) {
    return (
      <FormControl className="rule-select">
        <select disabled className="p-1 copyDrop1 text-center" value={defaultDisabledValue || 'all'} style={{ cursor: "no-drop" }}>
          <option value='all'>All Practices</option>
          {renderPracticeOptions()}
        </select>
      </FormControl>
    );
  }
  return (
    <FormControl className="rule-select">
      <select
        className="p-1 copyDrop1 text-center"
        value={selectedPracticeId}
        onChange={handlePracticeChange}
      >
        {hasAllPracticeOption === true && (
          <option value="all">All Practices</option>
        )}
        {renderPracticeOptions()}
      </select>
    </FormControl>
  );
};

export default PracticeSelect;


