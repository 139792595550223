import { renderToString } from "react-dom/server";
import { LoadingIndicator } from "./LoadingIndicator";

export class LoadingSpinner {
  id = "loading-indicator";

  static fire() {
    this.show();
  }

  static show() {
    const instance = new LoadingSpinner();
    instance.render();
  }

  static hide() {
    setTimeout(() => {
      const el = document.getElementById(new LoadingSpinner().id);
      if (el) el.remove();
    }, 1000);
  }

  render() {
    LoadingSpinner.hide();
    const app = document.getElementById("loadingIndicator");
    if (app) {
      app.innerHTML += renderToString(this.element());
    }
  }

  element() {
    return (
      <div id={this.id}>
        <LoadingIndicator />
      </div>
    );
  }
}
