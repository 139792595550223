import { Card, CardContent, CardHeader } from "@material-ui/core";
import './style.css'

const borderRadius = '20px'

const headerStyle = {
    backgroundColor: '#24476A',
    height: 'auto'
}

const contentStyle = {
    padding: '0px !important'
}

export const Panel = ({ heading, children, headStyle = {}, titleSize = 25, bodyStyle = {}, cardStyle = {} }) => {
    const mainCardStyle = {
        borderRadius: borderRadius,
        ...cardStyle
    }
    
    const cardBodyStyle = {
        padding: bodyStyle.padding !== undefined ? bodyStyle.padding : '10px 20px 10px 20px',
        ...bodyStyle
    };

    const cardHeaderStyle = {
        ...headerStyle,
        ...headStyle
    }
    return (
        <Card className='ro-card height-auto' style={mainCardStyle} >
            <CardHeader
                title={<div style={{ fontSize: titleSize }}
                    className='text-white mt-2 font-face-futura font-weight-bold'>{heading}</div>}
                style={cardHeaderStyle} />
            <CardContent style={cardBodyStyle}>
                {children}
            </CardContent>
        </Card>
    )
}