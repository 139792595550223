export const columnHeader = [
  {
    name: 'Patient Name',
    key: 'PatientName'
  },
  {
    name: 'Encounter',
    key: 'EncounterID'
  },
  {
    name: 'Provider',
    key: 'SchedulingProviderName'
  },
  {
    // name: 'Date of Service',
    name: 'DOS',
    key: 'ServiceStartDate'
  },
  {
    // name: 'Procedure',
    name: 'Code',
    key: 'ProcedureCode'
  },
  {
    name: 'Primary Insurance',
    key: 'PrimaryInsurancePlanName'
  },
  {
    name: 'Secondary Insurance',
    key: 'SecondaryInsurancePlanName'
  },
  {
    name: 'Facility',
    key: 'ServiceLocationName'
  },
  {
    name: 'DXC1',
    key: 'EncounterDiagnosisID1'
  }
]