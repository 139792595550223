import React from "react";
import AppHeader from "../Sidebar/Header";
import Sidebar from "components/Sidebar";
import { useEffect, useState } from "react";
import API from "Api";
import { Link } from "react-router-dom";
import './logger.css'

const Logger = () => {
  const [logFiles, setLogFiles] = useState([]);

  const getLoggerFiles = async () => {
    const { data } = await API.get("/getLoggerFiles");
    return data;
  };

  useEffect(async () => {
    let files = await getLoggerFiles();
    setLogFiles(files);
  }, []);

  return (
    <>
      <Sidebar />
      <div className="log-counainer">
        <div className="row">
          {logFiles.map((file) => (
            <div className="col-md-4 file-div">
              <Link to={`/logFileContent/${file}`}>{file}</Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Logger;
