const selectStyle = {
  position: "relative",
  width: "100%",
  height: "30px",
  background: "#FFFFFF",
  border: "1px solid #D8D8D8",
  boxShadow: "3px 3px 9px rgba(0, 0, 0, 0.05)",
  borderRadius: "9px",
  fontFamily: 'Futura PT',
  paddingLeft: '6px'
};

export const Select = (props) => {
  const {
    options = [],
    handleSelect,
    style = {},
    label = "",
    selected,
    ...rest
  } = props;

  return (
    <>
      <label className="textInputLabel futuraPT font-face-futura">{label}</label>
      <select
        onChange={handleSelect}
        style={{ ...selectStyle, ...style }}
        selected={selected}
        {...rest}
      >
        {options.map((opt, key) => (
          <option className="font-face-futura futuraPT " value={opt.value}>
            {opt.label}
          </option>
        ))}
      </select>
    </>
  );
};
