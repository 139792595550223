import React from "react";
import {
  IconButton,
  Button,
  Card,
  CardContent,
  CardHeader,
  TablePagination,
  Tooltip,
} from "@material-ui/core";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import "../settings.css";
import { CSVLink } from "react-csv";
import {
  SampleDiagnosisCodes,
  SampleModifierCodes,
  SampleProcedureCodes,
} from "../data";
import moment from "moment";
import settingEdit from "assets/icons/settingEdit.svg";
import settingDelete from "assets/icons/settingDelete.svg";
import { useState } from "react";

const GroupTable = ({
  groups,
  page,
  rowsPerPage,
  openEditGroupModal,
  groupName,
  setState,
  selectType,
  showCard,
  showDropdown,
  optionsList,
  selectedValues,
  displayData,
  groupValuesList,
  openEditGroup,
  closeEditGroup,
  deleteGroup,
  handleGroupType,
  csvRead,
  state,
  searchValues,
  handleCheckbox,
  handleOk,
  handleGroupName,
  editGroup,
  editGroupData,
  handlePageChange,
  handleRowsPerPage
}) => {
  const [roleDetail, setRoleDetail] = useState(JSON.parse(sessionStorage.getItem('userDetail')));
  return (
    <div className="mb-3 table-responsive setting-table font-face-futura">
      <table className="tables">
        <thead>
          <tr>
            <th>Group Name</th>
            <th>Created Date</th>
            {/* <th>Status</th> */}
            <th>Type</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {groups.length > 0 ? (
            groups
              .filter((group) => group.status !== false)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((group, idx) => {
                return (
                  <tr key={idx} style={{ fontSize: 15, lineHeight: 4 }}>
                    <td>{group.groupName}</td>
                    <td>{moment(group.created_at).format("MM-DD-YYYY")}</td>
                    {/* <td>{group.status === true ? 'Active' : 'Deactivated'}</td> */}
                    <td>{group.typeOfGroup}</td>
                    <td>
                      <Tooltip
                      title={(roleDetail.roleId == 1 || roleDetail.roleId == 2 || roleDetail.roleId == 3 || roleDetail.roleId == 4) ? "" : "you don't have permission"}
                      arrow
                      >
                        <IconButton>
                          <input
                            type="image"
                            disabled={(roleDetail.roleId == 1 || roleDetail.roleId == 2 || roleDetail.roleId == 3 || roleDetail.roleId == 4) ? false : true }
                            src={settingEdit}
                            alt=''
                            onClick={() => openEditGroup(group)}
                          />
                          {/* <EditIcon onClick={() => openEditGroup(group)} /> */}
                        </IconButton>
                      </Tooltip> 
                      <Tooltip
                      title={roleDetail.roleId == 1 ? "" : "you don't have permission"}
                      arrow
                      >
                      <IconButton>
                        <input
                          type="image"
                          disabled={roleDetail.roleId == 1 ? false : true }
                          className="mx-1"
                          alt=''
                          src={settingDelete}
                          onClick={(groupId) => deleteGroup(group.id)}
                        />
                        {/* <DeleteIcon onClick={(groupId) => deleteGroup(group.id)} /> */}
                      </IconButton>
                      </Tooltip>  
                    </td>
                  </tr>
                );
              })
          ) : (
            <tr>
              <td colSpan={4} className="noRecord text-center">
                No records found!
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {openEditGroupModal && (
        <Dialog
          fullWidth
          open={openEditGroupModal}
          onClose={closeEditGroup}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="addGroupModal"
        >
          <div align="center" className="addGroupTitle">
            <DialogTitle id="alert-dialog-title">
              <span className="font-face-futura">{"Edit Group"}</span>
            </DialogTitle>
          </div>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <span className="font-face-futura addGroupDesc">
                This associates multiple diagnosis, procedure and modifier codes
                in one group.
              </span>
            </DialogContentText>
            <div className="mt-2">
              <Card className="addGroupCard">
                <CardHeader
                  className="addGroupCardHead"
                  title={
                    <h5 className="text-white font-face-futura">Profile</h5>
                  }
                />
                <CardContent className="addGroupContent">
                  <div>
                    <label htmlFor="group" className="cardLabel">
                      Group Name:
                    </label>
                    <br />
                    <input
                      type="text"
                      className="p-2 text"
                      value={groupName}
                      onChange={handleGroupName}
                    />
                  </div>
                  <div className="mt-2">
                    <label htmlFor="group" className="cardLabel">
                      Type of Group:
                    </label>
                    <select
                      fullWidth
                      className="px-2 text"
                      value={selectType}
                      onChange={handleGroupType}
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      <option value="Diagnosis Codes">Diagnosis Codes</option>
                      <option value="Modifier Codes">Modifier Codes</option>
                      <option value="Procedure Codes">Procedure Codes</option>
                    </select>
                  </div>
                </CardContent>
              </Card>
              {showCard && (
                <>
                  <div className="mt-2 csvDiv font-face-futura">
                    <CSVLink
                      style={{
                        textDecoration: "none",
                        color: "#5CB02C",
                      }}
                      filename={
                        selectType === "Diagnosis Codes"
                          ? "DiagnosisCodes.csv"
                          : selectType === "Modifier Codes"
                          ? "ModifierCodes.csv"
                          : selectType === "Procedure Codes"
                          ? "ProcedureCodes.csv"
                          : ""
                      }
                      data={
                        selectType === "Diagnosis Codes"
                          ? SampleDiagnosisCodes
                          : selectType === "Modifier Codes"
                          ? SampleModifierCodes
                          : selectType === "Procedure Codes"
                          ? SampleProcedureCodes
                          : []
                      }
                    >
                      <span className="mx-3">Download Sample File</span>
                    </CSVLink>
                    <label style={{ cursor: "pointer" }}>
                      <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={csvRead}
                        accept=".csv"
                      />
                      <span>Add Via CSV File</span>
                    </label>
                  </div>
                  <Card className="mt-2 addGroupCard">
                    <CardHeader
                      className="addGroupCardHead"
                      title={
                        <h5 className="text-white font-face-futura">
                          {selectType}
                        </h5>
                      }
                    />
                    <CardContent className="addGroupContent">
                      <input
                        className="p-2 mb-3 text modifier-search"
                        type="text"
                        value={state.searchKey}
                        placeholder="Type something to search..."
                        onChange={(e) => {
                          searchValues(selectType, e.target.value);
                        }}
                      />
                      <br />
                      {showDropdown && (
                        <div className="drop">
                          {optionsList &&
                            optionsList.map((option, idx) => (
                              <div key={idx} className="optionDrop">
                                {/* <input className={idx === 0 ? 'mt-2' : 'm-0'} */}
                                <input
                                  type="checkbox"
                                  style={{ width: 18, height: 18 }}
                                  checked={selectedValues.includes(option)}
                                  value={option}
                                  onChange={handleCheckbox}
                                />
                                <span className="spantext">{option}</span>
                              </div>
                            ))}
                          <div className="buttonDiv">
                            <Button
                              className="button"
                              variant="contained"
                              onClick={handleOk}
                            >
                              <span className="font-face-futura">Ok</span>
                            </Button>
                          </div>
                        </div>
                      )}
                      {displayData && groupValuesList && (
                        <div className="groupDrop">{groupValuesList}</div>
                      )}
                    </CardContent>
                  </Card>
                </>
              )}
              <footer>
                <div className="actionButton">
                  <Button
                    className="mt-4 mx-2 nevermind"
                    variant="outlined"
                    onClick={closeEditGroup}
                  >
                    <span
                      className="text-white font-face-futura"
                      style={{ fontSize: 18 }}
                    >
                      NeverMind
                    </span>
                  </Button>
                  <Button
                    className="mt-4 mx-2 looksgood"
                    variant="outlined"
                    onClick={(groupId) => editGroup(editGroupData.id)}
                  >
                    <span
                      className="text-white font-face-futura"
                      style={{ fontSize: 18 }}
                    >
                      Add
                    </span>
                  </Button>
                </div>
              </footer>
            </div>
          </DialogContent>
        </Dialog>
      )}
      <TablePagination
        rowsPerPageOptions={[5, 10, 20]}
        component="div"
        count={groups.filter((group) => group.status !== false).length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{ "aria-label": "Previous Page" }}
        nextIconButtonProps={{ "aria-label": "Next Page" }}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPage}
      />
    </div>
  );
};

export default GroupTable;
