import { Button } from "@material-ui/core";
import "./style.css";

export const ROButton = (props) => {
  const {
    onClick,
    children,
    variant = "contained",
    className = "",
    fontSize = 14,
    type = "primary",
    disabled = false,
    style = {},
    id,
    showLoader = false,
    ...rest
  } = props;

  const generateButtonClassName = () => {
    switch (type) {
      case "primary":
        return "ro-btn-primary";
      case "success":
        return "ro-btn-success";
      case "info-light":
        return "ro-btn-info-light";
      case "info":
        return "ro-btn-info";
      case "danger":
        return "ro-btn-danger";
      default:
        return "ro-btn-primary";
    }
  };

  return (
    <Button
      variant={variant}
      onClick={onClick}
      disabled={disabled}
      style={{ ...{ cursor: disabled ? "no-drop" : "pointer" }, ...style }}
      className={"ro-btn " + generateButtonClassName() + " " + className}
      id={id}
      {...rest}
    >
      <span
        className={"font-face-futura text-white "}
        style={{ fontSize: fontSize }}
      >
        {children}
      </span>
    </Button>
  );
};
