import React, { useEffect } from "react";
import "./autoRunRule.css";
import { useState } from "react";

const CustomSelect = ({
  listItem,
  schedulingProviderChange,
  name,
  getReportsFilterOnSwiper,
  getSwiperReport,
  dropdownCloseOnChange,
  selectedDateRange,
  selectedData,
  ...rest
}) => {
  const [showList, setShowList] = useState(false);
  const [dropdownItem, setDropdownItem] = useState(null);
  const [schedulingProvider, setSchedulingProvider] = useState([]);
  let practiceData = sessionStorage.getItem('practice')

  const showDropdownList = () => {
    setShowList((prev) => !prev);
  };

  useEffect(() => {
    setDropdownItem(() =>
      listItem.map((item) => {
        if (selectedData.includes(item[name])){
          item.isActive = true;
        }
        else item.isActive = false;
        return item;
      })
    );
    setShowList(dropdownCloseOnChange)
  }, [listItem]);

  useEffect(()=>{
    if(selectedDateRange){
      setSchedulingProvider([])
      schedulingProviderChange([], name);
    }
    if(practiceData){
      setSchedulingProvider([])
    }
  },[selectedDateRange, practiceData])

  const handleClick = (e, item) => {
    let clickedItems = item[name];
    let dataModified = dropdownItem.map((element) => {
      if (element[name] === item[name]) {
        element.isActive === false
          ? (element.isActive = true)
          : (element.isActive = false);
      }
      return element;
    });

    setDropdownItem(dataModified);
    if (schedulingProvider.indexOf(clickedItems) !== -1) {
      let arr = schedulingProvider;
      arr.splice(arr.indexOf(clickedItems), 1);
      setSchedulingProvider(arr);
    } else {
      let arr = schedulingProvider;
      arr.push(clickedItems);
      setSchedulingProvider(arr);
    }
  };

  const handleCloseBtn = () => {
    schedulingProviderChange(schedulingProvider, name);
    getReportsFilterOnSwiper();
    getSwiperReport();
    setShowList(false);
  };
  
  return (
    <div className="dropGroup">
      <button className="reportsDropdown" onClick={showDropdownList}>
        {schedulingProvider.length > 1 ? "multiple items" : schedulingProvider.length === 1 ? schedulingProvider : "Select" } 
      </button>
      <div className={name === "SchedulingProviderName" ? "customProvider" : 
      name === "ServiceLocationName"? "customLocation" : 
      name === "PrimaryInsurancePlanName" ? "customInsurance" : "customProvider"}>
        <ul className="customList">
          {showList === true &&
            (dropdownItem && dropdownItem.length === 0 ? (
              <li style={{width:'247px'}}>No Options</li>
            ) : (
              dropdownItem.map((item, i) => (
                <li
                  id={`list_${i}_${name}`}
                  onClick={(e) => handleClick(e, item)}
                  key={item[name]}
                  className={item.isActive === true ? "activeGreen" : "font-face-futura"}
                >
                  {item[name]}
                </li>
              ))
            ))}
        </ul>
        {showList === true && dropdownItem.length > 0 && (
          <div className="btncover">
          <button 
          onClick={handleCloseBtn}>CLOSE</button>
          </div>
        )}
      </div>
    </div>
  );
};
export default CustomSelect;
