import React from 'react';
import './App.css';
import Dashboard from './components/Dashboard';
import RuleProcessor from './components/RuleProcessor';
import RunRules from './components/RuleProcessor/runRules';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import InspectionNotRan from './components/Inspection-NotRan';
import InspectionNoViolation from './components/Inspection-NoViolation';
import CreateRule from './components/CreateRule/rule-builder';
import Insurances from './components/Insurances';
import Settings from './components/Settings/group';
import Login from './Auth/Login/login';
import PracticeSettings from './components/Settings/PracticeSettings/practice-settings';
import ManageRules from './components/ManageRule';
import RuleDashboard from './components/ManageRule/rule-dashboard';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';
import PropTypes from 'prop-types';
import Reports from './components/AutoRunRule/Reports';
import Logger from './components/Logger';
import LoggerDetails from './components/Logger/LoggerDetails';
import ForgotPassword from 'Auth/Login/ForgotPassword';
import InviteUser from 'Auth/Login/inviteUser';

// Initial path to check whether user is logged in or not
const PrivateRoute = ({component: Component, ...rest}) => {
  return (
      // Show the component only when the user is logged in
      // Otherwise, redirect the user to /signin page
      <Route {...rest} render={props => (
        sessionStorage.getItem('token') ?
              <Component {...props} />
          : <Redirect to="/" />
      )} />
  );
};
PrivateRoute.propTypes = {
  component: PropTypes.element.isRequired,
}

const PublicRoute = ({component: Component, restricted, ...rest}) => {
  return (
    <Route {...rest} render={props => (
      sessionStorage.getItem('token') && restricted ?
          <Redirect to="/dashboard" />
        : <Component {...props} />
    )} />
);
};
PublicRoute.propTypes = {
  component: PropTypes.element.isRequired,
  restricted: PropTypes.bool.isRequired
}

function App() {
  return (
   <>
    <div className="App">
      <Router>
        <Switch>
          <PublicRoute restricted={true} path="/" exact component={Login} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/register" component={InviteUser} />
          <PrivateRoute path="/dashboard" component={Dashboard} exact />
          <PrivateRoute path="/rule-processor" component={RuleProcessor} exact />
          <PrivateRoute path="/runRules" component={RunRules} exact />
          <PrivateRoute path="/manageRule/:ruleType?" component={ManageRules} exact />
          <PrivateRoute path="/rule-dashboard" component={RuleDashboard}/>
          <PrivateRoute path="/inspection-notran" component={InspectionNotRan} />
          <PrivateRoute path="/inspection-noviolation" component={InspectionNoViolation} />
          <PrivateRoute key='create-rule' exact path="/create-rule" component={CreateRule} />
          <PrivateRoute key='edit-rule' exact path="/edit-rule/:ruleId" component={CreateRule} />
          <PrivateRoute path="/insurances/:encounterId?/:payerId?/:encounterType?" component={Insurances} exact/>
          <PrivateRoute path="/settings" component={Settings} />
          <PrivateRoute path="/practice-settings" component={PracticeSettings} />
          <PrivateRoute path="/logs" component={Logger} />
          <PrivateRoute path="/logFileContent/:loggerName" component={LoggerDetails} />
          <PrivateRoute path="/Reports" component={Reports} exact/>
          <PrivateRoute path="/*" component={notfound_page} exact/>
        </Switch>
          <NotificationContainer />
      </Router>
    </div>
   </>
  );
}

function notfound_page(){
  return <>
    <h1>Page not Found</h1>
  </>
}

export default App;
