import React from 'react'
import {
  IconButton,
  TablePagination,
} from "@material-ui/core";
import "../settings.css";
import settingEdit from "assets/icons/settingEdit.svg";

const PracticeTable = ({ practiceList, page, rowsPerPage, handlePageChange, handleRowsPerPage, editPractice }) => {
  return (
    <div className="mb-3 table-responsive setting-table font-face-futura">
    <table className="tables">
      <thead>
        <tr>
          <th>Practice Name</th>
          <th>Created Date</th>
          <th>Email</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {practiceList?.length > 0 ? (
          practiceList
            .slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            )
            .map((practice, idx) => {
              let date = new Date(practice.created_at);
              // const createdAt = date.toLocaleDateString();
              const createdAt =
                date.getMonth() +
                1 +
                "-" +
                date.getDate() +
                "-" +
                date.getFullYear();
              return (
                <tr key={idx} style={{ fontSize: 15, lineHeight: 4 }}>
                  <td>{practice?.practiceName}</td>
                  <td>{createdAt}</td>
                  <td>{practice?.email}</td>
                  <td>
                    {practice?.status === true
                      ? "Active"
                      : "Deactivated"}
                  </td>
                  <td>
                    <IconButton>
                      <img
                        src={settingEdit}
                        alt=''
                        onClick={(id) => editPractice(practice?.id)
                        }
                      />
                    </IconButton>
                  </td>
                </tr>
              );
            })
        ) : (
          <tr>
            <td colSpan={6} className="noRecord text-center">
              No records found!
            </td>
          </tr>
        )}
      </tbody>
    </table>
    <TablePagination
      rowsPerPageOptions={[5, 10, 20]}
      component="div"
      count={practiceList?.length}
      rowsPerPage={rowsPerPage}
      page={page}
      backIconButtonProps={{ "aria-label": "Previous Page" }}
      nextIconButtonProps={{ "aria-label": "Next Page" }}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleRowsPerPage}
    />
  </div>
  )
}

export default PracticeTable