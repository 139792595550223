import React, { Component } from "react";
import { Pie } from "react-chartjs-2";

class CleanChart extends Component {
  constructor(props) {
    super(props);
    this.state = { };
  }
  render() {
    const {data} = this.props
    return (
      <div>
        <Pie
          data={data}
          options={{
            maintainAspectRatio: true,
            responsive: true,
            plugins: {
              title: {
                display: true,
              },
              legend: {
                position: "top",
                display: false,
              },
            },
          }}
        />
      </div>
    );
  }
}

export default CleanChart;
