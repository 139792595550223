import {
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
} from "@material-ui/core";
import React, { Component } from "react";
import "./indexUser.css";
import API from "../../../Api";
import {
  systemAdminDo,
  systemAdminDont,
  ruleCretorDo,
  ruleCretorDont,
  ruleLimitedDo,
  ruleLimitedDont,
  ruleEditDo,
  ruleEditDont,
  claimScrubberDo,
  claimScrubberDont,
} from "./UserData";
import { NotificationManager } from "react-notifications";
import showPassword from '../../../assets/icons/showPassword.svg';
import hidePassword from '../../../assets/icons/hidePassword.svg';
import { RoCheckbox } from "layouts/Form/Checkbox";

class AddUserModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      userEmail: "",
      searchKey: "",
      roleSelect: "select",
      password: "",
      confirmPassword: "",
      showPass: false,
      showConfirmPass: false,
      practicesList: [],
      practiceSearch: [],
      userCanDo: [],
      userCantDo: [],
      practiceSelected: [],
    };
  }

  /**
   * function to handle input change
   * @param {*} event 
   */
  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  showPassword = () => {
    this.setState({ showPass: !this.state.showPass });
  };
  showConfirmPass = () => {
    this.setState({ showConfirmPass: !this.state.showConfirmPass });
  };

  /**
   * function to handle Drop down change in permission level
   * @param {*} e
   */
  handlePermissionChange = (e) => {
    let value = e.target.value;
    switch (value) {
      case "select":
        this.setState({
          userCanDo: [],
          userCantDo: [],
        });
        break;
      case "1":
        this.setState({
          userCanDo: systemAdminDo,
          userCantDo: systemAdminDont,
        });
        break;
      case "2":
        this.setState({
          userCanDo: ruleCretorDo,
          userCantDo: ruleCretorDont,
        });
        break;
      case "3":
        this.setState({
          userCanDo: ruleLimitedDo,
          userCantDo: ruleLimitedDont,
        });
        break;
      case "4":
        this.setState({
          userCanDo: ruleEditDo,
          userCantDo: ruleEditDont,
        });
        break;
      case "5":
        this.setState({
          userCanDo: claimScrubberDo,
          userCantDo: claimScrubberDont,
        });
        break;
      default:
        break;
    }
    this.setState({ roleSelect: e.target.value });
  };

  /**
   * function to get all practices 
   */
  getAllPractices = () => {
    API.post("allPractices")
      .then((res) => {
        this.setState({
          practicesList: res.data.data.filter(practice => practice.status),
          practiceSearch: res.data.data.filter(practice => practice.status),
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /**
   * searching fucntionality for practice access
   * @param {*} value
   */
  searchPratices = (value) => {
    this.setState({ searchKey: value});
    let searchResult = this.state.practiceSearch.filter((items) => {
      if (
        items.practiceName.toUpperCase().indexOf(value.toUpperCase()) !== -1
      ) {
        return items;
      }
    });
    this.setState({ practicesList: searchResult });
  };

  /**
   * used to get selected practice id in array
   * @param {*} e
   */
  practiceCheckbox = (e) => {
    let value = Number(e.target.value)
    let newArr = [...this.state.practiceSelected, value];
    if (this.state.practiceSelected.includes(value)) {
      newArr = newArr.filter((id) => id !== value);
    }
    this.setState({ practiceSelected: newArr });
  };

  /**
   * method is used for add user form validations
   */
  isUserValid = () => {
    const {
      firstName,
      lastName,
      userEmail,
      roleSelect,
      practiceSelected,
      password,
      confirmPassword,
    } = this.state;
    
    let validatePassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/;
    let isValid = true;

    if (!firstName) {
      NotificationManager.error("Please enter a First Name", "Failure!", 3000);
      isValid = false;
    } else if (!lastName) {
      NotificationManager.error("Please enter a Last Name", "Failure!", 3000);
      isValid = false;
    } else if (
      !userEmail ||
      !/^[+\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(userEmail)
    ) {
      NotificationManager.error(
        "Please enter a valid user email",
        "Failure!",
        3000
      );
      isValid = false;
    } else if (!password) {
      NotificationManager.error("Please enter a password!", "Failure", 3000);
      isValid = false;
    } else if (password.length < 8 || password.length > 20) {
      NotificationManager.error(
        "Password must be in between 8 to 20 characters",
        "Failure",
        3000
      );
      isValid = false;
    } else if (!password.match(validatePassword)) {
      NotificationManager.error(
        "Password must contain atleast one uppercase, one lowercase, one number and a special character",
        "Failure",
        3000
      );
      isValid = false;
    } else if (!confirmPassword) {
      NotificationManager.error("Confirm Password must be filled", "Failure", 3000);
      isValid = false;
    } else if (password !== confirmPassword) {
      NotificationManager.error("Password does not matched", "Failure", 3000);
      isValid = false;
    } else if (roleSelect === "select") {
      NotificationManager.error(
        "Please select any of the permission level",
        "Failure!",
        3000
      );
      isValid = false;
    } else if (practiceSelected.length === 0) {
      NotificationManager.error(
        "Choose any of the practices",
        "Failure!",
        3000
      );
      isValid = false;
    }
    return isValid;
  };

  /**
   * rest the user add form while close and add the user
   */
  resetUserForm = () => {
    this.setState({
      firstName: "",
      lastName: "",
      userEmail: "",
      roleSelect: "select",
      practiceSelected: [],
      userCanDo: [],
      userCantDo: [],
      password: '',
      confirmPassword: ''
    });
  };

  /**
   * this method is used to submit the user form and send body_data to the
   * group @Component
   */
  addUser = () => {
    let token = JSON.parse(sessionStorage.getItem("token")).token
    let clientKey = JSON.parse(sessionStorage.getItem("userDetail"));
    if (this.isUserValid()) {
      const {
        firstName,
        lastName,
        userEmail,
        roleSelect,
        practiceSelected,
        password,
        confirmPassword,
      } = this.state;
      let body_data = {
        first_name: firstName,
        last_name: lastName,
        email: userEmail,
        roleid: roleSelect,
        practiceId: practiceSelected,
        password: password,
        confirmPassword: confirmPassword,
        client_key: clientKey.client_key
      };
      this.props.addUserDetails(body_data, token);
      this.resetUserForm();
    }
  };

  /**
   * function to handle close button in pop-up
   */
  closeModal = () => {
    this.props.closeUserModal();
    this.resetUserForm();
  };

  /**
   * Lifecycle method to get all the data while componet render
   */
  componentDidMount() {
    this.getAllPractices();
  }

  render() {
    const {
      firstName,
      lastName,
      userEmail,
      password,
      confirmPassword,
      showPass,
      showConfirmPass,
      searchKey,
      roleSelect,
      practicesList,
      userCanDo,
      userCantDo,
      practiceSelected
    } = this.state;
    const { permissionValues } = this.props;
    return (
      <>
        {this.props.open && (
          <Dialog
            fullwidth="true"
            open={this.props.open}
            onClose={this.closeModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <div align="center" className="userTitle">
              <DialogTitle id="alert-dialog-title">
                <span className="font-face-futura">Add User</span>
              </DialogTitle>
            </div>
            <DialogContent>
              <div className="mt-2">
                <Card className="userCard" style={{ height: "200px" }}>
                  <CardHeader
                    className="userCardHead"
                    title={
                      <h5 className="text-white font-face-futura">Profile</h5>
                    }
                  />
                  <CardContent className="userContent">
                    <div className="row">
                      <div className="col-lg-6 col-sm-6">
                        <label htmlFor="firstName">
                          <strong>First Name:</strong>
                        </label>
                        <input
                          className="user-Input"
                          name="firstName"
                          value={firstName.charAt(0).toUpperCase() + firstName.slice(1)}
                          onChange={this.handleInputChange}
                        />
                      </div>
                      <div className="col-lg-6 col-sm-6">
                        <label htmlFor="lastName">
                          <strong>Last Name:</strong>
                        </label>
                        <input
                          className="user-Input"
                          name="lastName"
                          value={lastName.charAt(0).toUpperCase() + lastName.slice(1)}
                          onChange={this.handleInputChange}
                        />
                      </div>
                      <div className="col-lg-12 col-sm-12 mt-2">
                        <label htmlFor="userEmail">
                          <strong>Email:</strong>
                        </label>
                        <input
                          className="user-Input"
                          name="userEmail"
                          value={userEmail}
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
              <div className="mt-2">
                <Card className="userCard" style={{ height: "200px" }}>
                  <CardHeader
                    className="userCardHead"
                    title={
                      <h5 className="text-white font-face-futura">
                        Password Management
                      </h5>
                    }
                  />
                  <CardContent className="userContent">
                    <div className="row">
                      <div className="col-lg-11 col-sm-11">
                        <label htmlFor="password">
                          <strong>Temporary Password:</strong>
                        </label>
                        <input
                          type={showPass ? "text" : "password"}
                          className="user-Input"
                          name="password"
                          value={password}
                          onChange={this.handleInputChange}
                        />
                        <img
                          src={showPass ? showPassword : hidePassword}
                          alt="password"
                          onClick={this.showPassword}
                          className="pass-btn"
                        />
                      </div>
                      <div className="col-lg-11 col-sm-11 mt-2">
                        <label htmlFor="confirmPassword">
                          <strong>Confirm Password:</strong>
                        </label>
                        <input
                          type={showConfirmPass ? "text" : "password"}
                          className="user-Input"
                          name="confirmPassword"
                          value={confirmPassword}
                          onChange={this.handleInputChange}
                        />
                        <img
                          src={showConfirmPass ? showPassword : hidePassword}
                          alt="password"
                          onClick={this.showConfirmPass}
                          className="pass-btn"
                        />
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
              <div className="mt-1">
                <Card className="mt-2 userCard">
                  <CardHeader
                    className="userCardHead"
                    title={
                      <h5 className="text-white font-face-futura">
                        Permissions
                      </h5>
                    }
                  />
                  <CardContent>
                    <div align="center">
                      <span className="contentUser">
                        Choose the users site permissions
                      </span>
                    </div>
                    <div align="center">
                      <span className="mt-1 contentText ">
                        Let’s get them set up!
                      </span>
                    </div>
                    <div className="mt-3">
                      <label htmlFor="group" className="userLabel">
                        Permission Level:
                      </label>
                      <div className="boxSizingDrop">
                        <select
                          fullwidth="true"
                          className="px-2 text font-face-futura"
                          value={roleSelect}
                          onChange={this.handlePermissionChange}
                        >
                          <option value="select">Select</option>
                          {permissionValues &&
                            permissionValues.map((options, idx) => (
                              <option key={idx} value={options.id}>
                                {options.role_Name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="mt-2">
                      <div className="row font-face-futura">
                        <div className="col-lg-6 col-sm-6">
                          <div className="headingsText">
                            <strong>What can they do?</strong>
                          </div>
                          <ul className="mt-2 headingsText">
                            {userCanDo &&
                              userCanDo.map((canDo, idx) => (
                                <li key={idx}>{canDo}</li>
                              ))}
                          </ul>
                        </div>

                        <div className="col-lg-6 col-sm-6">
                          <div className="headingsText">
                            <strong>What can't they do?</strong>
                          </div>
                          <div className="mt-2 headingsText">
                            {userCantDo &&
                              userCantDo.map((cantDo, idx) => (
                                <li key={idx}>{cantDo}</li>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
              <Card className="mt-2 userCard">
                <CardHeader
                  className="userCardHead"
                  title={
                    <h5 className="text-white font-face-futura">
                      Practice Access
                    </h5>
                  }
                />
                <CardContent className="userCardContent">
                  <input
                    className="p-2 text mb-3 practice-search"
                    type="text"
                    placeholder="Type something to search..."
                    value={searchKey}
                    onChange={(e) => {
                      this.searchPratices(e.target.value);
                    }}
                  />
                  <br />
                    <div className="practiceDrop">
                      {practicesList.length > 0 ? (
                        practicesList.map((option, idx) => (
                          <div key={idx} className="optionPracDrop">
                            <RoCheckbox
                            style={{ width: 18, height: 18 }}
                            value={option.id}
                            checked={practiceSelected.includes(option.id)}
                            onChange={this.practiceCheckbox}
                            />
                            {/* <input
                              type="checkbox"
                              style={{ width: 18, height: 18 }}
                              // checked={selectedValue.includes(option)}
                              value={option.id}
                              onChange={this.practiceCheckbox}
                            /> */}
                            <span className="practiceText">
                              {option.practiceName}
                            </span>
                          </div>
                        ))
                      ) : (
                        <span className="noRecordPrac">No Result found!</span>
                      )}
                    </div>
                </CardContent>
              </Card>
            </DialogContent>
            <DialogActions>
              <div className="actionBtn">
                <Button
                  fullWidth
                  className="mt-4 mb-2 mx-2 nevermindBtn"
                  variant="outlined"
                  onClick={this.closeModal}
                >
                  <span
                    className="text-white font-face-futura"
                    style={{ fontSize: 18 }}
                  >
                    Nevermind
                  </span>
                </Button>
                <Button
                  fullWidth
                  variant="outlined"
                  className={"mt-4 mb-2 mx-2 linkBtnAfter"}
                  onClick={() => this.addUser()}
                >
                  <span className="text-white font-face-futura">
                   Add User! 
                  </span>
                </Button>
              </div>
            </DialogActions>
          </Dialog>
        )}
      </>
    );
  }
}

export default AddUserModal;
