import "./style.css";

export const drawDatatableCell = (
  /**
   * Value will contain a primitive data type value to sort and search the column value
   */
  value,
  /**
   * Content will contain the jsx will be display on the table cell
   */
  content,
  /**
   * Custom cell className
   */
  className,
  /**
   * Attach active cell styling
   */
  isActive = false,
  /**
   * Custom active class
   */
  activeClassName
) => {
  return {
    val: value,
    content: (
      <div
        className={`${className ? className : "tdata"} ${
          isActive ? (activeClassName ? activeClassName : "activeTdata") : ""
        }`}
        style={{}}
      >
        {content}
      </div>
    ),
  };
};
