import "./style.css";

export const SearchInput = (props) => {
  const {
    placeholder = "",
    value = "",
    style = {},
    onChange = () => {},
    className = "",
    ...rest
  } = props;
  return (
    <input
      type="text"
      style={style}
      className={"searchInputField futuraPT font-face-futura-placeholder" + ' ' + className}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      {...rest}
    />
  );
};
