import React from "react";
import clsx from "clsx";
import { AppBar, Badge, FormControl } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import NotificationsIcon from "@material-ui/icons/Notifications";
import "./sidebar.css";
import logo from "../../assets/icons/logo.svg";
import PracticeSelect from "./PracticeSelect";

const AppHeader = ({
  classes,
  path,
  handleChange,
  practice,
  practiceList,
  open,
  handleChangePractice,
  handlePracticeChangeByNotReviewPage,
  hasAllPracticeOption,
  defaultDisabledValue,
  setSidebarDisable,
  customSetPractice,
  refreshPractice,
  ...props
}) => {

  const [userRoleData , setUserRoleData] = React.useState({})
  React.useEffect(() => {
    const sessionValue = JSON.parse(sessionStorage?.getItem('userDetail'))
    setUserRoleData(sessionValue)
  }, [])

  return (
    <div>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <header
          className={classes.logo}
          style={{ backgroundColor: "white", height: 100 }}
        >
          <div className="ml-30">
            <img
              src={logo}
              alt="logo"
              width={160}
              style={{ height: 100, float: "left", marginLeft: '65px' }}
            />
          </div>
          <div className="row align-items-center justify-content-end">
            {/* <div className="col-lg-6 col-xxl-3 col-sm-3"> */}
            <div className="col-lg-auto">
              {/* {path === "/rule-processor" && (
                <div className="ml-30">
                  <img
                    src={logo}
                    alt="logo"
                    width={160}
                    style={{ height: 100, float: "left" }}
                  />
                </div>
              )}
              {path === "/runRules" && (
                <div className="ml-30">
                  <img
                    src={logo}
                    alt="logo"
                    width={160}
                    style={{ height: 100, float: "left" }}
                  />
                </div>
              )}
              {(path === "/create-rule" ||
                path === "/manageRule" ||
                path === "/rule-dashboard" ||
                path) && (
                <div className="ml-30">
                  <img
                    src={logo}
                    alt="logo"
                    width={160}
                    style={{ height: 100, float: "left" }}
                  />
                </div>
              )}
              {(path === "/dashboard" ||
                path === `/insurances/${false}` ||
                path === `/insurances/${true}`) && (
                <div className="ml-30">
                  <img
                    src={logo}
                    alt="logo"
                    width={170}
                    style={{ height: 100, float: "left" }}
                  />
                </div>
              )}
              {(path === "/inspection-notran" ||
                path === "/inspection-noviolation") && (
                <div className="ml-30">
                  <img
                    src={logo}
                    alt="logo"
                    width={170}
                    style={{ height: 100, float: "left" }}
                  />
                </div>
              )}
              {(path === "/settings" || path === '/practice-settings') && (
                <div className="ml-30">
                  <img
                    src={logo}
                    alt="logo"
                    width={170}
                    style={{ height: 100, float: "left" }}
                  />
                </div>
              )} */}
            </div>

            {/* <div className="col-lg-6 col-xxl-9 col-sm-9"> */}
            <div className="col-lg-auto">
              <div className="header-flex" style={{marginTop: '-80px'}}>
                <div className="search_content">
                  <PracticeSelect
                    refreshPractice={refreshPractice}
                    handleChangePractice={handleChangePractice}
                    handlePracticeChangeByNotReviewPage={handlePracticeChangeByNotReviewPage}
                    defaultDisabledValue={defaultDisabledValue}
                    setSidebarDisable={setSidebarDisable}
                    hasAllPracticeOption={hasAllPracticeOption}
                    customSetPractice={customSetPractice}
                  />
                </div>

                {/* <div className="notify">
                  <IconButton>
                    <Badge
                      color="secondary"
                      overlap="circular"
                      variant="dot"
                      badgeContent=""
                    >
                      <NotificationsIcon
                        style={{ color: "#10496a", fontSize: "30px" }}
                      />
                    </Badge>
                  </IconButton>
                </div> */}

                <div className="ac_admin">
                  <div className="c-flex pe-3">
                    <div
                      className=""
                      style={{
                        height: 50,
                        width: 50,
                        border: "2px solid #10496a",
                        borderStyle: "dashed",
                        borderRadius: "50%",
                        backgroundColor: "#F0F0F0",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      <span
                        style={{
                          color: "#24476A",
                          fontSize: 24,
                          fontWeight: 'bold',
                          position: "absolute",
                          margin: "7px -14px",
                          fontFamily: "Futura PT"
                        }}
                      >
                        {(userRoleData?.Name)?.match(/\b(\w)/g)}
                      </span>
                    </div>
                    {/* <div className="pt-3" style={{ float: "left" }}> */}
                    <div className="pt-3 ms-3">
                      <p
                        className="h6"
                        style={{
                          fontSize: "17px",
                          textAlign: "left",
                          color: "#535353",
                          fontFamily: "Futura PT",
                        }}
                      >
                        {userRoleData?.Name}
                        <br />
                        <small
                          style={{
                            fontSize: "12px",
                            color: "#9B9B9B",
                            fontFamily: "Futura PT",
                          }}
                        >
                          {userRoleData?.role_Name}
                        </small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </AppBar>
    </div>
  );
};

export default AppHeader;
