import API from '../Api'

export class userActivity {
    constructor(activityType, token) {
      this.activityType = activityType;
      this.token = token;
    }

    async setActivityAPI({encounterID = ''}){
        const activityResponse = await API.post('setActivityAPI', {event: this.activityType, action: 'User', user_id: this.token, encounterID});
        return activityResponse.data;
    }

    async getActivity(){
        const activityResponse = await API.get('getActivity');
        return activityResponse;
    }
}