import React, { useState, useRef } from "react";
import { Bar, getElementAtEvent } from "react-chartjs-2";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import { useHistory, useLocation } from "react-router-dom";

import "./style.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    title: {
      display: true,
    },
    legend: {
      position: "top",
      display: false,
    },
  },
  scales: {
    x: {
      min: 0,
      max: 9,
    },
  },
};

const BarChart = (props) => {
  const chartRef = useRef();
  const data = props.data;
  const practiceData = props.practiceData;
  const [opt, setOpt] = useState(options);
  const [value, setValue] = useState(0);
  const history = useHistory();

  const noOfColumnToDisplay = 10;
  const noOfColumnToScroll = 1;
  const labelsLength = data.labels.length;
  const noOfMaxForSlider = Math.ceil(
    (labelsLength - noOfColumnToDisplay) / noOfColumnToScroll
  );
  const clickScroll = (event, newValue) => {
    const newOpt = JSON.parse(JSON.stringify(opt));
    let minVal = opt.scales.x.min;
    let maxVal = opt.scales.x.max;
    if (value !== newValue) {
      if (labelsLength - minVal >= noOfColumnToDisplay) {
        let newMin = noOfColumnToScroll * newValue;
        newOpt.scales.x.min = newMin;
        newOpt.scales.x.max = newMin + (noOfColumnToDisplay - 1);
        setOpt(newOpt);
        setValue(newValue);
      } else if (maxVal === labelsLength) {
        console.log(maxVal, labelsLength);
        let newMin = noOfColumnToScroll * newValue;
        newOpt.scales.x.min = newMin;
        newOpt.scales.x.max = newMin + (noOfColumnToDisplay - 1);
        setOpt(newOpt);
        setValue(newValue);
      }
    }
  };
  const styleForNotReviewed = {
    width: "709px",
    height: "350px",
    marginLeft: "28px",
  };
  const styleForProviders = {
    width: "709px",
    height: "500px",
    marginLeft: "28px",
  };

  const navigateToNotReview = (elm) => {
    let eventList = getElementAtEvent(chartRef.current, elm)
    if(eventList.length) {
      let practiceAcronym = data.labels[eventList[0].index]
      let practiceId = practiceData.filter(practice=> practice.practiceAcronym===practiceAcronym)
      history.push({
        pathname: '/rule-processor',
          state: {
              practiceId: practiceId[0].id,
              practiceData: practiceData
          }
      });
    }
  }
  return (
    <>
        <div>
          <div style={props.data.datasets[0].label === "NotReviewed" ? styleForNotReviewed : styleForProviders} 
>
            <Bar  
             ref={chartRef}
             data={data}   
             options={opt}
             onClick={(elm)=> navigateToNotReview(elm)}
            />
          </div>
          <Box sx={{ marginLeft: "20px", width: 750 }}>
            <Slider
              value={value}
              aria-label="Small steps"
              defaultValue={0}
              onChange={clickScroll}
              step={1}
              color="primary"
              min={0}
              max={noOfMaxForSlider}
            />
          </Box>
        </div>
    </>
  );
};

export default BarChart;
