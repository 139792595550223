import React from 'react';
import Sidebar from '../Sidebar';
import Footer from '../Footer/Footer';
import { Button, Card, CardHeader, CardContent } from '@material-ui/core';
import { Dialog, DialogContent, DialogContentText, DialogTitle, Switch } from '@mui/material';
import {
    categoryArray, conditionArray, trizettoConditionArray, eligibilityArray,
    conditionForAllPractice, selectedCategoryData, daysArray, dateData, globalRuleData, RPAFlowNames
} from './data';
import API from '../../Api';
import './createRule.css';
import addIcon from '../../assets/icons/add.svg';
import removeIcon from '../../assets/icons/remove.svg';
import helpIcon from '../../assets/icons/help.svg';
import ReactChipInput from "react-chip-input";
import InputWithChip from './InputWithchips';
import MultipleSelect from './Multiselect';
import _ from 'lodash';
import PropTypes from 'prop-types';
// React Notification
import { NotificationManager } from 'react-notifications';
import { RoAlert } from 'layouts/RoAlert';
import PracticeSelect from "components/Sidebar/PracticeSelect";
import info from '../../assets/icons/info.svg';
import selector from '../../assets/icons/selector.svg';
import questionmar from '../../assets/icons/questionmar.svg';
import tooltiparrow from '../../assets/icons/tooltiparrow.svg';
import close from '../../assets/icons/close.svg';

const andObject = {
    for: 'and',
    operator: '',
    category: '',
    condition: '',
    show: 'none',   // either TEXT or SELECT
    textValuesArray: []
}
const thenObject = {
    pendValue: '',
    for: 'then',
    operator: 'none',
    nestedThen: {
        reason: '',
        rpaNameValue: '',
        rpaId: '',
        customRPAName: '',
        batchToggle: false,
        batchMsg: '',
        errorToggle: false,
        errorMsg: ''
    }
}

function RuleComponentForAnd({ index, onChange, optionList, operator, category, condition, ruleValues, ruleDataValues, handleConditonChange, show, onEnter,
    handleCategoryChange, searchValues, handlePlus, handleMinus, onTagsChange, placeholder, editRule, deleteChipEdit, textValuesArray, isAllPracticeSelected,
    procedureTiles, procedureTileValue, handleProcedureTileChange, dayValue, procedureArray, handleDayValue, procedureValues, onProcedureTagsChange, clearSearch,
    openProcedurePopup, nevermind, gotIt, handleGender, maskDate, globalDropValue, handleGlobalDropValue }) {

    const innerCardStyle = {
        width: '100%',
        height: 'auto',    //155
        background: '#F5F4F4',
        border: '1px solid #B2B1B1',
        boxSizing: 'border-box',
        boxShadow: '3px 3px 7px rgba(0, 0, 0, 0.25)',
        borderRadius: '9px'
    }

    return (
        <>
            <div align="center" className='cd-flex'>
                <div className='font-face-futura numeric-digit'>{index + 1}</div>
                <Card style={innerCardStyle} className='row-change'>
                    <CardContent>
                        <div className='question-mark'>
                            <img src={helpIcon} alt="help" />
                        </div>
                        <div className='row mb-2 align-items-center'>
                            <div className='col-lg-1'>
                                {index === 0 ?
                                    <h4 className='font-face-futura the my-2'>When&nbsp;the</h4> :
                                    <h4 className='font-face-futura the'>the</h4>}
                            </div>

                            <div className={category === 'Global Period' ? 'col-lg-8 ' : (category === 'Modifier' ||
                                category === 'Diagnosis 1' ||
                                category === 'Diagnosis 2' ||
                                category === 'Diagnosis 3' ||
                                category === 'Diagnosis 4' ||
                                category === 'Diagnosis in any slot' ||
                                category === 'Total Charges' ||
                                category === 'Units' ||
                                category === 'Unit Charge') ? 'col-lg-4' : 'col-lg-5'}>
                                <select
                                    name={`category-${index}`}
                                    className='selectStyle font-face-futura text-center'
                                    value={category}
                                    // style={{
                                    //     width: '100%',
                                    //     height: 50, backgroundColor: '#FFFFFF',
                                    //     borderRadius: '9px', border: '1px solid #9A9A9A', fontSize: 18
                                    // }}
                                    onChange={(e) => handleCategoryChange(e, index)}>
                                    <option value=""></option>
                                    {categoryArray.map((cat) => (
                                        <option key={cat.name} value={cat.name}>{cat.name}</option>
                                    ))}
                                </select>
                            </div>

                            {(category === 'Modifier' ||
                                category === 'Diagnosis 1' ||
                                category === 'Diagnosis 2' ||
                                category === 'Diagnosis 3' ||
                                category === 'Diagnosis 4' ||
                                category === 'Diagnosis in any slot' ||
                                category === 'Total Charges' ||
                                category === 'Units' ||
                                category === 'Unit Charge') && procedureTiles.length > 0 ?
                                // category === 'Unit Charge') && procedureTiles.length > 0 && procedureTileValue !== 'any' ?
                                <div className='col-lg-2 cdflex'>
                                    <span className='font-face-futura' style={{ fontSize: 18 }}>for procedure tile </span>
                                    <select className='selectStyle font-face-futura text-left'
                                        value={procedureTileValue}
                                        // style={{
                                        //     height: 50, backgroundColor: '#FFFFFF',
                                        //     borderRadius: '9px', border: '1px solid #9A9A9A', fontSize: 17
                                        // }}
                                        onChange={(e) => handleProcedureTileChange(e, index)}>
                                        <option value=""></option>
                                        {procedureTiles.map(tile => (
                                            <option key={tile} value={tile}>{tile}</option>
                                        ))}
                                    </select>
                                </div> : (category === 'Modifier' ||
                                    category === 'Diagnosis 1' ||
                                    category === 'Diagnosis 2' ||
                                    category === 'Diagnosis 3' ||
                                    category === 'Diagnosis 4' ||
                                    category === 'Diagnosis in any slot' ||
                                    category === 'Total Charges' ||
                                    category === 'Units' ||
                                    category === 'Unit Charge') &&
                                <div className='col-lg-2 font-face-futura' style={{ marginTop: '0.75rem', fontSize: 18 }}>
                                    <span>for any procedure</span>
                                </div>}

                            {category === 'Global Period' &&
                                <>
                                    <div className='col-lg-3'>
                                        <span className='font-face-futura ms-3' style={{ fontSize: 24, float: 'left' }}>for the procedure(s)</span>
                                    </div>
                                    <div className='col-lg-12 '>
                                        <MultipleSelect multiple className="custom-select" categoryType={category}
                                            options={optionList}
                                            indexs={index}
                                            separateBy="or"
                                            onTagsChange={onTagsChange}
                                            placeholder={placeholder}
                                            editRule={editRule}
                                            ruleValues={ruleValues}
                                            value={textValuesArray}
                                            onChange={(e) => {
                                                searchValues(category, e.target.value)
                                            }} />
                                    </div>


                                    <div className='col-lg-10 offset-md-1'>
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                <span className='font-face-futura ' style={{ fontSize: 24 }}>is within</span>
                                                <select className='selectStyle withinday font-face-futura days-select text-center'
                                                    name="days"
                                                    value={dayValue}
                                                    onChange={(e) => handleDayValue(e, index)}>
                                                    <option value=""></option>
                                                    {daysArray.map(day => (
                                                        <option key={day.name} value={day.value}>{day.value}</option>
                                                    ))}
                                                </select>
                                                <span className='font-face-futura ms-2' style={{ fontSize: 24 }}>day(s),</span>
                                            </div>
                                            <div className='col-lg-6'>
                                                <select className='selectStyle font-face-futura text-center'
                                                    name="days"
                                                    disabled={dayValue === '0' ? true : false}
                                                    value={dayValue === '0' ? 'before & after' : globalDropValue}
                                                    onChange={(e) => handleGlobalDropValue(e, index)}>
                                                    <option value=""></option>
                                                    {globalRuleData.map(day => (
                                                        <option key={day.name} value={day.value}>{day.value}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='font-face-futura mt-1'>
                                        <span className='mr-r' style={{ fontSize: 24 }}>we cannot bill the procedure(s):</span>
                                    </div>
                                    <div className='col-lg-12'>
                                        <MultipleSelect multiple className="custom-select" categoryType={category}
                                            options={optionList}
                                            indexs={index}
                                            separateBy="or"
                                            onTagsChange={onProcedureTagsChange}
                                            placeholder={placeholder}
                                            editRule={editRule}
                                            ruleValues={procedureValues}
                                            value={procedureArray}
                                            onChange={(e) => {
                                                searchValues(category, e.target.value)
                                            }} />
                                    </div>
                                </>
                            }

                            {category !== 'Global Period' && <div className={(category === 'Modifier' ||
                                category === 'Diagnosis 1' ||
                                category === 'Diagnosis 2' ||
                                category === 'Diagnosis 3' ||
                                category === 'Diagnosis 4' ||
                                category === 'Diagnosis in any slot' ||
                                category === 'Total Charges' ||
                                category === 'Units' ||
                                category === 'Unit Charge') ? 'col-lg-4' : 'col-lg-5'}>
                                <select
                                    className='selectStyle font-face-futura text-center'
                                    name={`condition-${index}`}
                                    value={condition}
                                    // style={{
                                    //     width: '100%',
                                    //     height: 50, backgroundColor: '#FFFFFF',
                                    //     borderRadius: '9px', border: '1px solid #9A9A9A', fontSize: 18
                                    // }}
                                    onChange={handleConditonChange}>
                                    {category === 'Trizetto Payer ID' ? trizettoConditionArray.map((con) => (
                                        <option key={con.name} value={con.value}>{con.name}</option>
                                    )) : category === 'Eligibility Response' ? eligibilityArray.map((eli) => (
                                        <option key={eli.name} value={eli.value}>{eli.name}</option>
                                    )) : (category === 'Policy Number' ||
                                        category === 'Patient Alert' ||
                                        category === 'Patient Address') ? conditionForAllPractice.map((con) => (
                                            <option key={con.name} value={con.value}>{con.name}</option>
                                        )) : (category === 'Place of Service Code') ? conditionArray.map((con) => (
                                            <option key={con.name} value={con.value}>{con.name}</option>
                                        )) :
                                        (category === 'Age' ||
                                            category === 'Total Charges' ||
                                            category === 'Units' ||
                                            category === 'Unit Charge') ? selectedCategoryData.map((sel) => (
                                                <option key={sel.name} value={sel.value}>{sel.name}</option>
                                            )) :
                                            ((category === 'Date of Injury' ||
                                                category === 'Post Date' ||
                                                category === 'Service Date' || category === 'Hospitalization Start Date')) ? dateData.map((sel) => (
                                                    <option key={sel.name} value={sel.value}>{sel.name}</option>
                                                )) :
                                                ((category === 'Place of Service' ||
                                                    category === 'Primary Insurance Plan' ||
                                                    category === 'Referring Provider' ||
                                                    category === 'Rendering Provider' ||
                                                    category === 'Scheduling Provider' ||
                                                    category === 'Secondary Insurance Plan' || 'Policy Number') &&
                                                    (isAllPracticeSelected === true) && (category !== 'Diagnosis 1' &&
                                                        category !== 'Diagnosis 2' &&
                                                        category !== 'Diagnosis 3' &&
                                                        category !== 'Diagnosis 4' &&
                                                        category !== 'Diagnosis in any slot' &&
                                                        category !== 'Modifier' && category !== 'Procedure Code')) ? conditionForAllPractice.map((practice) => (
                                                            <option key={practice.name} value={practice.value}>{practice.name}</option>
                                                        )) :
                                                    ((category === 'Diagnosis 1' ||
                                                        category === 'Diagnosis 2' ||
                                                        category === 'Diagnosis 3' ||
                                                        category === 'Diagnosis 4' ||
                                                        category === 'Diagnosis in any slot' ||
                                                        category === 'Modifier' || category === 'Procedure Code') &&
                                                        (isAllPracticeSelected === true)) ? conditionArray.map((con) => (
                                                            <option key={con.name} value={con.value}>{con.name}</option>
                                                        )) :
                                                        conditionArray.map((con) => (
                                                            <option key={con.name} value={con.value}>{con.name}</option>
                                                        ))}
                                </select>
                            </div>}
                        </div>
                        {show === 'TEXT' ? (
                            <InputWithChip
                                separateBy={["is", "contains"].includes(condition) ? "or" : "and"}
                                forCondition={condition} categoryType={category}
                                editRule={editRule}
                                indexs={index}
                                ruleValues={ruleDataValues}
                                deleteChipEdit={deleteChipEdit}
                                onEnter={(chips) => onEnter({ chips, index })}
                                maskdate={maskDate}
                            />
                        ) : (show === 'DROP' && category !== 'Global Period') ? (
                            <MultipleSelect multiple className="custom-select" categoryType={category}
                                options={optionList}
                                indexs={index}
                                separateBy={["is", "contains"].includes(condition) ? "or" : "and"}
                                onTagsChange={onTagsChange}
                                placeholder={placeholder}
                                editRule={editRule}
                                ruleValues={ruleValues}
                                value={textValuesArray}
                                tagValues={textValuesArray}
                                gender={textValuesArray}
                                handleGender={(e) => handleGender(e, index)}
                                onFocus={clearSearch}
                                onChange={(e) => {
                                    searchValues(category, e.target.value)
                                }} />
                        ) : null}
                        <div style={{ float: 'right', marginBootom: 10, cursor: 'pointer' }} className='picon'>
                            <img src={addIcon} alt="add" width={20} onClick={() => handlePlus(index)} />
                            {index > 0 ? <>&nbsp;&nbsp;
                                <img src={removeIcon} alt="remove" width={20} onClick={() => handleMinus(index)} />
                            </> : <></>}
                        </div>
                    </CardContent>
                </Card>
            </div>

            <div align="center" className='mt-4 mb-4'>
                <select
                    className='p-1 selectStyle font-face-futura text-center'
                    name={`rule-${index}`}
                    value={operator}
                    style={{ width: '150px' }}
                    // style={{ width: '150px', height: 50, backgroundColor: '#FFFFFF', borderRadius: '9px', border: '1px solid #9A9A9A', fontSize: 18 }}
                    onChange={onChange}>
                    <option value="none"></option>
                    <option value="and">and</option>
                    <option value="then">then</option>
                </select>&nbsp;&nbsp;&nbsp;
                <img src={helpIcon} alt="help" style={{ cursor: 'pointer' }} />
            </div>

            {openProcedurePopup &&
                <Dialog className='boxWidth'
                    maxWidth="lg"
                    open={openProcedurePopup}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <div align="center" className='img-avatar'>
                        <img src={info} className='image' />
                    </div>
                    <div align="center" className='dialog-box2' style={{ height: '580px' }}>
                        <DialogTitle id="alert-dialog-title">
                            <span className='pleaseConfirm'>{"PLEASE CONFIRM"}</span>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <h4 className='mt-3 head1'>What you are currently doing:</h4>
                                <span className='textColor'>By adding multiple procedures in this tile, you are specifying the different procedure <br />
                                options that could apply to <b>a single line item</b>.</span>
                            </DialogContentText><br />
                            <DialogContentText id="alert-dialog-description">
                                <h5 className='head2'>Are you trying to add more than one (1) procedure on the same claim?</h5>
                                <span className='textColor'>If yes, create a <b>new tile</b> for each additional procedure.<br />
                                    Use each new tile to list procedures for a separate line item.</span>
                            </DialogContentText>
                            <div className='mt-2'>
                                <p className='view'>VIEW TRAINING MATERIAL</p>
                            </div>
                            <div className="actionButton">
                                <Button fullWidth name="never" className='mx-2 never-mind' variant='contained' onClick={(e) => nevermind(e, index)}>
                                    <span className='text-white font-face-futura'>NEVERMIND</span>
                                </Button>
                                <Button fullWidth name="got" className='mx-2 looks-good' variant='contained' onClick={gotIt}>
                                    <span className='text-white font-face-futura'>GOT IT!</span>
                                </Button>
                            </div>
                        </DialogContent>
                    </div>
                </Dialog>
            }
        </>
    )
}

// RuleComponentForAnd.propTypes = {
//     operator: PropTypes.string.isRequired,
//     index: PropTypes.number.isRequired,
//     onEnter: PropTypes.func.isRequired,
//     onChange: PropTypes.func.isRequired,
//     optionList: PropTypes.array.isRequired,
//     category: PropTypes.string.isRequired,
//     condition: PropTypes.string.isRequired,
//     handleConditonChange: PropTypes.func.isRequired,
//     show: PropTypes.string.isRequired,
//     onClick: PropTypes.func.isRequired,
//     handleCategoryChange: PropTypes.func.isRequired,
//     searchValues: PropTypes.string.isRequired,
//     handlePlus: PropTypes.func.isRequired,
//     handleMinus: PropTypes.func.isRequired
// }

function RuleComponentForThen({ index, onChange, pendValue, operator, handleChange, handleThen, reason, rpaNameValue, handleRPAName, customRPAName, rpaId,
    batchToggle, batchMsg, errorToggle, errorMsg, handleToggle, showVariableSelector, handleVariableSelector, customRPAList, globalRPAList, handleVariableOptions,
    variable, procedureTiles, procedureTileValue, handleProcedureTileChange }) {
    const innerCardStyle = {
        width: '100%',
        // maxHeight: '260px',    //155
        height: 'auto',    //155
        background: '#F5F4F4',
        border: '1px solid #B2B1B1',
        boxSizing: 'border-box',
        boxShadow: '3px 3px 7px rgba(0, 0, 0, 0.25)',
        borderRadius: '9px'
    }
    let client = JSON.parse(sessionStorage.getItem('clientDetail'));
    // console.log(customRPAName)
    let variableList = [
        { key: 'encounterInformation', value: 'Encounter Information' },
        { key: 'serviceLocation', value: 'Service Location Name' },
        { key: 'policyInformation', value: 'Policy Information' },
        { key: 'primaryInsurance', value: 'Primary Insurance Plan Name' },
        { key: 'secondaryInsurance', value: 'Secondary Insurance Plan Name' },
        { key: 'providerType', value: 'Provider Type' },
        { key: 'renderingProvider', value: 'Rendering Provider' },
        { key: 'schedulingProvider', value: 'Scheduling Provider' },
        { key: 'referringProvider', value: 'Referring Provider' }
    ]
    return (
        <>
            <div align="center" className='cd-flex'>
                <div className='numeric-digit'>A</div>
                <Card style={innerCardStyle}>
                    <CardContent>
                        <div className='question-mark'>
                            <img src={helpIcon} alt="help" />
                        </div>
                        <div className='row mb-2'>
                            <div className='col-lg-2'>
                                <h4 className='font-face-futura the my-2'>Do&nbsp;what?</h4>
                            </div>
                            <div className='col-lg-8'>
                                <select
                                    name={`cat-${index}`}
                                    className='selectStyle font-face-futura text-center'
                                    value={pendValue}
                                    style={{ width: '100%', height: 50, backgroundColor: '#FFFFFF', borderRadius: '9px', fontSize: 18 }}
                                    onChange={onChange}>
                                    <option value=""></option>
                                    <option value="pend to review tab">Pend to Review Tab</option>
                                    {client?.RPAEnabled && <option value="Run RPA Flow">Run RPA Flow</option>}
                                </select>
                            </div>

                            <div>
                                {pendValue === 'pend to review tab' &&
                                    <div className='mt-3'>
                                        <div className='pos'>
                                            <span className='font-face-futura tleft my-2'>Reason:</span><br />
                                            <textarea
                                                className='p-2 font-face-futura textbox reason'
                                                name="txt"
                                                value={reason}
                                                onChange={handleChange} />
                                        </div>
                                    </div>
                                }
                                {pendValue === 'Run RPA Flow' &&
                                    <><Card className='rpaCard'>
                                        <CardHeader className='rpaCardHead' title={<h5 className='text-start text-white font-face-futura'>Selector</h5>} />
                                        <CardContent className='rpaContent'>
                                            <div className='mx-2 row'>
                                                <div className='text-start col-12 col-sm-12 col-lg-12'>
                                                    <label htmlFor='rpaName'><strong>RPA Flow Name:</strong></label>
                                                    <select name='rpaName' className='rpa_select' value={rpaNameValue} onChange={handleRPAName}>
                                                        <option>Select</option>
                                                        {globalRPAList?.map((global, idx) => (
                                                            <option key={global.id} value={global.name}>{global.name}</option>
                                                        ))}
                                                        <option value="Run Custom RPA Flow">Run Custom RPA Flow</option>
                                                    </select>
                                                </div>
                                            </div>
                                            {rpaNameValue && (rpaNameValue !== 'Change Modifier') && <div className='mx-2 my-3 row'>
                                                <div className='text-start col-12 col-sm-12 col-lg-12'>
                                                    <label htmlFor='flowName'>
                                                        <strong>
                                                            {rpaNameValue === 'Run Custom RPA Flow' ? 'Custom RPA Flow Name' :
                                                                rpaNameValue === 'Update Provider' ? 'Provider Field' :
                                                                    rpaNameValue === 'Add Authorization' ? 'Verify Authorization Line Note' : ''}:
                                                        </strong>
                                                    </label>
                                                    {rpaNameValue === 'Run Custom RPA Flow' &&
                                                        <select name='customRPA' className='rpa_select' id={rpaId} value={customRPAName} onChange={handleRPAName}>
                                                            <option>Select</option>
                                                            {customRPAList?.map(custom => (
                                                                <option key={custom.id} value={custom.id} id={custom.id}>{custom.name}</option>
                                                            ))}
                                                        </select>}
                                                    {rpaNameValue === 'Update Provider' &&
                                                        <select name='flowName' className='rpa_select'>
                                                            <option>Select</option>
                                                            <option>Rendering Provider</option>
                                                            <option>Scheduling Provider</option>
                                                            <option>Referring Provider</option>
                                                        </select>}
                                                    {rpaNameValue === 'Add Authorization' &&
                                                        <select name='flowName' className='rpa_select'>
                                                            <option>Select</option>
                                                            <option>False</option>
                                                            <option>True</option>
                                                        </select>}
                                                </div>
                                            </div>}
                                        </CardContent>
                                    </Card>
                                        {/* {rpaNameValue && (rpaNameValue === 'Update Provider' || rpaNameValue === 'Add Authorization') &&
                                            <Card className='rpaCard overflow-visible'>
                                                <CardHeader className='rpaCardHead' title={<h5 className='text-start text-white font-face-futura'>Condition</h5>} />
                                                <CardContent className='rpaContent'>
                                                    <div className='mx-2 row'>
                                                        <div className='text-start col-12 col-sm-12 col-lg-12'>
                                                            <label htmlFor='flowName'>
                                                                <strong>
                                                                    {rpaNameValue === 'Run Custom RPA Flow' ? 'Custom RPA Flow Name' :
                                                                        rpaNameValue === 'Update Provider' ? 'Provider Name' :
                                                                            rpaNameValue === 'Add Authorization' ? 'Auth Note Field Contains (25 character max)' : ''}:
                                                                </strong>
                                                            </label>
                                                            <div className='selDiv '>
                                                                <div className='selImg'>
                                                                    <img src={selector} alt='selector' onClick={handleVariableSelector} />
                                                                </div>

                                                                {showVariableSelector && <div className='position-absolute seltoggle'>
                                                                    <div>
                                                                        <div className='position-relative upper-section'>
                                                                            <h6 className='ps-3'>Variable Selector</h6>
                                                                            <img src={tooltiparrow} className='arrow-left' />

                                                                            <span className='arrow-right'>
                                                                                <img src={questionmar} />
                                                                                <img src={close} />
                                                                            </span>
                                                                        </div>
                                                                        <ul className='list-unstyled mb-0 text-center'>
                                                                            {variableList.map((list, idx) => (
                                                                                <li
                                                                                    className={idx === 0 || idx === 2 || idx === 5 ? 'active' : ''}
                                                                                    key={list.key} value={list.value} onClick={() => handleVariableOptions(list.value)}>{list.value}</li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                </div>}
                                                                {(rpaNameValue === 'Update Provider' || rpaNameValue === 'Add Authorization') && !variable ?
                                                                    <input type='text' name='selector' className='batchInput' /> :
                                                                    <label htmlFor="chips-input" className="label-wrap font-face-futura labelbox">
                                                                        <span key={index} className="rpa-chips">
                                                                            {variable}{" "}&nbsp;&nbsp;
                                                                            <span className="chip-del-btn">
                                                                                &times;
                                                                            </span>
                                                                        </span>
                                                                    </label>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </CardContent>
                                            </Card>}

                                        {rpaNameValue && (rpaNameValue === 'Change Modifier') &&
                                            <Card className='rpaCard overflow-visible'>
                                                <CardHeader className='rpaCardHead' title={<h5 className='text-start text-white font-face-futura'>Condition</h5>} />
                                                <CardContent className='rpaContent'>
                                                    <div className='mx-2 row'>
                                                        <div className='text-start col-12 col-sm-12 col-lg-12'>
                                                            <label htmlFor='flowName'>
                                                                <strong>
                                                                    {rpaNameValue === 'Change Modifier' ? 'All Procedure Codes in Tile' : ''}:
                                                                </strong>
                                                            </label>
                                                            <input name='errorMsg'
                                                                type='text'
                                                                className='batchInput'
                                                                value={errorMsg} onChange={handleRPAName} />
                                                        </div>
                                                    </div>
                                                    <div className='mx-2 mt-2 row'>
                                                        <div className='text-start col-12 col-sm-12 col-lg-12'>
                                                            <label htmlFor='flowName'>
                                                                <strong>
                                                                    {rpaNameValue === 'Change Modifier' ? 'Add Modifier' : ''}:
                                                                </strong>
                                                            </label>
                                                            <input name='errorMsg'
                                                                type='text'
                                                                className='batchInput'
                                                                value={errorMsg} onChange={handleRPAName} />
                                                        </div>
                                                    </div>
                                                </CardContent>
                                            </Card>} */}
                                        <Card className='rpaCard'>
                                            <CardHeader className='rpaCardHead' title={<h5 className='text-start text-white font-face-futura'>Error Handling</h5>} />
                                            <CardContent className='rpaContent'>
                                                <div className='mx-2 row'>
                                                    <div className='text-start col-12 col-sm-12 col-lg-12'>
                                                        <label htmlFor='batchToggle'><strong>Add Batch Message if there is an error?</strong></label><br />
                                                        <span className='font12 custom_switch'>Off <Switch name="batchToggle" checked={batchToggle} onChange={handleToggle} />On</span>
                                                    </div>
                                                </div>
                                                <div className='mx-2 row'>
                                                    <div className='text-start col-12 col-sm-12 col-lg-12'>
                                                        <label htmlFor='batchMsg'><strong>Batch Message (10 characters max):</strong></label>
                                                        <input
                                                            name='batchMsg'
                                                            type='text'
                                                            maxLength={10}
                                                            disabled={batchToggle === false ? true : false}
                                                            className={batchToggle === false ? 'batchDisable' : 'batchInput'}
                                                            value={batchMsg} onChange={handleRPAName} />
                                                    </div>
                                                </div>
                                                <div className='mx-2 mt-3 row'>
                                                    <div className='text-start col-12 col-sm-12 col-lg-12'>
                                                        <label htmlFor='errorToggle'><strong>Add Erroe Note if there is an error?</strong></label><br />
                                                        <small>If you turn this off we will put an error note stating the action could not be conpleted</small><br />
                                                        <span className='font12 custom_switch'>Off <Switch name="errorToggle" checked={errorToggle} onChange={handleToggle} />On</span>
                                                    </div>
                                                </div>
                                                <div className='mx-2 row'>
                                                    <div className='text-start col-12 col-sm-12 col-lg-12'>
                                                        <label htmlFor='errorMsg'><strong>Error Message:</strong></label>
                                                        <input name='errorMsg'
                                                            type='text'
                                                            disabled={errorToggle === false ? true : false}
                                                            className={errorToggle ? 'batchInput' : 'batchDisable'}
                                                            value={errorMsg} onChange={handleRPAName} />
                                                    </div>
                                                </div>
                                            </CardContent>
                                        </Card></>
                                }
                            </div>
                        </div>
                        <div className='picon'>
                            <img src={removeIcon} alt="remove" width={20} onClick={() => handleThen(index)} />
                        </div>
                    </CardContent>
                </Card>
            </div>
        </>
    )
}

RuleComponentForThen.propTypes = {
    operator: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    pendValue: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleThen: PropTypes.func.isRequired
}

function getModifiedDetails(ruleDetails) {
    // console.log("RULE DETAILS", ruleDetails)
    ruleDetails.ruleInfo = ruleDetails?.ruleInfo?.map((m, index) => {
        if (index !== ruleDetails?.ruleInfo?.length - 1) m["for"] = "and";
        return m;
    })
    return ruleDetails;
}

export default class CreateRule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showCreatedRule: false,
            ruleName: '',
            gender: '',
            rulesArray: [{
                category: "",
                condition: "",
                textValuesArray: [],
                operator: "",
                for: "and",
                show: 'none',
                procedureTileVal: "",
                dayValue: "",
                globalDropValue: "",
                procedureValueArray: []
            }],
            optionArray: [],
            tags: this.props.location.state ? this.props.location.state?.tags : [],
            optionList: [],
            searchKey: '',
            mPlaceholder: 'Type something to search...',
            ruleData: this.props.location.state ? getModifiedDetails(this.props.location.state.ruleDetail) : {},
            editRule: this.props.location.state ? this.props.location.state.editRule : false,
            ruleId: this.props.match.params.ruleId,
            showCreateRulePopup: false,
            showEditRulePopup: false,
            isAllPracticeSelected: false,
            showCreateRuleModal: false,
            practices: [],
            procedureTileArray: [],
            showCreateRuleModal: false,
            customSetPractice: () => { },
            showProcedurePopup: false,
            showVariableSelector: false,
            customRPAList: [],
            globalRPAList: [],
            variable: ''
        }
    }

    // function to get custom RPA list
    getCustomRPA = () => {
        API.get('getCustomRPAClient').then((res) => {
            let customRPA = res.data.data.filter(item => item.status === 'Active');
            // let id = customRPA.map(item => {
            //     console.log(item.id)
            // })
            this.setState({ customRPAList: customRPA || [] });
        }).catch(err => {
            console.error(err);
        })
    }

    // function to get global RPA list
    getGlobalRPA = () => {
        API.get('getGlobalRPAClient').then((res) => {
            let globalRPA = res.data.data.filter(item => item.status === 'Active');
            this.setState({ globalRPAList: globalRPA || [] });
        }).catch(err => {
            console.error(err);
        })
    }

    // function to handle rpa name change
    handleRPANameChange = (e, index) => {
        const { name, value } = e.target;
        if (!this.state.editRule) {
            let rulesArrayCopy = [...this.state.rulesArray];
            if (name === 'rpaName') {
                rulesArrayCopy[index].nestedThen.rpaNameValue = value;
            }
            if (name === 'customRPA') {
                console.log(e.target)
                rulesArrayCopy[index].nestedThen.customRPAName = this.state.customRPAList.find((custom) => custom.id == value).name;
                rulesArrayCopy[index].nestedThen.rpaId = value;
            }
            if (name === 'batchMsg') {
                rulesArrayCopy[index].nestedThen.batchMsg = value;
            }
            if (name === 'errorMsg') {
                rulesArrayCopy[index].nestedThen.errorMsg = value;
            }
            this.setState({ rulesArray: rulesArrayCopy, [name]: value, variable: '' })
        } else {
            let ruleDataCopy = { ...this.state.ruleData };
            if (name === 'rpaName') {
                ruleDataCopy.rpaInfo[0].rpa_flow_Name = value;
            }
            if (name === 'customRPA') {
                // console.log(">>>>>>>>>>>>>>>>>", value, this.state.customRPAList.find((custom) => custom.id == value).name)
                // ruleDataCopy.rpaInfo[0].customRPAName = this.state.customRPAList.find((custom) => custom.id == value).name;
                ruleDataCopy.rpaInfo[0].id = value;
            }
            if (name === 'batchMsg') {
                ruleDataCopy.rpaInfo[0].batch_message = value;
            }
            if (name === 'errorMsg') {
                ruleDataCopy.rpaInfo[0].error_message = value;
            }
            this.setState({ ruleData: ruleDataCopy });
        }
    }

    // function to handle variable selector
    handleVariableSelector = () => {
        this.setState({ showVariableSelector: !this.state.showVariableSelector });
    }

    // function to handle variable options
    handleVariableOptions = (value) => {
        console.log(value);
        this.setState({ variable: value });
    }

    // functions to perform RPA actions
    handleToggle = (e, index) => {
        let { name, checked } = e.target;
        if (!this.state.editRule) {
            let rulesArrayCopy = [...this.state.rulesArray]
            if (name === 'batchToggle') {
                rulesArrayCopy[index].nestedThen.batchToggle = checked;
            } else if (name === 'errorToggle') {
                rulesArrayCopy[index].nestedThen.errorToggle = checked;
            }
            this.setState({ rulesArray: rulesArrayCopy });
        } else {
            let ruleDataCopy = { ...this.state.ruleData };
            if (name === 'batchToggle') {
                ruleDataCopy.rpaInfo[0].batch_status = checked;
            } else if (name === 'errorToggle') {
                ruleDataCopy.rpaInfo[0].error_status = checked;
            }
            this.setState({ ruleData: ruleDataCopy });
        }
    }

    onTagsChange = (e, values, indexs) => {
        const { ruleData } = this.state;
        this.setState({ optionArray: [] });
        // console.log(e, values, "values", indexs);
        let optionValues = [];
        if (this.state.editRule) {
            let rulesInfoCopy = [...ruleData.ruleInfo]
            rulesInfoCopy[indexs].values = values;
            this.setState({ ruleData: { ...ruleData, ruleInfo: rulesInfoCopy } })
            // console.log(rulesInfoCopy);

            rulesInfoCopy.map((item) => {
                // console.log(item)
                item.values?.map(val => {
                    if (val.type) {
                        optionValues.push({
                            id: val.id,
                            name: val.name.split('-')[0].trim(),
                            type: val.type
                        })
                    } else {
                        optionValues.push(item)
                    }
                    return val;
                })
                return item;
            })
        } else {
            let rulesArrayCopy = [...this.state.rulesArray];
            let categoryType = rulesArrayCopy[indexs].category;
            values.map((item) => {
                if (item.type &&
                    (categoryType === 'Diagnosis 1' ||
                        categoryType === 'Diagnosis 2' ||
                        categoryType === 'Diagnosis 3' ||
                        categoryType === 'Diagnosis 4' ||
                        categoryType === 'Diagnosis in any slot' ||
                        categoryType === 'Procedure Code' ||
                        categoryType === 'Modifier' || categoryType === 'Global Period')) {
                    optionValues.push({
                        id: item.id,
                        name: item.name.split('-')[0].trim(),
                        type: item.type
                    })
                } else {
                    optionValues.push({ id: item.id, name: item.name, type: item.type });
                }
                return item;
            })
            if (categoryType === 'Procedure Code' && optionValues.length === 2) {
                this.setState({ showProcedurePopup: true });
                // this.setState({showProcedurePopup: true, secondValue:extraValue});
            }
            // console.log(indexs, "optionValues", optionValues, this.state.rulesArray);

            // let rulesArrayCopy = [...this.state.rulesArray];

            rulesArrayCopy[indexs].textValuesArray = optionValues;
            this.setState({ rulesArray: rulesArrayCopy });
        }

        this.setState({ optionArray: optionValues });
    }

    handleNevermind = (e, index) => {
        let rulesArrayCopy = [...this.state.rulesArray]
        let category = rulesArrayCopy[index].category;
        let length = rulesArrayCopy[index].textValuesArray.length;
        console.log(rulesArrayCopy, index, length);
        if (category === 'Procedure Code' && length === 2) {
            rulesArrayCopy[index].textValuesArray.pop();
            this.setState({ showProcedurePopup: false, rulesArray: rulesArrayCopy });
        }

    }
    handleProcedurePopup = () => {
        this.setState({ showProcedurePopup: false, isNevermind: false })
    }

    onProcedureTagsChange = (e, values, indexs) => {
        const { ruleData } = this.state;
        this.setState({ optionArray: [] });
        // console.log(e, values, "values", indexs);
        let optionValues = [];
        if (this.state.editRule) {
            let rulesInfoCopy = [...ruleData.ruleInfo]
            rulesInfoCopy[indexs].procedureArray = values;
            this.setState({ ruleData: { ...ruleData, ruleInfo: rulesInfoCopy } })
            // console.log(rulesInfoCopy);

            rulesInfoCopy.map((item) => {
                // console.log(item)
                item.procedureArray?.map(val => {
                    if (val.type) {
                        optionValues.push({
                            id: val.id,
                            name: val.name.split('-')[0].trim(),
                            type: val.type
                        })
                    } else {
                        optionValues.push(item)
                    }
                    return val;
                })
                return item;
            })
        } else {
            let rulesArrayCopy = [...this.state.rulesArray];
            let categoryType = rulesArrayCopy[indexs].category;
            values.map((item) => {
                if (item.type &&
                    (categoryType === 'Diagnosis 1' ||
                        categoryType === 'Diagnosis 2' ||
                        categoryType === 'Diagnosis 3' ||
                        categoryType === 'Diagnosis 4' ||
                        categoryType === 'Diagnosis in any slot' ||
                        categoryType === 'Procedure Code' ||
                        categoryType === 'Modifier' || categoryType === 'Global Period')) {
                    optionValues.push({
                        id: item.id,
                        name: item.name.split('-')[0].trim(),
                        type: item.type
                    })
                } else {
                    optionValues.push({ name: item.name });
                }
                return item;
            })
            // console.log(indexs, "optionValues", optionValues, this.state.rulesArray);

            // let rulesArrayCopy = [...this.state.rulesArray];

            rulesArrayCopy[indexs].procedureValueArray = optionValues;
            this.setState({ rulesArray: rulesArrayCopy });
        }

        this.setState({ optionArray: optionValues });
    }

    // function to validate form
    isFormValid = () => {
        const { ruleName, rulesArray } = this.state;
        let rulesArrayCopy = [...rulesArray];
        let ruleDataCopy = { ...this.state.ruleData };
        // console.log("rulesArrayCopy", rulesArrayCopy, ruleDataCopy);

        let isValid = true;

        if (!this.state.editRule) {
            if (!ruleName) {
                isValid = false;
                NotificationManager.error('Rule name is mandatory!', 'Failure!', 3000);
            }
            rulesArrayCopy.map(rule => {
                if (rule.for === 'and' && (!rule.category || !rule.condition)) {
                    isValid = false;
                    NotificationManager.error('There must be atleast one tile to create a rule!', 'Failure!', 3000);
                }
                else if (rulesArrayCopy.length === 2 && rule.category === 'Eligibility Response') {
                    isValid = false;
                    NotificationManager.error('User must first select a payer ID', 'Failure!', 3000);
                }
                else if (rule.for === 'and' && rule.condition !== 'is missing' && rule.textValuesArray.length === 0 && rule.category !== 'Gender') {
                    isValid = false;
                    NotificationManager.error('User must enter or select atleast one value!', 'Failure!', 3000);
                }
                else if (!rule.operator) {
                    isValid = false;
                    NotificationManager.error('Please select to add a new tile!', 'Failure!', 3000);
                }
                else if (Object.keys(rule).length === 4 && rule.for === 'then' && !rule.pendValue) {
                    isValid = false;
                    NotificationManager.error('Please select from action tile!', 'Failure!', 3000);
                }
                else if (rule.operator === 'none' && rule.pendValue === 'pend to review tab' && !rule.nestedThen.reason) {
                    isValid = false;
                    NotificationManager.error('Reason is mandatory!', 'Failure!', 3000);
                }
                switch (rule.category) {
                    case 'Age':
                        switch (rule.condition) {
                            case 'is between':
                                if (rule.textValuesArray.length > 2) {
                                    isValid = false;
                                    NotificationManager.error('More than 2 values cannot be selected!', 'Failure!', 3000);
                                }
                                break;
                            case 'is above':
                            case 'is below':
                            case 'is equal to':
                            case 'is equal to or above':
                            case 'is equal to or below':
                                if (rule.textValuesArray.length > 1) {
                                    isValid = false;
                                    NotificationManager.error('More than 1 value cannot be selected!', 'Failure!', 3000);
                                }
                                break;
                            default:
                                break;
                        }
                        break;
                    case 'Date of Injury':
                    case 'Post Date':
                    case 'Service Date':
                        switch (rule.condition) {
                            case 'is between':
                                if (rule.textValuesArray.length > 2) {
                                    isValid = false;
                                    NotificationManager.error('More than 2 values cannot be selected!', 'Failure!', 3000);
                                }
                                break;
                            case 'is after':
                            case 'is before':
                            case 'is equal to':
                            case 'is equal to or after':
                            case 'is equal to or before':
                                if (rule.textValuesArray.length > 1) {
                                    isValid = false;
                                    NotificationManager.error('More than 1 value cannot be selected!', 'Failure!', 3000);
                                }
                                break;
                            default:
                                break;
                        }
                        break;
                    default:
                        break;
                }
                return rule;
            })
        }
        else {
            if (!ruleDataCopy.ruleName) {
                isValid = false;
                NotificationManager.error('Rule name is mandatory!', 'Failure!', 3000);
            }
            const length = ruleDataCopy.ruleInfo.length;
            ruleDataCopy.ruleInfo.map((rule, idx) => {
                if (idx <= length - 1 && rule.for === 'and' && ((!rule.category || !rule.condition) || (!rule.category && !rule.condition))) {
                    isValid = false;
                    NotificationManager.error('There must be atleast one tile to create a rule!', 'Failure!', 3000);
                }
                else if (idx <= length - 1 && rule.condition !== 'is missing' && rule.values?.length === 0) {
                    isValid = false;
                    NotificationManager.error('User must enter or select atleast one value!', 'Failure!', 3000);
                }
                else if (idx === length - 2 && (rule.operator !== 'then')) {
                    isValid = false;
                    NotificationManager.error('Please select to add a new tile! -----> operator', 'Failure!', 3000);
                }
                else if (Object.keys(rule).length === 4 && rule.for === 'then' && !ruleDataCopy.result) {
                    isValid = false;
                    NotificationManager.error('Please select from action tile!', 'Failure!', 3000);
                }
                else if (idx === length - 1 && ruleDataCopy.result === 'pend to review tab' && !ruleDataCopy.Reason) {
                    isValid = false;
                    NotificationManager.error('Reason is mandatory!', 'Failure!', 3000);
                }
                switch (rule.category) {
                    case 'Age':
                    case 'Total Charges':
                    case 'Units':
                    case 'Unit Charge':
                        switch (rule.condition) {
                            case 'is between':
                                if (rule.values?.length > 2) {
                                    isValid = false;
                                    NotificationManager.error('More than 2 values cannot be selected!', 'Failure!', 3000);
                                }
                                break;
                            case 'is above':
                            case 'is below':
                            case 'is equal to':
                            case 'is equal to or above':
                            case 'is equal to or below':
                                if (rule.values?.length > 1) {
                                    isValid = false;
                                    NotificationManager.error('More than 1 value cannot be selected!', 'Failure!', 3000);
                                }
                                break;
                            default:
                                break;
                        }
                        break;
                    case 'Date of Injury':
                    case 'Post Date':
                    case 'Service Date':
                        switch (rule.condition) {
                            case 'is between':
                                if (rule.values?.length > 2) {
                                    isValid = false;
                                    NotificationManager.error('More than 2 values cannot be selected!', 'Failure!', 3000);
                                }
                                break;
                            case 'is after':
                            case 'is before':
                            case 'is equal to':
                            case 'is equal to or after':
                            case 'is equal to or before':
                                if (rule.values?.length > 1) {
                                    isValid = false;
                                    NotificationManager.error('More than 1 value cannot be selected!', 'Failure!', 3000);
                                }
                                break;
                            default:
                                break;
                        }
                    default:
                        break;
                }
                return rule;
            })
        }
        return isValid;
    }

    // function to create a rule
    createRule = () => {
        if (this.isFormValid()) {
            let { rulesArray, ruleName, tags, optionArray, gender } = this.state;
            let ruleInfo = [];
            let values = [];
            let procedureValues = [];
            rulesArray.forEach((rule, idx) => {
                if (rule.textValuesArray && rule.textValuesArray.length > 0) {
                    values = [];
                    values = rule.textValuesArray
                    if (rule.procedureValueArray && rule.procedureValueArray.length > 0) {
                        procedureValues = [];
                        procedureValues = rule.procedureValueArray
                    }
                } else if (optionArray && optionArray.length > 0) {
                    values = [];
                    values = optionArray
                } else {
                    values = [];
                }
                let ruleData = {
                    ruleType: (rule.category === 'Modifier' ||
                        rule.category === 'Diagnosis 1' ||
                        rule.category === 'Diagnosis 2' ||
                        rule.category === 'Diagnosis 3' ||
                        rule.category === 'Diagnosis 4' ||
                        rule.category === 'Diagnosis in any slot' || rule.category === 'Procedure Code') ? "2" : "1",
                    category: rule.category,
                    condition: rule.condition,
                    values: rule.condition === 'is missing' ? [] : rule.category === 'Gender' ? rule.textValuesArray : values,
                    operator: (rule.operator).toLowerCase()
                }
                if (rule.category === 'Modifier' ||
                    rule.category === 'Diagnosis 1' ||
                    rule.category === 'Diagnosis 2' ||
                    rule.category === 'Diagnosis 3' ||
                    rule.category === 'Diagnosis 4' ||
                    rule.category === 'Diagnosis in any slot' ||
                    rule.category === 'Total Charges' ||
                    rule.category === 'Units' ||
                    rule.category === 'Unit Charge') {
                    ruleData.procedureTile = rule.procedureTileVal ? rule.procedureTileVal : 'any'
                }
                if (rule.category === 'Global Period') {
                    ruleData.days = rule.dayValue;
                    ruleData.globalDrop = rule.dayValue === '0' ? 'before & after' : rule.globalDropValue;
                    ruleData.procedureArray = procedureValues;
                }
                // console.log("ruleData", ruleData)
                if (ruleData.category !== undefined || ruleData.condition !== undefined) {
                    ruleInfo.push(ruleData)
                }
            })
            let length = rulesArray.length;
            let result = rulesArray[length - 1];
            let body_data = {
                ruleName: ruleName,
                gender: gender,
                ruleInfo: ruleInfo,
                result: result.pendValue,
                rpa_flow_Name: result.nestedThen.rpaNameValue,
                rpa_id: Number(result.nestedThen.rpaId),
                batch_status: result.nestedThen.batchToggle,
                batch_message: result.nestedThen.batchMsg,
                error_status: result.nestedThen.errorToggle,
                error_message: result.nestedThen.errorMsg,
                Reason: result.nestedThen.reason,
                tags: tags
            }
            console.log(body_data)
            API.post('createRule', body_data).then((response) => {
                NotificationManager.success('Rule created successfully!', 'Successful!', 3000);
                // console.log("response.data.data",response.data.data.practiceId);
                this.props.history.push(`manageRule/${this.state.isAllPracticeSelected ? '0' : '1'}`);
            }).catch(err => {
                NotificationManager.error('An error occured while creating the rule!', 'Failure!', 3000);
                console.error(err);
            })
        }
    }

    // Update Rule Api function
    updateRule = () => {
        if (this.isFormValid()) {
            let { ruleData, tags } = this.state;
            let ruleInfo = [];
            let values = [];
            let procedureValues = [];
            ruleData.ruleInfo.forEach((rule, idx) => {
                if (rule.values && rule.values.length > 0) {
                    values = [];
                    values = rule.values
                    if (rule.procedureArray && rule.procedureArray.length > 0) {
                        procedureValues = [];
                        procedureValues = rule.procedureArray
                    }
                } else {
                    values = [];
                }
                let ruleDataa = {
                    ruleType: (rule.category === 'Modifier' ||
                        rule.category === 'Diagnosis 1' ||
                        rule.category === 'Diagnosis 2' ||
                        rule.category === 'Diagnosis 3' ||
                        rule.category === 'Diagnosis 4' ||
                        rule.category === 'Diagnosis in any slot' || rule.category === 'Procedure Code') ? "2" : "1",
                    category: rule.category,
                    condition: rule.condition,
                    values: rule.condition === 'is missing' ? [] : values,
                    // values: values,
                    operator: (rule.operator)?.toLowerCase()
                }
                if (rule.category === 'Modifier' ||
                    rule.category === 'Diagnosis 1' ||
                    rule.category === 'Diagnosis 2' ||
                    rule.category === 'Diagnosis 3' ||
                    rule.category === 'Diagnosis 4' ||
                    rule.category === 'Diagnosis in any slot' ||
                    rule.category === 'Total Charges' ||
                    rule.category === 'Units' ||
                    rule.category === 'Unit Charge') {
                    ruleDataa.procedureTile = rule.procedureTile ? rule.procedureTile : 'any'
                }
                if (rule.category === 'Global Period') {
                    ruleDataa.days = rule.days;
                    ruleDataa.globalDrop = rule.days === '0' ? 'before & after' : rule.globalDrop;
                    ruleDataa.procedureArray = procedureValues;
                }
                // console.log("ruleData", ruleDataa)
                if (ruleDataa.category !== undefined || ruleDataa.condition !== undefined) {
                    ruleInfo.push(ruleDataa)
                }
            })
            // let length = ruleData.length;

            let body_data = {
                ruleName: ruleData.ruleName,
                ruleInfo: ruleInfo,
                result: ruleData.result,
                Reason: ruleData.Reason,
                tags: tags,
                parentRuleId: ruleData.parentRuleId,
                rpa_primary_id: ruleData.rpaInfo ? Number(ruleData.rpaInfo[0]?.id) : '',
                rpa_flow_Name: ruleData.rpaInfo ? ruleData.rpaInfo[0]?.rpa_flow_Name : '',
                rpa_id: ruleData.rpaInfo ? Number(ruleData.rpaInfo[0]?.rpa_id) : '',
                batch_status: ruleData.rpaInfo ? ruleData.rpaInfo[0]?.batch_status : '',
                batch_message: ruleData.rpaInfo ? ruleData.rpaInfo[0]?.batch_message : '',
                error_status: ruleData.rpaInfo ? ruleData.rpaInfo[0]?.error_status : '',
                error_message: ruleData.rpaInfo ? ruleData.rpaInfo[0]?.error_message : ''
            }
            console.log(body_data, "body_data")
            API.put('updateRule/' + this.state.ruleId, body_data).then((response) => {
                NotificationManager.success('Rule created successfully!', 'Successful!', 3000);
                // console.log(response.data);
                this.props.history.push('/manageRule');
            }).catch(err => {
                NotificationManager.error('An error occured while creating the rule!', 'Failure!', 3000);
                console.error(err);
            })
        }
    }

    // function to reset values
    reset = () => {
        // window.location.reload();
        this.setState({
            showCreatedRule: false,
            ruleName: '',
            rulesArray: [{
                category: "",
                condition: "",
                textValuesArray: [],
                operator: "",
                for: "and",
                show: 'none'
            }],
            optionArray: [],
            optionList: [],
            searchKey: '',
            tags: this.props.location.state ? this.props.location.state.ruleDetail[0].tags : [],
            ruleData: this.props.location.state ? getModifiedDetails(this.props.location.state.ruleDetail[0]) : {},
        });
    }

    // function to hide and show div
    // handleShow = (event) => {
    //     this.setState({ showRule: true});
    // }

    // handleHide = (event) => {
    //     this.setState({ showRule: !this.state.showRule });
    // }

    // function to get procedure tile numbers
    getProcedureTiles = () => {
        let rulesArrayCopy = [...this.state.rulesArray];
        let rulesInfoCopy = { ...this.state.ruleData };
        if (!this.state.editRule) {
            let procedureTileArray1 = [];
            rulesArrayCopy.map((rule, idx) => {
                if (rule.category === 'Procedure Code') {
                    procedureTileArray1.push(idx + 1)
                }
            })
            this.setState({ procedureTileArray: procedureTileArray1 })
        } else {
            let procedureTileArray2 = [];
            rulesInfoCopy.ruleInfo.map((rule, idx) => {
                if (rule.category === 'Procedure Code') {
                    procedureTileArray2.push(idx + 1)
                }
            })
            this.setState({ procedureTileArray: procedureTileArray2 })
        }
    }

    // function to handle procedure tile dropdown
    handleProcedureTileChange = (e, index) => {
        let { procedureTileArray } = this.state;
        const { value } = e.target;
        let rulesArrayCopy = [...this.state.rulesArray];
        let rulesInfoCopy = { ...this.state.ruleData };
        console.log(procedureTileArray)
        procedureTileArray.map(item => {
            if (!this.state.editRule) {
                rulesArrayCopy[index].procedureTileVal = value;
                this.setState({ ...this.state, rulesArray: rulesArrayCopy, showCreatedRule: true });
            } else {
                rulesInfoCopy.ruleInfo[index].procedureTile = value;
                this.setState({ ...this.state, ruleData: rulesInfoCopy, showCreatedRule: true });
            }
        })
    }

    // function to handle day change
    handleDayValue = (e, index) => {
        const { value } = e.target;
        let rulesArrayCopy = [...this.state.rulesArray];
        let rulesInfoCopy = { ...this.state.ruleData };
        if (!this.state.editRule) {
            rulesArrayCopy.map(item => {
                rulesArrayCopy[index].dayValue = value;
            })
            this.setState({ ...this.state, rulesArray: rulesArrayCopy, showCreatedRule: true })
        } else {
            rulesInfoCopy.ruleInfo.map(item => {
                rulesInfoCopy.ruleInfo[index].days = value;
            })
            this.setState({ ...this.state, ruleData: rulesInfoCopy, showCreatedRule: true })
        }
    }

    // function to handle global drop change
    handleGlobalDropValue = (e, index) => {
        const { value } = e.target;
        let rulesArrayCopy = [...this.state.rulesArray];
        let rulesInfoCopy = { ...this.state.ruleData };
        if (!this.state.editRule) {
            rulesArrayCopy.map(item => {
                rulesArrayCopy[index].globalDropValue = value;
            })
            this.setState({ ...this.state, rulesArray: rulesArrayCopy, showCreatedRule: true })
        } else {
            rulesInfoCopy.ruleInfo.map(item => {
                rulesInfoCopy.ruleInfo[index].globalDrop = value;
            })
            this.setState({ ...this.state, ruleData: rulesInfoCopy, showCreatedRule: true })
        }
    }

    // function to handle category change
    handleCategoryChange = (e, index) => {
        const { value } = e.target;
        let rulesArrayCopy = [...this.state.rulesArray];
        let rulesInfoCopy = { ...this.state.ruleData };

        categoryArray.map(item => {
            if (!this.state.editRule) {
                if (e.target.value === item.name) {
                    rulesArrayCopy[index].show = '';
                    rulesArrayCopy[index].condition = '';
                    // rulesArrayCopy[index].operator = '';
                    if (index === rulesArrayCopy.length - 1) {
                        rulesArrayCopy[index].operator = '';
                    } else {
                        rulesArrayCopy[index].operator = rulesArrayCopy[index].operator;
                    }
                }
                rulesArrayCopy[index].category = value;
                if (value === 'Global Period') {
                    rulesArrayCopy[index].condition = 'is';
                } else {
                    rulesArrayCopy[index].condition = '';
                }
                rulesArrayCopy[index].textValuesArray = [];
                this.setState({ ...this.state, rulesArray: rulesArrayCopy, showCreatedRule: true, optionList: [] });
            } else {
                if (e.target.value === item.name) {
                    rulesInfoCopy.ruleInfo[index].show = '';
                    rulesInfoCopy.ruleInfo[index].condition = '';
                    rulesInfoCopy.ruleInfo[index].values = [];
                } else {
                    rulesInfoCopy.ruleInfo[index].values = [];
                }
                rulesInfoCopy.ruleInfo[index].category = value;
                if (value === 'Global Period') {
                    rulesInfoCopy.ruleInfo[index].condition = 'is';
                } else {
                    rulesInfoCopy.ruleInfo[index].condition = '';
                }
                rulesInfoCopy.ruleInfo[index].values = [];
                this.setState({ ...this.state, ruleData: rulesInfoCopy, showCreatedRule: true, optionList: [] });
            }
            return item;
        })
        this.getProcedureTiles();
    }

    // function to search values in dropdown
    searchInDropdown = _.debounce((type, searchKey) => {
        API.get(`searchInDropDrown/${type}`, { params: { searchData: searchKey } }).then((response) => {
            let array = [];
            let searchOptions = response.data.data;
            // console.log(response.data)
            switch (type) {
                case 'Diagnosis 1':
                case 'Diagnosis 2':
                case 'Diagnosis 3':
                case 'Diagnosis 4':
                case 'Diagnosis in any slot':
                    // searchOptions = searchOptions.map(f => f.type === 'group' ? f.dataValues.groupName : f.type === 'individualCode' ? (f.dataValues.diagnosisCodes + ' - ' + f.dataValues.diseaseName) : 
                    // (!f.type && f.diagnosisCodes) ? f.diagnosisCodes + ' - ' + f.diseaseName : (!f.type && f.groupName) ? f.groupName : null);
                    searchOptions.map(f => {
                        if (f.type === 'group') {
                            array.push({ id: f.dataValues.id, name: f.dataValues.groupName, type: f.type })
                        } else if (f.type === 'individualCode') {
                            array.push({ id: f.dataValues.id, name: f.dataValues.diagnosisCodes + ' - ' + f.dataValues.diseaseName, type: f.type })
                        } else if (!f.type && f.diagnosisCodes) {
                            array.push({ id: f.id, name: f.diagnosisCodes + ' - ' + f.diseaseName, type: 'individualCode' })
                        } else {
                            array.push({ id: f.id, name: f.groupName, type: 'group' })
                        }
                        return f;
                    });
                    break;
                case 'Procedure Code':
                case 'Global Period':
                    // searchOptions = searchOptions.map(f => f.type === 'group' ? f.dataValues.groupName : f.type === 'individualCode' ? (f.dataValues.procedureCodes + ' - ' + f.dataValues.diseaseName) : 
                    // (!f.type && f.procedureCodes) ? f.procedureCodes + ' - ' + f.diseaseName : (!f.type && f.groupName) ? f.groupName : null);
                    searchOptions.map(f => {
                        if (f.type === 'group') {
                            array.push({ id: f.dataValues.id, name: f.dataValues.groupName, type: f.type })
                        } else if (f.type === 'individualCode') {
                            array.push({ id: f.dataValues.id, name: f.dataValues.procedureCodes + ' - ' + f.dataValues.diseaseName, type: f.type })
                        } else if (!f.type && f.procedureCodes) {
                            array.push({ id: f.id, name: f.procedureCodes + ' - ' + f.diseaseName, type: 'individualCode' })
                        } else {
                            array.push({ id: f.id, name: f.groupName, type: 'group' })
                        }
                        return f;
                    });
                    break;
                case 'Modifier':
                    // searchOptions = searchOptions.map(f => f.type === 'group' ? f.dataValues.groupName : f.type === 'individualCode' ? f.dataValues.modifierCode : 
                    // (!f.type && f.modifierCode) ? f.modifierCode : (!f.type && f.groupName) ? f.groupName : null);
                    searchOptions.map(f => {
                        if (f.type === 'group') {
                            array.push({ id: f.dataValues.id, name: f.dataValues.groupName, type: f.type })
                        } else if (f.type === 'individualCode') {
                            array.push({ id: f.dataValues.id, name: f.dataValues.modifierCode, type: f.type })
                        } else if (!f.type && f.modifierCode) {
                            array.push({ id: f.id, name: f.modifierCode, type: 'individualCode' })
                        } else {
                            array.push({ id: f.id, name: f.groupName, type: 'group' })
                        }
                        return f;
                    });
                    break;
                case 'Place of Service Code':
                    // searchOptions = searchOptions.map(f => f.placeOfServiceCode);
                    searchOptions.map(f => {
                        array.push({ id: f.id, name: f.placeOfServiceCode });
                        return f;
                    })
                    break;
                case 'Scheduling Provider':
                case 'Rendering Provider':
                case 'Referring Provider':
                    // searchOptions = searchOptions.map(f => f.providerName);
                    searchOptions.map(f => {
                        array.push({ id: f.id, name: f.providerName, type: 'provider' });
                        return f;
                    })
                    break;
                case 'Place of Service':
                    // searchOptions = searchOptions.map(f => f.serviceLocationName);
                    searchOptions.map(f => {
                        array.push({ id: f.id, name: f.serviceLocationName, type: 'location' });
                        return f;
                    })
                    break;
                case 'Primary Insurance Plan':
                    // searchOptions = searchOptions.map(f => f);
                    searchOptions.map(f => {
                        array.push({ name: f, type: 'insurance' });
                        return f;
                    })
                    break;
                case 'Secondary Insurance Plan':
                    // searchOptions = searchOptions.map(f => f);
                    searchOptions.map(f => {
                        array.push({ name: f, type: 'insurance' });
                        return f;
                    })
                    break;
                case 'Trizetto Payer ID':
                    searchOptions.map(f => {
                        array.push({ name: f });
                        return f;
                    })
                default:
                    break;
            }
            this.setState({
                // optionList: array.length > 0 ? array : searchOptions,
                optionList: array,
                mPlaceholder: (!searchOptions.length || searchOptions.length === 0) ? 'No options' : 'Type something to search...'
            })
        }).catch(err => {
            console.error(err);
            // this.setState({mPlaceholder: 'no result'})
        })
    }, 1000)
    searchValues = (type, value) => {
        this.setState({ optionList: [] });
        this.setState({ searchKey: value });
        if (value.length > 0) {
            this.searchInDropdown(type, value);
        }
    }

    clearSearch = () => {
        this.setState({ optionList: [] })
    }

    // function to handle condition change
    handleConditionChange = (e) => {
        const { name, value } = e.target;
        let [, index] = name.split('-');
        let rulesArrayCopy = [...this.state.rulesArray];
        let rulesInfoCopy = { ...this.state.ruleData };

        index = parseInt(index, 10);

        if (!this.state.editRule) {
            switch (rulesArrayCopy[index].category) {
                case 'Patient Name':
                    rulesArrayCopy[index].show = ["is missing"].includes(e.target.value) ? "" : ["is", "is not", "contains", "does not contain"].includes(e.target.value) ? "TEXT" : "DROP";
                    break;
                case 'Age':
                case 'Total Charges':
                case 'Units':
                case 'Unit Charge':
                    rulesArrayCopy[index].show = ["is missing"].includes(e.target.value) ? "" : ["is above", "is below", "is equal to", "is not equal to", "is between", "is not between", "is equal to or above", "is equal to or below"].includes(e.target.value) ? "TEXT" : "DROP";
                    break;
                case 'Date of Injury':
                case 'Post Date':
                case 'Service Date':
                    rulesArrayCopy[index].show = ["is missing"].includes(e.target.value) ? "" : ["is after", "is before", "is equal to", "is not equal to", "is between", "is not between", "is equal to or after", "is equal to or before"].includes(e.target.value) ? "TEXT" : "DROP";
                    break;
                default:
                    rulesArrayCopy[index].show = ["is missing"].includes(e.target.value) ? "" : ["contains", "does not contain"].includes(e.target.value) ? "TEXT" : "DROP";
                    break;
            }
            rulesArrayCopy[index].condition = value;
            if (value !== "is" && value !== "is not") {
                rulesArrayCopy[index].textValuesArray = [];
            }
            this.setState({ ...this.state, rulesArray: rulesArrayCopy })
        } else {
            switch (rulesInfoCopy.ruleInfo[index].category) {
                case 'Patient Name':
                    rulesInfoCopy.ruleInfo[index].show = ["is missing"].includes(e.target.value) ? "" : ["is", "is not", "contains", "does not contain"].includes(e.target.value) ? "TEXT" : "DROP";
                    break;
                case 'Age':
                case 'Total Charges':
                case 'Units':
                case 'Unit Charge':
                    rulesInfoCopy.ruleInfo[index].show = ["is missing"].includes(e.target.value) ? "" : ["is above", "is below", "is equal to", "is between", "is equal to or above", "is equal to or below"].includes(e.target.value) ? "TEXT" : "DROP";
                    break;
                case 'Date of Injury':
                case 'Post Date':
                case 'Service Date':
                    rulesInfoCopy.ruleInfo[index].show = ["is missing"].includes(e.target.value) ? "" : ["is after", "is before", "is equal to", "is between", "is equal to or after", "is equal to or before"].includes(e.target.value) ? "TEXT" : "DROP";
                    break;
                default:
                    rulesInfoCopy.ruleInfo[index].show = ["is missing"].includes(e.target.value) ? "" : ["contains", "does not contain"].includes(e.target.value) ? "TEXT" : "DROP";
                    break;
            }
            rulesInfoCopy.ruleInfo[index].condition = value;
            if (value !== "is" && value !== "is not") {
                rulesInfoCopy.ruleInfo[index].values = [];
            }
            this.setState({ ...this.state, ruleData: rulesInfoCopy })
        }
    }

    // function to handle chips on enter
    // handleOnEnter = (val) => {
    //     const { ruleData, editRule } = this.state;
    //     if (val.chips[0] !== '') {
    //         if (editRule) {
    //             let rulesInfoCopy = { ...ruleData };
    //             rulesInfoCopy.ruleInfo[val.index].values.push(val.chips[0]);
    //             switch (rulesInfoCopy.ruleInfo[val.index].category) {
    //                 case 'Age':
    //                 case 'Date of Injury':
    //                 case 'Post Date':
    //                 case 'Service Date':
    //                     switch (rulesInfoCopy.ruleInfo[val.index].condition) {
    //                         case 'is between':
    //                             if (rulesInfoCopy.ruleInfo[val.index].values.length > 2) {
    //                                 NotificationManager.error('More than 2 values cannot be selected!', 'Failure!', 3000);
    //                             }
    //                             break;
    //                         case 'is above':
    //                         case 'is below':
    //                         case 'is equal to':
    //                         case 'is equal to or above':
    //                         case 'is equal to or below':
    //                             if (rulesInfoCopy.ruleInfo[val.index].values.length > 1) {
    //                                 NotificationManager.error('More than 1 value cannot be selected!', 'Failure!', 3000);
    //                             }
    //                             break;
    //                         default:
    //                             break;
    //                     }
    //                 default:
    //                     break;
    //             }
    //             this.setState({ ...this.state, ruleData: rulesInfoCopy })
    //         } else {
    //             let rulesArrayCopy = [...this.state.rulesArray];
    //             rulesArrayCopy[val.index].textValuesArray = val.chips;
    //             switch (rulesArrayCopy[val.index].category) {
    //                 case 'Age':
    //                 case 'Date of Injury':
    //                 case 'Post Date':
    //                 case 'Service Date':
    //                     switch (rulesArrayCopy[val.index].condition) {
    //                         case 'is between':
    //                             if (rulesArrayCopy[val.index].textValuesArray.length > 2) {
    //                                 NotificationManager.error('More than 2 values cannot be selected!', 'Failure!', 3000);
    //                             }
    //                             break;
    //                         case 'is above':
    //                         case 'is below':
    //                         case 'is equal to':
    //                         case 'is equal to or above':
    //                         case 'is equal to or below':
    //                             if (rulesArrayCopy[val.index].textValuesArray.length > 1) {
    //                                 NotificationManager.error('More than 1 value cannot be selected!', 'Failure!', 3000);
    //                             }
    //                             break;
    //                         default:
    //                             break;
    //                     }
    //                 default:
    //                     break;
    //             }
    //             this.setState({ ...this.state, rulesArray: rulesArrayCopy })
    //         }
    //     }
    // }

    handleOnEnter = (val) => {
        const { ruleData, editRule } = this.state;
        const re = /^[0-9\b]+$/;
        if (val.chips[0] !== '') {
            if (editRule) {
                let rulesInfoCopy = { ...ruleData };
                let category = rulesInfoCopy.ruleInfo[val.index].category;
                let dateChips = val.chips.map(item => this.maskDate(item));
                if (category === 'Date of Injury' || category === 'Service Date') {
                    rulesInfoCopy.ruleInfo[val.index].values.push(dateChips);
                } else {
                    rulesInfoCopy.ruleInfo[val.index].values.push(val.chips[0]);
                }
                // rulesInfoCopy.ruleInfo[val.index].values.push(val.chips[0]);
                switch (rulesInfoCopy.ruleInfo[val.index].category) {
                    case 'Age':
                    case 'Total Charges':
                    case 'Units':
                    case 'Unit Charge':
                        switch (rulesInfoCopy.ruleInfo[val.index].condition) {
                            case 'is between':
                            case 'is not between':
                                if((rulesInfoCopy.ruleInfo[val.index].values).some(isNaN)){
                                    NotificationManager.error('Value must be a whole number!', 'Failure!', 3000);
                                }
                                if (rulesInfoCopy.ruleInfo[val.index].values.length > 2) {
                                    NotificationManager.error('More than 2 values cannot be selected!', 'Failure!', 3000);
                                    rulesInfoCopy.ruleInfo[val.index].values.pop();
                                }
                                break;
                            case 'is above':
                            case 'is below':
                            case 'is equal to':
                            case 'is not equal to':
                            case 'is equal to or above':
                            case 'is equal to or below':
                                // if (!re.test(rulesInfoCopy.ruleInfo[val.index].values)) {
                                if((rulesInfoCopy.ruleInfo[val.index].values).some(isNaN)){
                                    NotificationManager.error('Value must be a whole number!', 'Failure!', 3000);
                                }
                                if (rulesInfoCopy.ruleInfo[val.index].values.length > 1) {
                                    NotificationManager.error('More than 1 value cannot be selected!', 'Failure!', 3000);
                                    rulesInfoCopy.ruleInfo[val.index].values.pop();
                                }
                                break;
                            default:
                                break;
                        }
                        break;
                    case 'Date of Injury':
                    case 'Post Date':
                    case 'Service Date':
                        switch (rulesInfoCopy.ruleInfo[val.index].condition) {
                            case 'is between':
                            case 'is not between':
                                if (rulesInfoCopy.ruleInfo[val.index].values.length > 2) {
                                    NotificationManager.error('More than 2 values cannot be selected!', 'Failure!', 3000);
                                    rulesInfoCopy.ruleInfo[val.index].values.pop();
                                }
                                break;
                            case 'is after':
                            case 'is before':
                            case 'is equal to':
                            case 'is not equal to':
                            case 'is equal to or after':
                            case 'is equal to or before':
                                if (rulesInfoCopy.ruleInfo[val.index].values.length > 1) {
                                    NotificationManager.error('More than 1 value cannot be selected!', 'Failure!', 3000);
                                    rulesInfoCopy.ruleInfo[val.index].values.pop();
                                }
                                break;
                            default:
                                break;
                        }
                        break;
                    default:
                        break;
                }
                this.setState({ ...this.state, ruleData: rulesInfoCopy })
            } else {
                let rulesArrayCopy = [...this.state.rulesArray];
                let category = rulesArrayCopy[val.index].category;
                // rulesArrayCopy[val.index].textValuesArray = val.chips;
                let dateChips = val.chips.map(item => this.maskDate(item))
                rulesArrayCopy[val.index].textValuesArray = (category === 'Date of Injury' || category === 'Service Date') ? dateChips : val.chips;
                switch (rulesArrayCopy[val.index].category) {
                    case 'Age':
                    case 'Total Charges':
                    case 'Units':
                    case 'Unit Charge':
                        switch (rulesArrayCopy[val.index].condition) {
                            case 'is between':
                            case 'is not between':
                                // if (!re.test(rulesArrayCopy[val.index].textValuesArray)) {
                                //     console.log(rulesArrayCopy[val.index].textValuesArray)
                                //     NotificationManager.error('Value must be a whole number!', 'Failure!', 3000);
                                // }
                                if ((rulesArrayCopy[val.index].textValuesArray).some(isNaN)) {
                                    // console.log(rulesArrayCopy[val.index].textValuesArray, !(rulesArrayCopy[val.index].textValuesArray).some(isNaN))
                                    NotificationManager.error('Value must be a whole number!', 'Failure!', 3000);
                                }
                                if (rulesArrayCopy[val.index].textValuesArray.length > 2) {
                                    NotificationManager.error('More than 2 values cannot be selected!', 'Failure!', 3000);
                                    rulesArrayCopy[val.index].textValuesArray.pop()
                                }
                                break;
                            case 'is above':
                            case 'is below':
                            case 'is equal to':
                            case 'is not equal to':
                            case 'is equal to or above':
                            case 'is equal to or below':
                                // if (!re.test(rulesArrayCopy[val.index].textValuesArray)) {
                                if ((rulesArrayCopy[val.index].textValuesArray).some(isNaN)) {
                                    NotificationManager.error('Value must be a whole number!', 'Failure!', 3000);
                                }
                                if (rulesArrayCopy[val.index].textValuesArray.length > 1) {
                                    NotificationManager.error('More than 1 value cannot be selected!', 'Failure!', 3000);
                                    rulesArrayCopy[val.index].textValuesArray.pop()
                                }
                                break;
                            default:
                                break;
                        }
                        break;
                    case 'Date of Injury':
                    case 'Post Date':
                    case 'Service Date':
                        switch (rulesArrayCopy[val.index].condition) {
                            case 'is between':
                            case 'is not between':
                                if (!this.datechecker(rulesArrayCopy[val.index].textValuesArray)) {
                                    NotificationManager.error('Please Enter Valid Date Like MM/DD/YYYY');
                                }
                                if (rulesArrayCopy[val.index].textValuesArray.length > 2) {
                                    NotificationManager.error('More than 2 values cannot be selected!', 'Failure!', 3000);
                                    rulesArrayCopy[val.index].textValuesArray.pop()
                                }
                                break;
                            case 'is after':
                            case 'is before':
                            case 'is equal to':
                            case 'is not equal to':
                            case 'is equal to or after':
                            case 'is equal to or before':
                                if (!this.datechecker(rulesArrayCopy[val.index].textValuesArray)) {
                                    NotificationManager.error('Please Enter Valid Date Like MM/DD/YYYY');
                                }
                                if (rulesArrayCopy[val.index].textValuesArray.length > 1) {
                                    NotificationManager.error('More than 1 value cannot be selected!', 'Failure!', 3000);
                                    rulesArrayCopy[val.index].textValuesArray.pop()
                                }
                                break;
                            default:
                                break;
                        }
                        break;
                    default:
                        break;
                }
                this.setState({ ...this.state, rulesArray: rulesArrayCopy })
            }
        }
    }

    datechecker = (dt) => {
        console.log(dt)
        var reGoodDate = /^((0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{4})*$/;
        return reGoodDate.test(dt);
    }
    maskDate = value => {
        // console.log(value)
        let v = value.replace(/\D/g, '').slice(0, 10);
        if (v.length >= 5) {
            return `${v.slice(0, 2)}/${v.slice(2, 4)}/${v.slice(4)}`;
        }
        else if (v.length >= 3) {
            return `${v.slice(0, 2)}/${v.slice(2)}`;
        }
        return v
    }

    // function to remove tags as chips
    deleteChipEdit = (valindex, index) => {
        console.log(valindex, "ABS", index)
        let ruleDataCopy = { ...this.state.ruleData }
        ruleDataCopy.ruleInfo[index].values.splice(valindex, 1);
        // ruleDataCopy.ruleInfo[index].values = val.chips;
        this.setState({ ...this.state, ruleData: ruleDataCopy })
    }

    // function to add and remove tags as chips
    addChip = value => {
        // console.log(value, "value")
        if (value !== "" && value !== undefined) {
            this.setState({ tags: [...this.state.tags, value] });
        }
    };

    removeChip = index => {
        const chips = this.state.tags.slice();
        chips.splice(index, 1);
        this.setState({ tags: chips });
    };

    // function to add dynamic tiles
    onChange = (e) => {
        let { value, name } = e.target;
        let [type, index] = name.split("-");
        index = parseInt(index);
        if (!this.state.editRule) {
            let rulesArrayCopy = [...this.state.rulesArray];

            if (type === "rule" && index < rulesArrayCopy.length - 1) {
                const preVal = rulesArrayCopy[index + 1].operator;
                rulesArrayCopy[index + 1] = value === "and" ? { ...andObject } : { ...thenObject };
                rulesArrayCopy[index + 1].operator = preVal;
                rulesArrayCopy[index].operator = value;
                rulesArrayCopy = rulesArrayCopy.slice(0, index + 2);
            } else if (type === "cat") {
                rulesArrayCopy[index].pendValue = value;
                rulesArrayCopy[index].nestedThen.reason = '';
            } else if (value === "and") {
                rulesArrayCopy[index].operator = value;
                rulesArrayCopy.push({ ...andObject });
            } else if (value === "then") {
                rulesArrayCopy[index].operator = value;
                rulesArrayCopy.push({ ...thenObject });
            }
            this.setState({ ...this.state, rulesArray: rulesArrayCopy });
        } else {
            // console.log("ruleData", this.state.ruleData, index, value, type)
            let ruleDataCopy = { ...this.state.ruleData };
            // console.log("ruleDataCopy", ruleDataCopy);

            if (type === 'rule' && index < ruleDataCopy.ruleInfo.length - 1) {
                // console.log('inside first if')
                // console.log("RULE DATA COPY BEFORE", ruleDataCopy)
                const preVal = ruleDataCopy.ruleInfo[index + 1].operator;
                ruleDataCopy.ruleInfo[index + 1] = value === 'and' ? { ...andObject } : { ...thenObject };
                ruleDataCopy.ruleInfo[index + 1].operator = preVal;
                ruleDataCopy.ruleInfo[index].operator = value;
                ruleDataCopy.ruleInfo = ruleDataCopy.ruleInfo.slice(0, index + 2);
                // console.log("RULE DATA COPY", ruleDataCopy)
            } else if (type === "cat") {
                ruleDataCopy.result = value;
            } else if (value === "and") {
                ruleDataCopy.ruleInfo[index].operator = value;
                ruleDataCopy.ruleInfo.push({ ...andObject });
                ruleDataCopy.Reason = '';
                ruleDataCopy.result = '';
                ruleDataCopy.ruleInfo[index + 1].operator = '';
            } else if (value === "then") {
                ruleDataCopy.ruleInfo[index].operator = value;
                ruleDataCopy.ruleInfo.push({ ...thenObject });
                ruleDataCopy.Reason = '';
                ruleDataCopy.result = '';
                ruleDataCopy.ruleInfo[index + 1].operator = '';
            }
            this.setState({ ...this.state, ruleData: ruleDataCopy });
        }
    }

    // function to handle textarea change
    handleChange = (e, index) => {
        const { ruleData } = this.state;
        if (this.state.editRule) {
            if (e.target.name === 'txt') {
                ruleData.Reason = e.target.value;
            }
            this.setState({ [e.target.name]: e.target.value });
        } else {
            let { name, value } = e.target;
            let rulesArrayCopy = [...this.state.rulesArray];
            if (name === 'txt') {
                rulesArrayCopy[index].nestedThen.reason = value;
            }
            this.setState({ rulesArray: rulesArrayCopy, [name]: value });
        }
    }

    // function to handle gender change
    handleGender = (e, index) => {
        const { ruleData } = this.state;
        if (this.state.editRule) {
            if (e.target.name === 'gender') {

            }
            this.setState({ [e.target.name]: e.target.value });
        } else {
            let { value } = e.target;
            let rulesArrayCopy = [...this.state.rulesArray];
            console.log(index, rulesArrayCopy)
            rulesArrayCopy[index].textValuesArray = [value];
            this.setState({ rulesArray: rulesArrayCopy });
        }
    }

    // function to add a tile on clicking plus
    // handlePlus = (index) => {
    //     // console.log(index, "Index")
    //     if (!this.state.editRule) {
    //         let rulesArrayCopy = [...this.state.rulesArray];
    //         if (rulesArrayCopy[index].operator === 'then') {
    //             rulesArrayCopy.splice(index + 1, 0, {
    //                 for: 'and',
    //                 category: '',
    //                 condition: '',
    //                 operator: 'then',
    //                 show: 'none',
    //                 textValuesArray: []
    //             });
    //             rulesArrayCopy[index].operator = 'and';
    //         } else {
    //             rulesArrayCopy.splice(index + 1, 0, {
    //                 for: 'and',
    //                 category: '',
    //                 condition: '',
    //                 operator: 'and',
    //                 show: 'none',
    //                 textValuesArray: []
    //             });
    //         }
    //         this.setState({ rulesArray: rulesArrayCopy });
    //     } else {
    //         let ruleDataCopy = { ...this.state.ruleData }
    //         if (ruleDataCopy.ruleInfo[index].operator === 'then') {
    //             ruleDataCopy.ruleInfo.splice(index + 1, 0, {
    //                 for: 'and',
    //                 category: '',
    //                 condition: '',
    //                 operator: 'then',
    //                 show: 'none',
    //                 textValuesArray: []
    //             });
    //             ruleDataCopy.ruleInfo[index].operator = 'and';
    //         } else {
    //             ruleDataCopy.ruleInfo.splice(index + 1, 0, {
    //                 for: 'and',
    //                 category: '',
    //                 condition: '',
    //                 operator: 'and',
    //                 show: 'none',
    //                 textValuesArray: []
    //             });
    //         }
    //         this.setState({ ruleData: ruleDataCopy });
    //     }
    // }
    handlePlus = (index) => {
        if (!this.state.editRule) {
            let rulesArrayCopy = [...this.state.rulesArray];
            if (rulesArrayCopy[index].operator === 'then') {
                rulesArrayCopy.splice(index + 1, 0, {
                    for: 'and',
                    category: '',
                    condition: '',
                    operator: 'then',
                    show: 'none',
                    textValuesArray: []
                });
                rulesArrayCopy[index].operator = 'and';
            } else {
                if (index === rulesArrayCopy.length - 1) {
                    rulesArrayCopy.splice(index + 1, 0, {
                        for: 'and',
                        category: '',
                        condition: '',
                        operator: '',
                        show: 'none',
                        textValuesArray: []
                    });
                } else {
                    rulesArrayCopy.splice(index + 1, 0, {
                        for: 'and',
                        category: '',
                        condition: '',
                        operator: 'and',
                        show: 'none',
                        textValuesArray: []
                    });
                }
                rulesArrayCopy[index].operator = 'and';
            }
            this.setState({ rulesArray: rulesArrayCopy });
        } else {
            let ruleDataCopy = { ...this.state.ruleData }
            if (ruleDataCopy.ruleInfo[index].operator === 'then') {
                ruleDataCopy.ruleInfo.splice(index + 1, 0, {
                    for: 'and',
                    category: '',
                    condition: '',
                    operator: 'then',
                    show: 'none',
                    textValuesArray: []
                });
                ruleDataCopy.ruleInfo[index].operator = 'and';
            } else {
                ruleDataCopy.ruleInfo.splice(index + 1, 0, {
                    for: 'and',
                    category: '',
                    condition: '',
                    operator: 'and',
                    show: 'none',
                    textValuesArray: []
                });
            }
            this.setState({ ruleData: ruleDataCopy });
        }
    }

    // function to delete tile on clicking minus
    // handleMinus = (index) => {
    //     if (!this.state.editRule) {
    //         let rulesArrayCopy = [...this.state.rulesArray];
    //         if (index === rulesArrayCopy.length - 2) {
    //             if (rulesArrayCopy[index + 1].for === 'then') {
    //                 rulesArrayCopy[index - 1].operator = 'then';
    //             }
    //             rulesArrayCopy.splice(index, 1);
    //         } else {
    //             rulesArrayCopy.splice(index, 1);
    //         }
    //         this.setState({ rulesArray: rulesArrayCopy });
    //     } else {
    //         let ruleDataCopy = { ...this.state.ruleData }
    //         if (index === ruleDataCopy.ruleInfo.length - 2) {
    //             if (ruleDataCopy.ruleInfo[index + 1].for === 'then') {
    //                 ruleDataCopy.ruleInfo[index - 1].operator = 'then';
    //             }
    //             ruleDataCopy.ruleInfo.splice(index, 1);
    //         } else {
    //             ruleDataCopy.ruleInfo.splice(index, 1);
    //         }
    //         this.setState({ ruleData: ruleDataCopy });
    //     }

    // }
    handleMinus = (index) => {
        if (!this.state.editRule) {
            let rulesArrayCopy = [...this.state.rulesArray];
            if (index === rulesArrayCopy.length - 2) {
                if (rulesArrayCopy[index + 1].for === 'then') {
                    rulesArrayCopy[index - 1].operator = 'then';
                }
                rulesArrayCopy.splice(index, 1);
            } else {
                rulesArrayCopy.splice(index, 1);
            }
            this.setState({ rulesArray: rulesArrayCopy }, () => this.getProcedureTiles());
        } else {
            let ruleDataCopy = { ...this.state.ruleData }
            if (index === ruleDataCopy.ruleInfo.length - 2) {
                if (ruleDataCopy.ruleInfo[index + 1].for === 'then') {
                    ruleDataCopy.ruleInfo[index - 1].operator = 'then';
                }
                ruleDataCopy.ruleInfo.splice(index, 1);
            } else {
                ruleDataCopy.ruleInfo.splice(index, 1);
            }
            this.setState({ ruleData: ruleDataCopy }, () => this.getProcedureTiles());
        }
    }

    // function to remove then object
    handleThen = (index) => {
        if (!this.state.editRule) {
            let rulesArrayCopy = [...this.state.rulesArray];
            rulesArrayCopy.splice(index, 1);
            rulesArrayCopy[index - 1].operator = 'none';
            this.setState({ rulesArray: rulesArrayCopy });
        } else {
            let ruleDataCopy = { ...this.state.ruleData };
            // console.log("ruleDataCopy", ruleDataCopy);
            ruleDataCopy.ruleInfo.splice(index, 1);
            ruleDataCopy.ruleInfo[index - 1].operator = 'none';
            this.setState({ ruleData: ruleDataCopy });
        }
    }

    // function to handle rule name
    handleRuleInput = (e) => {
        const { ruleData } = this.state;
        if (this.state.editRule) {
            if (e.target.name === 'ruleName') {
                ruleData.ruleName = e.target.value;
            }
            this.setState({ [e.target.name]: e.target.value })
        } else {
            const target = e.target;
            const name = target.name;
            const value = target.value;
            this.setState({ [name]: value });
        }
    }

    // function to get list of diagnosis codes
    getDiagnosisCodes = () => {
        API.get('getdiagnosisCodesList').then((response) => {
            // let diagnosisList = response.data.data.splice(0,10);
            let diagnosisList = response.data.data;
            diagnosisList = diagnosisList.map(f => f.diagnosisCodes + ' - ' + f.diseaseName)
            this.setState({ optionList: diagnosisList });
        }).catch(err => {
            console.error(err);
        })
    }

    // function to get list of place of service codes
    getPlaceOfServiceCodes = () => {
        API.get('getPlaceOfServiceCode').then((response) => {
            let placeOfServiceList = response.data.data;
            placeOfServiceList = placeOfServiceList.map(f => f);
            this.setState({ optionList: placeOfServiceList })
        }).catch(err => {
            console.error(err);
        })
    }

    // function to get list of procedure codes
    getProcedureCodes = () => {
        API.get('getProcedureCodesList').then((response) => {
            let procedureList = response.data.data;
            procedureList = procedureList.map(f => f.procedureCodes + ' - ' + f.diseaseName)
            this.setState({ optionList: procedureList });
        }).catch(err => {
            console.error(err);
        })
    }
    // function to get list of modifiers
    getModifiers = () => {
        API.get('getModifiersList').then((response) => {
            let modifiersList = response.data.data;
            modifiersList = modifiersList.map(f => f);
            this.setState({ optionList: modifiersList });
        }).catch(err => {
            console.error(err);
        })
    }

    // function to get list of all providers
    getProviders = () => {
        API.post('getAllProviders').then((response) => {
            let providers = response.data.data;
            providers = providers.map(f => f.providerName)
            this.setState({ optionList: providers });
        }).catch(err => {
            console.error(err);
        })
    }

    // function to get all service locations
    getServiceLocations = () => {
        API.post('getAllServiceLocations').then((response) => {
            let serviceLocations = response.data.data;
            serviceLocations = serviceLocations.map(f => f.serviceLocationName)
            this.setState({ optionList: serviceLocations });
        }).catch(err => {
            console.error(err)
        })
    }

    // function to get list of primary isurance plans
    getInsurancePlans = () => {
        API.get('getInsurancePlanList').then((response) => {
            let primaryInsurancePlans = response.data.primaryInsurance;
            primaryInsurancePlans = primaryInsurancePlans.map(f => f);
            this.setState({ optionList: primaryInsurancePlans });
        }).catch(err => {
            console.error(err);
        })
    }

    // function to get list of secondary isurance plans
    getInsurancePlans = () => {
        API.get('getInsurancePlanList').then((response) => {
            let secondaryInsurancePlans = response.data.secondaryInsurance;
            secondaryInsurancePlans = secondaryInsurancePlans.map(f => f);
            this.setState({ optionList: secondaryInsurancePlans });
        }).catch(err => {
            console.error(err);
        })
    }

    // function to get list of all practices
    getPractices = () => {
        API.get("allPractices")
            .then((response) => {
                let practices = response.data.data;
                this.setState({ practices: practices });
            })
            .catch((err) => {
                console.error(err);
            });
    };

    // function to open create rule modal
    handleModel = () => {
        this.setState({ showCreateRuleModal: false });
    };
    getModelOnCreateRule = () => {
        if (this.props.location.pathname === "/create-rule") {
            this.setState({ showCreateRuleModal: true });
        }
    };
    componentDidMount() {
        this.getModelOnCreateRule();
        this.getProcedureTiles();
        this.getCustomRPA();
        this.getGlobalRPA();
    }

    render() {
        const { rulesArray, ruleName, showCreatedRule, ruleData, editRule } = this.state;
        let practiceData = JSON.parse(sessionStorage.getItem("practice"));

        // console.log(practiceData, "practiceData==>")
        // console.log(this.state.rulesArray, "this.state.rulesArray")
        const headerStyle = {
            backgroundColor: '#24476A',
            height: 55,
            borderRadius: '9px'
        }
        const showStyle = {
            width: '100%',
            height: '100%',   // 294
            overflow: 'hidden',
            // height: 'calc(100vh - 165px)',    // 587
            background: 'linear-gradient(196.38deg, #81C3D7 13.47%, #81C3D7 38.72%, #3A7CA5 92.18%)',
            border: '1px solid #878787',
            borderRadius: '9px',
        }
        const header = {
            backgroundColor: '#24476A',
            height: 110,
            width: '100%',     //294
            marginLeft: 0,
            borderRadius: '9px 9px 0px 0px',
            // position: 'absolute'
        }
        const buttonStyle = {
            backgroundColor: '#24476A',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            borderRadius: '9px'
        }
        const ruleTextStyle = {
            fontSize: 18,
            fontStyle: 'normal',
            color: '#000000',
            fontWeight: 400
        }
        const ruleInputStyle = {
            // width: '330px',     //233
            width: '100%',
            maxWidth: '226px',
            height: '24px',     //18
            // height: '35px',     //18
            background: '#FFFFFF',
            borderRadius: '9px',
            marginLeft: 6, border: '1px solid #9A9A9A'
        }

        let roleData = JSON.parse(sessionStorage?.getItem('userDetail'))

        return (
            <section>
                <Sidebar
                    defaultDisabledValue={this.props.location?.state?.ruleDetail?.practiceId}
                    handleChangePractice={(practice) => {
                        // this.reset();
                        if (practice === 'all') {
                            this.setState({ isAllPracticeSelected: true })
                        } else this.setState({ isAllPracticeSelected: false })
                    }}
                    customSetPractice={(customSetPractice) => {
                        this.setState({ customSetPractice });
                    }}
                    path={this.props.location.state?.editRule ? this.props.location.state?.path : null}
                    hasAllPracticeOption={roleData.roleId === 3 ? false : true} />

                <RoAlert
                    show={this.state.showCreateRuleModal}
                    handleClose={() => this.setState({ showCreateRuleModal: false })}
                    handleSubmit={() => this.handleModel()}
                    title={"Create Rule?"}
                    type="info"
                    content={
                        <div className="alert-footer-text text-center">
                            Which practice would you like to create the rule for?
                            <br />
                            <PracticeSelect
                                hasAllPracticeOption={roleData.roleId === 3 ? false : true}
                                handleChangePractice={(practice) => {
                                    this.state.customSetPractice(
                                        practice.id ? practice.id : practice
                                    );
                                    if (practice === "all") {
                                        this.setState({ isAllPracticeSelected: true });
                                    } else this.setState({ isAllPracticeSelected: false });
                                }}
                            />
                        </div>
                    }
                />

                <div className='main-content'>
                    <div className='content-area'>
                        <Card className='card-style'>
                            <CardHeader title={<h4 className='text-white mt-2 font-face-futura'>RULE BUILDER</h4>} style={headerStyle} />
                            <CardContent className='custom-scroll'>
                                {!editRule ?
                                    <>
                                        {rulesArray.map((rule, idx) => {
                                            // let len = rulesArray.length;
                                            return rule.for === 'and' ? (
                                                <RuleComponentForAnd
                                                    isAllPracticeSelected={this.state.isAllPracticeSelected}
                                                    key={idx}
                                                    index={idx}
                                                    onChange={this.onChange}
                                                    operator={rule.operator}
                                                    category={rule.category}
                                                    condition={rule.condition}
                                                    show={rule.show}
                                                    textValuesArray={rule.textValuesArray}
                                                    handleCategoryChange={this.handleCategoryChange}
                                                    handleConditonChange={this.handleConditionChange}
                                                    onEnter={this.handleOnEnter}
                                                    optionList={this.state.optionList}
                                                    searchValues={this.searchValues}
                                                    handlePlus={this.handlePlus}
                                                    handleMinus={this.handleMinus}
                                                    onTagsChange={(e, value, index) => this.onTagsChange(e, value, index)}
                                                    onProcedureTagsChange={(e, value, index) => this.onProcedureTagsChange(e, value, index)}
                                                    placeholder={this.state.mPlaceholder}
                                                    procedureTiles={this.state.procedureTileArray}
                                                    procedureTileValue={rule.procedureTileVal}
                                                    handleProcedureTileChange={this.handleProcedureTileChange}
                                                    dayValue={rule.dayValue}
                                                    handleDayValue={this.handleDayValue}
                                                    globalDropValue={rule.globalDropValue}
                                                    handleGlobalDropValue={this.handleGlobalDropValue}
                                                    procedureArray={rule.procedureValueArray}
                                                    clearSearch={this.clearSearch}
                                                    openProcedurePopup={this.state.showProcedurePopup}
                                                    nevermind={this.handleNevermind}
                                                    gotIt={this.handleProcedurePopup}
                                                    gender={rule.textValuesArray}
                                                    handleGender={this.handleGender}
                                                    maskDate={this.maskDate}
                                                />
                                            ) :
                                                <RuleComponentForThen
                                                    key={idx}
                                                    index={idx}
                                                    pendValue={rule.pendValue}
                                                    onChange={this.onChange}
                                                    operator={rule.operator}
                                                    handleThen={this.handleThen}
                                                    reason={rule.nestedThen.reason}
                                                    handleChange={(e) => this.handleChange(e, idx)}
                                                    rpaNameValue={rule.nestedThen.rpaNameValue}
                                                    handleRPAName={(e) => this.handleRPANameChange(e, idx)}
                                                    batchToggle={rule.nestedThen.batchToggle}
                                                    batchMsg={rule.nestedThen.batchMsg}
                                                    errorToggle={rule.nestedThen.errorToggle}
                                                    errorMsg={rule.nestedThen.errorMsg}
                                                    handleToggle={(e) => this.handleToggle(e, idx)}
                                                    showVariableSelector={this.state.showVariableSelector}
                                                    handleVariableSelector={this.handleVariableSelector}
                                                    customRPAList={this.state.customRPAList}
                                                    globalRPAList={this.state.globalRPAList}
                                                    handleVariableOptions={this.handleVariableOptions}
                                                    variable={this.state.variable}
                                                />
                                        })}
                                    </> :
                                    <>
                                        {ruleData.ruleInfo && ruleData.ruleInfo.length > 0 && ruleData.ruleInfo.map((rule, idx) => {
                                            // console.log(ruleData.ruleInfo)
                                            let customRPAName = this.state.customRPAList.find(custom => custom.rpa_id == (ruleData?.rpaInfo && ruleData?.rpaInfo[0]?.rpa_id))
                                            // console.log(customRPAName)
                                            return (<>
                                                {rule.for === "and" ?
                                                    <RuleComponentForAnd
                                                        key={idx}
                                                        index={idx}
                                                        category={rule.category}
                                                        condition={rule.condition}
                                                        operator={rule.operator}
                                                        optionList={this.state.optionList}
                                                        show={(rule.condition === 'is missing' || (!rule.category || !rule.condition)) ? 'none' : (rule.condition === 'is' || rule.condition === 'is not') ? 'DROP' : 'TEXT'}
                                                        searchValues={this.searchValues}
                                                        onChange={this.onChange}
                                                        handleCategoryChange={this.handleCategoryChange}
                                                        handleConditonChange={this.handleConditionChange}
                                                        onEnter={this.handleOnEnter}
                                                        deleteChipEdit={this.deleteChipEdit}
                                                        handlePlus={this.handlePlus}
                                                        handleMinus={this.handleMinus}
                                                        onTagsChange={this.onTagsChange}
                                                        onProcedureTagsChange={this.onProcedureTagsChange}
                                                        placeholder={this.state.mPlaceholder}
                                                        editRule={this.state.editRule}
                                                        ruleDataValues={ruleData}
                                                        ruleValues={rule.values}
                                                        procedureTileValue={rule.procedureTile}
                                                        procedureTiles={this.state.procedureTileArray}
                                                        handleProcedureTileChange={this.handleProcedureTileChange}
                                                        dayValue={rule.days}
                                                        handleDayValue={this.handleDayValue}
                                                        globalDropValue={rule.globalDrop}
                                                        handleGlobalDropValue={this.handleGlobalDropValue}
                                                        procedureValues={rule.procedureArray}
                                                        clearSearch={this.clearSearch} /> :
                                                    <RuleComponentForThen
                                                        key={idx}
                                                        index={idx}
                                                        operator={rule.operator}
                                                        pendValue={ruleData.result}
                                                        reason={ruleData.Reason}
                                                        onChange={this.onChange}
                                                        handleThen={this.handleThen}
                                                        handleChange={(e) => this.handleChange(e, idx)}
                                                        rpaNameValue={ruleData?.rpaInfo && ruleData?.rpaInfo[0]?.rpa_flow_Name}
                                                        rpaId={customRPAName?.id}
                                                        customRPAName={customRPAName?.name}
                                                        handleRPAName={(e) => this.handleRPANameChange(e, idx)}
                                                        batchToggle={ruleData?.rpaInfo && ruleData?.rpaInfo[0]?.batch_status}
                                                        batchMsg={ruleData?.rpaInfo && ruleData?.rpaInfo[0]?.batch_message}
                                                        errorToggle={ruleData?.rpaInfo && ruleData?.rpaInfo[0]?.error_status}
                                                        errorMsg={ruleData?.rpaInfo && ruleData?.rpaInfo[0]?.error_message}
                                                        handleToggle={(e) => this.handleToggle(e, idx)}
                                                        customRPAList={this.state.customRPAList}
                                                        globalRPAList={this.state.globalRPAList} />
                                                }

                                                {/* {(rule.operator === 'then') && 
                                                <RuleComponentForThen
                                                    key={idx}
                                                    index={idx}
                                                    operator={rule.operator}
                                                    pendValue={ruleData.result}
                                                    reason={ruleData.Reason}
                                                    onChange={this.onChange}
                                                    handleThen={this.handleThen}
                                                    handleChange={(e) => this.handleChange(e, idx)} />
                                                } */}
                                            </>
                                            )
                                        })}
                                    </>}
                            </CardContent>
                        </Card>
                    </div>

                    <div className='right-sidebar'>
                        <div style={showStyle}>
                            <header className="row text-white" style={header}>
                                <h5 className="col-10 mt-4 font-face-futura" style={{ textAlign: 'left', lineHeight: '22px', marginLeft: 10, fontSize: '20px' }}>RULE<br />
                                    <p className='mt-2 font-face-futura' style={{ fontSize: 13, lineHeight: 1.5 }}>While building your rule, you can view your entire rule here!</p>
                                </h5>
                                {/* <div className="col-1" style={{ cursor: 'pointer' }} onClick={this.handleHide}>
                                        <RemoveRedEyeIcon className="mt-2" /><br />
                                        <span style={{ fontSize: 12 }}>Hide</span>
                                    </div> */}
                            </header>
                            <div className='rulestyle'>
                                <h6 className="m-2 font-face-futura" style={ruleTextStyle}>Rule Name
                                    <div className="mt-1" style={{ width: 40, borderBottom: '1px solid #2F6690' }}></div></h6>
                                <input className='p-2 textbox font-face-futura'
                                    type='text'
                                    style={ruleInputStyle}
                                    name="ruleName"
                                    value={!editRule ? ruleName : ruleData.ruleName}
                                    onChange={this.handleRuleInput} />
                                <div className="m-3" style={{ borderBottom: '1px solid #2F6690' }}></div>

                                <div className='rule-box'>
                                    <h6 className="m-2 font-face-futura" style={ruleTextStyle}>Rule
                                        <div className="mt-1" style={{ width: 25, borderBottom: '1px solid #2F6690' }}></div></h6>
                                    {!editRule ?
                                        <>
                                            {showCreatedRule && <p className="m-2 font-face-futura" style={{ fontSize: 14, lineHeight: '21px' }}>
                                                {rulesArray.length > 0 && rulesArray.map((item, index) => {
                                                    // console.log(item)
                                                    return (<>
                                                        {index === 0 ? <span style={{ color: '#4F4D4D' }}>When the{' '}</span> : item.for !== 'then' ? <span style={{ color: '#4F4D4D' }}>{' '}the{' '}</span> : null}
                                                        {item.category ? <span style={{ color: '#0082B9' }}>{(item.category).toLowerCase()}{' '}</span> : null}
                                                        {(item.category === 'Modifier' ||
                                                            item.category === 'Diagnosis 1' ||
                                                            item.category === 'Diagnosis 2' ||
                                                            item.category === 'Diagnosis 3' ||
                                                            item.category === 'Diagnosis 4' ||
                                                            item.category === 'Diagnosis in any slot' ||
                                                            item.category === 'Total Charges' ||
                                                            item.category === 'Units' ||
                                                            item.category === 'Unit Charge') && this.state.procedureTileArray.length > 0 ? <span style={{ color: '#4F4D4D' }}>for procedure tile {item.procedureTileVal} </span> :
                                                            (item.category === 'Modifier' ||
                                                                item.category === 'Diagnosis 1' ||
                                                                item.category === 'Diagnosis 2' ||
                                                                item.category === 'Diagnosis 3' ||
                                                                item.category === 'Diagnosis 4' ||
                                                                item.category === 'Diagnosis in any slot' ||
                                                                item.category === 'Total Charges' ||
                                                                item.category === 'Units' ||
                                                                item.category === 'Unit Charge') && <span style={{ color: '#4F4D4D' }}>for any procedure </span>}

                                                        {item.category === 'Global Period' &&
                                                            <>
                                                                <span>for the procedure(s)</span>{' '}
                                                                {item.textValuesArray && (<span style={{ color: '#297200' }}>{(item.textValuesArray.map(f => f.name.split('-')[0].trim())).join(' or ')}{' '}</span>)}
                                                                <span>is</span>{' '}
                                                                {item.dayValue && (<span style={{ color: '#297200' }}>{item.dayValue}{' '}</span>)}
                                                                <span>{(item.dayValue === '0' || item.dayValue === '1') ? 'day' : 'days'},</span>{' '}
                                                                <span>{item?.dayValue === '0' ? 'before & after' : item?.globalDropValue}</span>{' '}
                                                                <span>we cannot bill the procedure(s)</span>{' '}
                                                                {item.procedureValueArray && (<span style={{ color: '#297200' }}>{(item.procedureValueArray.map(f => f.name.split('-')[0].trim())).join(' or ')}{' '}</span>)}
                                                            </>
                                                        }
                                                        {item.category !== 'Global Period' && <span style={{ color: '#FF6228' }}>{item.condition}</span>}{' '}
                                                        {item.category === 'Gender' && <span style={{ color: '#297200' }}>{((item?.textValuesArray).toString()).toLowerCase()}</span>}
                                                        {item.show === 'TEXT' ?
                                                            item.textValuesArray && (<span style={{ color: '#297200' }}>{item.condition === 'contains' ? (item.textValuesArray).join(' or ') : (item.textValuesArray).join(' and ')}{' '}</span>) :
                                                            item.show === 'DROP' ? item.textValuesArray && (<span style={{ color: '#297200' }}>{item.condition === 'is' ? (item.textValuesArray.map(f => f.name)).join(' or ') : (item.textValuesArray.map(f => f.name)).join(' and ')}{' '}</span>) : null}
                                                        {item.for === 'and' ? <span style={{ color: '#C96D02' }}>{(item.operator).toLowerCase()}</span> : item.for === 'then' ?
                                                            <span style={{ color: '#003C6A' }}>
                                                                {item.pendValue === 'pend to review tab' ? item.pendValue : item.nestedThen.rpaNameValue + ' ' + `[${item.nestedThen.customRPAName}]`}
                                                            </span> : null}
                                                    </>)
                                                })}
                                            </p>}
                                        </> :
                                        <p className="m-2 font-face-futura" style={{ fontSize: 14, lineHeight: '21px' }}>

                                            {ruleData.ruleInfo.length > 0 && ruleData.ruleInfo.map((item, index) => {
                                                // console.log("ruleData", ruleData.ruleInfo, ruleData.ruleInfo.length)
                                                return (
                                                    <>
                                                        {index === 0 ? <span style={{ color: '#4F4D4D' }}>When the{' '}</span> : item.for !== 'then' ? <span style={{ color: '#4F4D4D' }}>{' '}the{' '}</span> : null}
                                                        {item.category ? <span style={{ color: '#0082B9' }}>{(item.category).toLowerCase()}{' '}</span> : null}
                                                        {(item.category === 'Modifier' ||
                                                            item.category === 'Diagnosis 1' ||
                                                            item.category === 'Diagnosis 2' ||
                                                            item.category === 'Diagnosis 3' ||
                                                            item.category === 'Diagnosis 4' ||
                                                            item.category === 'Diagnosis in any slot' ||
                                                            item.category === 'Total Charges' ||
                                                            item.category === 'Units' ||
                                                            item.category === 'Unit Charge') && this.state.procedureTileArray.length > 0 ? <span style={{ color: '#4F4D4D' }}>for procedure tile {item.procedureTile} </span> :
                                                            // item.category === 'Unit Charge') && this.state.procedureTileArray.length > 0 && item.procedureTile !== 'any' ? <span style={{ color: '#4F4D4D' }}>for procedure tile {item.procedureTile} </span> :
                                                            (item.category === 'Modifier' ||
                                                                item.category === 'Diagnosis 1' ||
                                                                item.category === 'Diagnosis 2' ||
                                                                item.category === 'Diagnosis 3' ||
                                                                item.category === 'Diagnosis 4' ||
                                                                item.category === 'Diagnosis in any slot' ||
                                                                item.category === 'Total Charges' ||
                                                                item.category === 'Units' ||
                                                                item.category === 'Unit Charge') && <span style={{ color: '#4F4D4D' }}>for any procedure </span>}
                                                        {item.category === 'Global Period' &&
                                                            <>
                                                                <span>for the procedure(s)</span>{' '}
                                                                {item.values && (<span style={{ color: '#297200' }}>{(item.values.map(f => f.name.split('-')[0].trim())).join(' or ')}{' '}</span>)}
                                                                <span>is</span>{' '}
                                                                {item.days && (<span style={{ color: '#297200' }}>{item.days}{' '}</span>)}
                                                                <span>{(item.days === '0' || item.days === '1') ? 'day' : 'days'},</span>{' '}
                                                                <span>{item?.days === '0' ? 'before & after' : item?.globalDrop}</span>{' '}
                                                                <span>we cannot bill the procedure(s)</span>{' '}
                                                                {item.procedureArray && (<span style={{ color: '#297200' }}>{(item.procedureArray.map(f => f.name.split('-')[0].trim())).join(' or ')}{' '}</span>)}
                                                            </>
                                                        }
                                                        {item.category !== 'Global Period' && <span style={{ color: '#FF6228' }}>{item.condition}</span>}{' '}
                                                        {item.category !== 'Global Period' && <span style={{ color: '#297200' }}>
                                                            {(item.condition === 'is' || item.condition === 'contains') ? (item?.values && item.values.length > 0 && item.values.map(f => f.name ? f.name.split('-')[0].trim() : f).join(' or ')) : (item?.values && item.values.length > 0 && item.values.map(f => f.name ? f.name.split('-')[0].trim() : f).join(' and '))}{' '}
                                                        </span>}
                                                        {item.operator === 'and' ? <span style={{ color: '#C96D02' }}>{item.operator}</span> : item.operator === 'then' ?
                                                            <span style={{ color: '#003C6A' }}>{item.operator} {ruleData.result === 'pend to review tab' ? ruleData.result : (ruleData.result).toLowerCase() + ' ' + `[${ruleData?.rpaInfo[0]?.rpa_flow_Name}]`}</span> : null}
                                                    </>
                                                )
                                            })}
                                        </p>}
                                    <div className="m-3" style={{ borderBottom: '1px solid #2F6690' }}></div>

                                    <h6 className="m-2 font-face-futura" style={ruleTextStyle}>Reason
                                        <div className="mt-1" style={{ width: 25, borderBottom: '1px solid #2F6690' }}></div></h6>
                                    {!editRule ?
                                        <>
                                            {rulesArray.map(item => (
                                                <>
                                                    {item.for === 'then' && <p className="m-2 font-face-futura" style={{ fontSize: 14 }}>{item.nestedThen.reason}</p>}
                                                </>))}
                                        </> :
                                        <>
                                            {ruleData.result === 'pend to review tab' && <p className="m-2 font-face-futura" style={{ fontSize: 14 }}>{ruleData.Reason}</p>}
                                        </>}
                                    {ruleData.result === 'pend to review tab' && <div className="m-3" style={{ borderBottom: '1px solid #2F6690' }}></div>}
                                </div>

                                <div className='buttonEbd'>
                                    <h6 className="m-2 font-face-futura" style={ruleTextStyle}>Info
                                        <div className="mt-1" style={{ width: 25, borderBottom: '1px solid #2F6690' }}></div></h6>
                                    <p className="m-2 font-face-futura" style={{ fontSize: 14, display: 'flex', alignItems: 'center' }}><b>Tags:</b>&nbsp;
                                        <ReactChipInput
                                            classes={"chip-green"}
                                            // chips={!editRule ? this.state.tags : ruleData.tags}
                                            chips={this.state.tags}
                                            onSubmit={value => this.addChip(value.trim())}
                                            onRemove={index => this.removeChip(index)}
                                        />
                                    </p>
                                    <p className="m-2 font-face-futura" style={{ fontSize: 14 }}><strong>Created On:</strong> {new Date(Date.now()).toLocaleDateString('en-US')}</p>
                                    <p className="m-2 font-face-futura" style={{ fontSize: 14 }}><strong>Created By:</strong> Loren Dilger</p>
                                    <div className="m-3" style={{ borderBottom: '1px solid #2F6690' }}></div>

                                    <div align="center" className='mrtop-70'>
                                        <Button
                                            className="text-white"
                                            variant="contained"
                                            style={buttonStyle}
                                            onClick={() => {
                                                if (!editRule) {
                                                    this.setState({ showCreateRulePopup: true })
                                                } else {
                                                    this.setState({ showEditRulePopup: true });
                                                }
                                            }}
                                        // onClick={!editRule ? this.createRule : this.updateRule}
                                        >
                                            <span className='font-face-futura'>{!editRule ? "Save" : "Update"}</span>
                                        </Button>&nbsp;&nbsp;&nbsp;&nbsp;

                                        <Button className="text-white"
                                            variant="contained"
                                            onClick={!editRule ? this.reset : () => { window.location.reload() }}
                                            style={buttonStyle}>
                                            <span className='font-face-futura'>Reset</span>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ) : (
                            <div className="text-white" style={hideStyle} onClick={this.handleShow}>
                                <VisibilityOffIcon className="mt-1" /><br />
                                <span style={{ fontSize: 12 }}>Last Rule</span>
                            </div>
                        )} */}
                    </div>
                </div>
                <RoAlert
                    show={!editRule ? this.state.showCreateRulePopup : this.state.showEditRulePopup}
                    handleClose={() => {
                        if (!editRule) {
                            this.setState({ showCreateRulePopup: false })
                        } else {
                            this.setState({ showEditRulePopup: false })
                        }
                    }}
                    handleSubmit={() => {
                        if (!editRule) {
                            this.createRule();
                        } else {
                            this.updateRule();
                        }
                    }}
                    title={!editRule ? "you are creating a rule" : "you are editing a rule"}
                    type="info"
                    content={
                        <div className="alert-footer-text text-center">
                            You are about to {!editRule ? 'create' : 'edit'} a rule for <br />
                            <span className='font-face-futura'>
                                {!editRule ? (practiceData !== 'all' && 'the practice: ') : (this.props.location?.state?.ruleDetail?.practiceId !== '0' && 'the practice: ')}
                                <b>
                                    {!editRule ? (practiceData === 'all' ? 'All Practices' : practiceData.practiceName) : (this.props.location?.state?.ruleDetail?.practiceId === '0' ? 'All Practices' : practiceData.practiceName)}
                                </b>
                            </span>
                        </div>
                    }
                />
                <Footer />
            </section>
        )
    }
}
