import React from "react";
import SettingsIcon from "@material-ui/icons/Settings";
import RuleProcessor from "../RuleProcessor";
import CreateRule from "../CreateRule/rule-builder";
import ManageRules from "../ManageRule";
import Insurances from "../Insurances";
import Settings from "../Settings/group";
import Dashboard from "../Dashboard";
import Reports from "../AutoRunRule/Reports"
import DashBoardIcon from "../../assets/icons/dashboard.svg"
import RuleProcessorIcon from "../../assets/icons/rule-processor.png";
import CreateRuleIcon from "../../assets/icons/create-rule.png";
import ManageRulesIcon from "../../assets/icons/manage-rules.png";
import InsurancesIcon from "../../assets/icons/insurances.png";
import ReportsIcon from "../../assets/icons/reports.png";
import { makeStyles } from "@material-ui/core";

export const drawerWidth = 240;

export const navItems = [
  {
    path: "/dashboard",
    title: "Dashboard",
    icon: (
      <img
        src={DashBoardIcon}
        alt="dashboard"
        width={30}
        style={{ opacity: 1.4 }}
      />
    ),
    component: <Dashboard />,
  },
  {
    path: "/rule-processor",
    title: "Rule Processor",
    icon: (
      <img
        src={RuleProcessorIcon}
        alt="rule-processor"
        width={30}
        style={{ opacity: 0.4 }}
      />
    ),
    component: <RuleProcessor />,
  },
  {
    path: "/create-rule",
    title: "Create Rule",
    icon: (
      <img
        src={CreateRuleIcon}
        alt="create-rule"
        width={30}
        style={{ opacity: 0.4 }}
      />
    ),
    component: <CreateRule />,
  },
  {
    path: "/manageRule",
    title: "Manage Rule",
    icon: (
      <img
        src={ManageRulesIcon}
        alt="manageRule"
        width={30}
        style={{ opacity: 0.4 }}
      />
    ),
    component: <ManageRules />,
  },
  {
    path: `/insurances/${false}`,
    title: "Insurance",
    icon: (
      <img
        src={InsurancesIcon}
        alt="insurances"
        width={30}
        style={{ opacity: 0.4 }}
      />
    ),
    component: <Insurances />,
  },
  {
    path: "/Reports",
    title: "Reports",
    icon: (
      <img
        src={ReportsIcon}
        alt="reports"
        width={30}
        style={{ opacity: 0.4 }}
      />
    ),
    component: <Reports/>,
  },
  {
    path: "/settings",
    title: "Settings",
    icon: (
      <SettingsIcon style={{ color: "white", opacity: 0.4, fontSize: 30 }} />
    ),
    component: <Settings />,
  },
];

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    background: "#24476A",
    height: '100% !important'
  },
  appBar: {
    width: `calc(100% - 73px)`,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    overflow: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflow: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    width: `calc(119% - 73px)`,
    marginTop: 75,
  },
  selectOutlined: {
    padding: "8px",
    textAlign: "left",
    marginLeft: 4,
  },
  refreshing: {
    display: "none",
  },
}));
