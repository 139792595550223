import { Box, CircularProgress } from '@material-ui/core';

const loaderStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  bgcolor: 'transparent',
  color: '#24476a'
};

const loaderContainerStyle = {
    height: '100vh',
    width: '100%',
    position: 'fixed',
    backgroundColor: 'rgba(0, 0, 0, 0.232)',
    top: '0%',
    zIndex: '9999999999999999999'
};

export const LoadingIndicator = (props) => {
  return (
    <div style={loaderContainerStyle}>
      <CircularProgress style={loaderStyle} />
    </div>
  );
};
