import "./style.css";
import { DatatableHead } from "./DatatableHead";
import { CircularProgress } from "@material-ui/core";
import { useState } from "react";
import { useEffect } from "react";
import { CopyIcon } from "assets/icons";

const defaultBodyStyle = {
  height: "340px"
}

export const Datatable = ({
  /**
   * Head will contain the array of string to display the head of table
   * {name: string; isSortable: boolean, sortedState: 'asc' | 'desc' | 'noSort'}
   * @array
   */
  head,
  /**
   * Records will be a 2d array containing all the records display in the table
   * @interface {val: string | number; content: React.ReactNode}[][]
   * @Array @2DArray
   */
  records,
  /**
   * footer will contain the JSX will be displayed at the bottom of table
   * @ReactNode
   */
  footer = <></>,
  /**
   * style attributes
   */
  tableStyle = {},
  bodyStyle = {},
  headerStyle = {},
  /**
   * Event fired when clicking upon the table row
   */
  handleRowClick = () => { },
  /**
   * Event fired when clicking on the cell
   */
  handleCellClick = () => { },
  /**
   * Custom styling by column index
   * @example
   *  {0: {width: '100px'}, 1: {width: '200px'}}
   */
  columnStyles = {},
  /**
   * Loader configuration
   */
  showLoader = false,
  /**
   * Make column sortable
   */
  isSortable = false,
  /**
   * Get current sorted state
   */
  onSort,
}) => {
  const [recordsCache, setRecordsCache] = useState(records || []);
  const [dataRecords, setDataRecords] = useState(records || []);

  useEffect(() => {
    setDataRecords(records);
    setRecordsCache(records);
  }, [records]);

  const renderTableBody = () => {
    return (dataRecords || []).map((row, rowIndex) => {
      return (
        <tr
          key={rowIndex}
          onClick={() => handleRowClick(row, rowIndex)}
          className={"datatable-body"}
        >
          {row.map((col, colIndex) => (
            <td
              key={colIndex}
              style={columnStyles[colIndex]}
              className="text-center datatable-col"
              onClick={() => {
                handleCellClick(col, rowIndex, colIndex);
              }}
            >
              {col.content}
            </td>
          ))}
        </tr>
      );
    });
  };

  const renderLoader = (
    <div className="text-center h1" style={defaultBodyStyle}>
      <CircularProgress className="datatable-loader" />
    </div>
  );

  const renderNoRecord = (
    <div className="text-center lead text-futura text-danger px-5  mb-5" style={defaultBodyStyle}>
      No records found!
    </div>
  )

  return (
    <div>
      <div style={tableStyle}>
        <table className="datatable">
          <DatatableHead
            headerStyle={headerStyle}
            head={head || []}
            columnStyles={columnStyles}
            setDataRecords={setDataRecords}
            recordsCache={recordsCache}
            isSortable={isSortable}
            onSort={onSort}
          />
          {showLoader && renderLoader}
          {(dataRecords || []).length === 0 && !showLoader && renderNoRecord}
          {!showLoader && (dataRecords || []).length !== 0 && (
            <tbody style={bodyStyle}>{!showLoader && renderTableBody()}</tbody>
          )}
        </table>
        {footer}
      </div>
    </div>
  );
};
