import React, { Component } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import './charts.css';
Chart.register(ArcElement);

export class PieChart extends Component {
  constructor(props) {
    super(props);

    this.state = { };
  }
  render() {
    const { data, percentViolation } = this.props
    return (
      <div className="mb-2 position-relative text-center">
        <span className="font-face-futura"> Percentage Used</span><br />
        <span  className="font-face-futura">Past 6 Months</span>
        <Doughnut
          data={data}
          options={{
            responsive: true,
            maintainAspectRatio: true,
            plugins: {
              tooltip: {
                enabled: false 
              }
            }
          }}
        />
        <span className="pie_value">{percentViolation}</span>
      </div>
    );
  }
}

export default PieChart;