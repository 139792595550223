import { useState } from "react";
import { useEffect } from "react";
import "../style.css";
import { mapCurrentSortedStateToNextState, SortedState } from "./constants";
import { sortInAscending, sortInDescending } from "./helper";

export const DatatableHead = ({
  head,
  headerStyle,
  columnStyles,
  setDataRecords,
  recordsCache,
  isSortable,
  onSort,
}) => {
  const [header, setHeader] = useState(head || []);

  const handleColClick = async (index) => {
    if (onSort) {
      onSort(index);
      return;
    }
    if (!isSortable) return;
    const clickedHeader = header[index];
    const { sortedState } = clickedHeader;
    const nextSortedState = mapCurrentSortedStateToNextState[sortedState];
    const records = recordsCache.map((rec) => rec);

    switch (nextSortedState) {
      case SortedState.ASC:
        const ascendingOrder = sortInAscending(records, index);
        await setDataRecords([...ascendingOrder]);
        break;
      case SortedState.DESC:
        const descendingOrder = sortInDescending(records, index);
        await setDataRecords([...descendingOrder]);
        break;
      case SortedState.NO_SORT:
        await setDataRecords([...records]);
        break;
    }
    const h = header.map((h) => {
      return {
        ...h,
        sortedState: SortedState.NO_SORT,
      };
    });

    h[index] = { ...h[index], sortedState: nextSortedState };
    setHeader(h);
  };

  useEffect(() => {
    setHeader(head);
  }, [head, recordsCache]);

  const renderHeader = () => {
    return header.map((h, index) => (
      <th
        className="text-center datatable-col"
        key={index}
        style={columnStyles[index]}
      >
        <span
          className={`font-face-futura text-futura ${
            h.sortedState !== SortedState.NO_SORT ? "text-signature" : ""
          }`}
          onClick={() => handleColClick(index)}
          style={{ fontSize: "18px", cursor: "pointer" }}
        >
          {h.name}
        </span>
      </th>
    ));
  };

  return (
    <thead style={headerStyle}>
      <tr>{renderHeader()}</tr>
    </thead>
  );
};
