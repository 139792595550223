import { Box as MaterialBox, Modal } from "@material-ui/core";
import { QuestionMark } from "assets/icons";
import { ROButton } from "layouts/RoButton";
import { mapIconToAlertType, RoAlertTypes } from "./constants";
import "./style.css";

const alertStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35%",
  bgcolor: "white",
  border: "1px solid white",
  boxShadow: 24,
  height: "auto",
  borderRadius: "15px",
  padding: "30px",
  outline: 'none !important'
};

export const RoAlert = ({
  show,
  handleClose,
  handleSubmit,
  title = "",
  content,
  closeBtnText = "nevermind",
  btnText = "Yes, do it!",
  type = "info",
  showproviderPopup,
  showResendPopup, showTicket, showBypass
}) => {
  const alertTypes = Object.values(RoAlertTypes);
  if (!alertTypes.includes(type)) {
    console.error(
      `Please provide a valid type of alert. It must be one from - ${alertTypes.join(
        " | "
      )}`
    );
  }

  return (
    <Modal open={show} onClose={handleClose}>
      <MaterialBox sx={{ ...alertStyle }} className="rolesalert">
        <div className="alert-icon-container">
          <img
            className="alert-icon"
            src={mapIconToAlertType[type] || QuestionMark}
          />
        </div>
        {/* <div className="mt-4"></div> */}
        <h1 className="alert-heading mt-4 text-uppercase">{title}</h1>
        {content}
        <div className="pt-2 alert-footer">
          <div className="text-center alert-footer-text">
            {showproviderPopup ? " " : showResendPopup? "Would you like to resubmit it?" : showTicket? 'Would you like to submit a ticket?' : "Would you like to continue?"}
          </div>
          <br />
          <div className="row text-center">
            {showproviderPopup ? (<div className="col-12">
              <ROButton
                className="w-100"
                onClick={handleClose}
                type="danger"
                fontSize={23}
              >
                THAT'S OKAY, I'LL LET THEM KNOW
              </ROButton>
            </div>) : (
              <>
                <div className="col-6">
                  <ROButton
                    className="w-100"
                    onClick={handleClose}
                    type="danger"
                    fontSize={23}
                  >
                    {closeBtnText}
                  </ROButton>
                </div>
                <div className="col-6">
                  <ROButton
                    className="w-100"
                    fontSize={23}
                    onClick={handleSubmit}
                    type="info"
                  >
                    {showResendPopup ? "YES, INVITE" : showTicket? 'YES, SUBMIT' : showBypass ? 'YES, LINK' : btnText}
                  </ROButton>
                </div>
              </>
            )}
          </div>
        </div>
      </MaterialBox>
    </Modal>
  );
};
