export const searchItems = [
    {key: 'primaryInsurance', value: 'Primary Insurance'},
    {key: 'patientName', value: 'Patient Name'}, 
    {key: 'encounter', value: 'Encounter #'}, 
    {key: 'schedulingProvider', value: 'Provider'}, 
    // {key: 'schedulingProvider', value: 'Scheduling Provider'}, 
    {key: 'dateOfService', value: 'Date of Service'}, 
    {key: 'procedureCode', value: 'Procedure Code'},  
    // {key: 'secondaryInsurance', value: 'Secondary Insurance'}, 
    {key: 'facility', value: 'Place of Service'}, 
    // {key: 'facility', value: 'Facility'}, 
    {key: 'DXC1', value: 'Diagnosis 1'}, 
    // {key: 'DXC1', value: 'DXC1'}, 
    // {key: 'DXC2', value: 'DXC2'}
];

export const claimsDropdown = [
    {key: 'notRan', value: 'Claim Center - Not Ran'},
    {key: 'ranByDay', value: 'Claim Center - Ran by Day'},
    {key: 'claimsNotReviewed', value: 'Claims Not Reviewed'},
    {key: 'claimsReviewed', value: 'Claims Reviewed'}
];
  
export const searchValues = [
    {key: 'ruleName', value: 'Search by rule name'}, 
    {key: 'tags', value: 'Search by tag'}, 
    {key: 'modified', value: 'Search by modified'},
    {key: 'status', value: 'Search by status'}
];